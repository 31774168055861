import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { clearModalType } from '@pgl-apps/kap-stake/redux'
import { useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { hideModal } from '@pgl-apps/kap-stake/redux'
import { ModalWrapper } from '@pgl-apps/shared/components'
import type { ModalSettings } from '@pgl-apps/shared/types'

import { BoostMultiple } from './BoostMultiple'
import { BoostSingle } from './BoostSingle'
import { Claim } from './Claim'
import { CollectLockedRewards } from './CollectLockedRewards'
import { CollectVesting } from './CollectVesting'
import { Stake } from './Stake'
import { Unstake } from './Unstake'
import { Wallet } from './Wallet'

// --------------------- ===
//  MODAL STUFF
// ---------------------
export const defaultSettings: ModalSettings = {
  modalClass: '',
  type: 'center',
}

const MODAL_COMPONENTS = {
  /* Staking */
  [modalTypes.boostMultiple]: {
    comp: BoostMultiple,
    settings: {
      ...defaultSettings,
    },
  },
  [modalTypes.boostSingle]: {
    comp: BoostSingle,
    settings: {
      ...defaultSettings,
    },
  },
  [modalTypes.claim]: {
    comp: Claim,
    settings: {
      ...defaultSettings,
    },
  },
  [modalTypes.stake]: {
    comp: Stake,
    settings: {
      ...defaultSettings,
    },
  },
  [modalTypes.unstake]: {
    comp: Unstake,
    settings: {
      ...defaultSettings,
    },
  },
  /* Vesting */
  [modalTypes.collectVesting]: {
    comp: CollectVesting,
    settings: {
      ...defaultSettings,
    },
  },
  /* LockedRewards */
  [modalTypes.collectLockedRewards]: {
    comp: CollectLockedRewards,
    settings: {
      ...defaultSettings,
    },
  },
  /* Others */
  [modalTypes.wallet]: {
    comp: Wallet,
    settings: {
      ...defaultSettings,
    },
  },
}

export const ModalContent = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const type = useSelector((store: IRootState) => store.modal.type)
  const modalProps = useSelector((store: IRootState) => store.modal.props)
  const isVisible = useSelector((store: IRootState) => store.modal.isVisible)

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleCloseModal = useCallback(
    (arg?: unknown) => {
      // Special callback function
      if (modalProps && modalProps.closeCallback) {
        modalProps.closeCallback(arg)
      }

      // Then hide modal
      dispatch(hideModal())
    },
    [dispatch, modalProps]
  )

  // --------------------- ===
  //  RENDER
  // ---------------------
  if (!type) {
    return null
  }

  const currentModal = MODAL_COMPONENTS[type]
  const ModalComponent = currentModal.comp
  const { settings } = currentModal

  return (
    <ModalWrapper
      settings={settings}
      onCloseModal={handleCloseModal} // proper way to close the modal
      isVisible={isVisible}
      onClearModalType={() => {
        dispatch(clearModalType())
      }}
    >
      <ModalComponent
        onCloseModal={handleCloseModal} // proper way to close the modal
        {...modalProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    </ModalWrapper>
  )
}
