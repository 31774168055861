import type { ReactNode } from 'react'

// import styles from './index.module.scss'

interface Props {
  children: ReactNode
  maxHeight?: number | string // do we want this?
  className?: string // use only as bailout
  isHighlighted?: boolean
}

export const ContentBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    maxHeight,
    className = 'p-4 md:p-6 pt-6 w-full h-full flex flex-col',
    isHighlighted = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const highlightedClass = isHighlighted
    ? 'border border-theme-highlighted'
    : ''
  return (
    <div
      className={`bg-theme-containerBg rounded-lg ${className} ${highlightedClass}`}
      style={{
        maxHeight: maxHeight || 'initial',
      }}
    >
      {children}
    </div>
  )
}
