import { MenuItem } from './private/MenuItem/MenuItem'

interface Props {
  navIsOpen: boolean
  clickHandler: () => void
}

export function DropdownMenu(props: Props) {
  const { navIsOpen, clickHandler } = props
  return (
    // TODO fix links nested in buttons
    navIsOpen && (
      <div
        onClick={clickHandler}
        className="origin-top-left absolute left-10 mt-5 w-56 rounded-md shadow-lg bg-theme-containerBg ring-1 ring-black ring-opacity-5 divide-y divide-dotted  text-theme-tableText border-2 border-t-theme-primaryCtaBorder"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        <MenuItem link="/home" title="Guild" />
        <MenuItem link="/home" title="Stake" />
        <MenuItem link="/home" title="Vote" />
        <MenuItem link="/home" title="Deploy DAO" />
        <MenuItem link="/home" title="Other App" />
      </div>
    )
  )
}

export default DropdownMenu
