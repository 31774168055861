import { scaleBand, scaleLinear } from 'd3'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AxisBottom } from '../private/AxisBottom'
import { AxisLeft } from '../private/AxisLeft'
import { BarsBuilder } from '../private/BarsBuilder'
import type { ChartProps, PlotState } from '../types'

const defaultMargins = { top: 20, right: 0, bottom: 20, left: 30 }
const xAxisPadding = 5
const minYHeight = 10 // keeps a graph from going from 0 to 0 which looks weird

export const BarGraph = (props: ChartProps) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    values,
    showX = true,
    showY = true,
    margin = defaultMargins,
    graphKeyData,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [plot, setPlot] = useState<null | PlotState>(null)

  // --------------------- ===
  //  REFS
  // ---------------------
  const outerRef = useRef(null)

  // --------------------- ===
  //  FUNCS
  // ---------------------
  const renderGraph = useCallback(() => {
    const outerWidth = outerRef.current.clientWidth
    const outerHeight = outerRef.current.clientHeight
    const width = outerWidth - margin.left - margin.right
    const height = outerHeight - margin.top - margin.bottom - xAxisPadding

    // generate axis scales, filter out unselected values
    const labels = values.map(({ label }) => label)

    const subLabels = Object.keys(graphKeyData).filter(
      (key) => graphKeyData[key].isActive
    )
    const yValues = values
      .map(({ values }) => {
        return values.map(({ value, key }) => (graphKeyData[key] ? value : 0))
      })
      .flat()
    yValues.push(minYHeight)

    const scaleX = scaleBand().domain(labels).range([0, width]).padding(0.5)

    const subscaleX = scaleBand()
      .domain(subLabels)
      .range([0, scaleX.bandwidth()])
      .padding(0.1)

    const scaleY = scaleLinear()
      .domain([0, Math.max(...yValues)])
      .range([height, 0])
      .nice()

    setPlot({ width, height, scaleX, subscaleX, scaleY })
  }, [
    values,
    graphKeyData,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
  ])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    renderGraph()
    window.addEventListener('resize', renderGraph)
    return () => window.removeEventListener('resize', renderGraph)
  }, [renderGraph])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="w-full h-full" ref={outerRef}>
      {plot ? (
        <svg
          width={plot.width + margin.left + margin.right}
          height={plot.height + margin.top + margin.bottom}
        >
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            {showX && (
              <AxisBottom
                scale={plot.scaleX}
                transform={`translate(0, ${plot.height})`}
                width={plot.width}
              />
            )}
            {showY && (
              <AxisLeft
                scale={plot.scaleY}
                transform={`translate(5, -${xAxisPadding})`}
              />
            )}
            <BarsBuilder
              values={values}
              height={plot.height}
              scaleX={plot.scaleX}
              subscaleX={plot.subscaleX}
              scaleY={plot.scaleY}
              barTransform={`translate(0, -${xAxisPadding})`}
              graphKeyData={graphKeyData}
            />
          </g>
        </svg>
      ) : null}
    </div>
  )
}
