// NOTE: this is a tsx file bc tailwind is not picking up classes in ts files
import { tv } from 'tailwind-variants'

export const button = tv({
  base: 'relative inline-flex font-heading font-bold whitespace-nowrap items-center justify-center gap-2 rounded-sm',
  variants: {
    size: {
      xs: 'px-2 h-7 text-sm [&>.icon]:w-3',
      sm: 'px-4 h-10 text-base [&>.icon]:w-3',
      md: 'px-5 h-11 text-base [&>.icon]:w-6',
      lg: 'px-7 h-14 text-base [&>.icon]:w-7',
      xl: 'px-8 h-16 text-4xl [&>.icon]:w-9',
    },
    type: {
      solid: 'bg-kap-neon text-kap-black [&>.inner]:hidden hover:underline',
      outline:
        'bg-kap-neon text-kap-neon [&>.inner]:bg-kap-black [&:hover>.inner]:bg-opacity-80',
      text: 'bg-kap-neon bg-opacity-0 text-kap-neon hover:bg-opacity-25 [&>.inner]:hidden',
      danger: 'bg-kap-danger text-white hover:bg-opacity-75 [&>.inner]:hidden',
    },
    isDisabled: {
      true: '',
    },
  },
  compoundVariants: [
    {
      type: 'solid',
      isDisabled: true,
      class: 'bg-slate-700 text-slate-400 bg-none',
    },
    {
      type: 'outline',
      isDisabled: true,
      class: 'bg-opacity-0 border border-slate-700 text-slate-400',
    },
    {
      type: 'text',
      isDisabled: true,
      class: 'bg-slate-700 text-slate-400 bg-none',
    },
    {
      type: 'danger',
      isDisabled: true,
      class: 'opacity-50 bg-opacity-50',
    },
  ],
})

export const chamfer = {
  clipPath:
    'polygon(0 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%)',
}
