import { forwardRef } from 'react'

import closeIcon from '@pgl-apps/shared-images/icons/close-white.svg'
import { modalId } from '@pgl-apps/shared/helpers'

import { SrOnly } from '../../../../typography'
import type { PrivateModalProps } from '../types'
import styles from './index.module.scss'

export const centerModalInParams = {
  yPercent: 0,
  opacity: 1,
}
export const centerModalOutParams = {
  yPercent: 20,
  opacity: 0,
}

export const CenterModal = forwardRef<HTMLDivElement, PrivateModalProps>(
  (props, contentRef) => {
    // --------------------- ===
    //  PROPS
    // ---------------------
    const { onCloseModal, children } = props

    // --------------------- ===r
    //  RENDER
    // ---------------------
    return (
      <div
        ref={contentRef}
        className={styles.outer}
        id={modalId} // Don't remove - used by modalTrap
      >
        <div className={styles.content}>{children}</div>
        <button
          onClick={() => onCloseModal()}
          id="modalCloseBtn" // Don't remove - used by modal handling
          className={styles.closeBtn}
        >
          <img src={closeIcon} alt="" />
          <SrOnly label="Close Modal" />
        </button>
      </div>
    )
  }
)
