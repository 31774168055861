// --------------------- ===
//  ROUTE PATHS
// ---------------------
export const routePaths = {
  home: {
    path: '/',
    label: 'Stake',
  },
  vesting: {
    path: 'vesting',
    label: 'Rewards',
  },
}
