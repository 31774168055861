import type { ReactNode } from 'react'

interface Props {
  children: string | ReactNode
  onClick: () => void
}

export const DropdownOption = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, onClick } = props
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      // "dropdown-option" class used as an identifier for the parent.
      className="dropdown-option text-theme-base hover:underline text-left leading-normal break-all"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
