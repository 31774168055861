import type { ReactNode } from 'react'

import type { DropdownProps } from '@pgl-apps/shared/types'

import { Heading } from '../../../typography'
import { DropdownCommon } from '../../../utility'
import { ContentBox } from '../ContentBox'

interface Props {
  children: ReactNode
  label?: string // Helpful for some components to essentially either render PrimaryBox or ContentBox, which is why this is optional
  subLabel?: string
  dropdown?: DropdownProps
  className?: string
  isHighlighted?: boolean
}

export const PrimaryBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    label = '',
    subLabel = '',
    dropdown = null,
    className,
    isHighlighted = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <ContentBox className={className} isHighlighted={isHighlighted}>
      {(label || dropdown) && (
        <div className="flex flex-wrap divider-b items-center pb-3 mb-8">
          {label && (
            <div className="grow">
              <Heading type="article" tag="h2" className="!mb-0">
                {label}
              </Heading>
              {subLabel && (
                <h3 className="text-theme-muted text-sm my-1">{subLabel}</h3>
              )}
            </div>
          )}
          {/* basis-8 matches DropdownBucket min-w */}
          {dropdown && (
            <div className="grow-0 basis-40">
              <DropdownCommon
                label={dropdown.label}
                options={dropdown.options}
                onClick={dropdown.onClick}
              />
            </div>
          )}
        </div>
      )}
      {children}
    </ContentBox>
  )
}
