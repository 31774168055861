import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  type?: 'success' | 'error'
}

export const SymbolCircle = (props: Props) => {
  const { children } = props

  return (
    <span className="text-theme-bg bg-theme-success rounded-full h-4 w-4 flex items-center justify-center">
      {children}
    </span>
  )
}
