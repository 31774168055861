import { Link } from 'react-router-dom'

import { button, chamfer } from '../styles'
import type { PrimaryLnk } from '../types'
import { Inner } from './Inner'

export const LinkInternal = (props: PrimaryLnk) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    to,
    label,
    leftIcon,
    rightIcon,
    type = 'solid',
    state = 'default',
    size = 'md',
    srOnly,
    analyticsParams,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isDisabled = state === 'disabled'
  return (
    <Link
      to={to}
      className={button({ size, type, isDisabled })}
      style={chamfer}
      state={{ analyticsParams }}
    >
      <Inner
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
        srOnly={srOnly}
      />
    </Link>
  )
}
