import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import { day, year } from '@pgl-apps/kap-stake/helpers'
import { stakingOpts, useRewardsLocker } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { showModal, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import type { LockAgreement } from '@pgl-apps/kap-stake/types'
import {
  ActionCell,
  ActionItem,
  Cell,
  Row,
  Status,
  TableWrapper,
} from '@pgl-apps/shared/components'
import { defaultColumns } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import styles from '../index.module.scss'

const columnData: typeof defaultColumns.general[] = [
  {
    ...defaultColumns.general,
    label: 'Pool',
  },
  {
    ...defaultColumns.general,
    label: 'Amount',
  },
  {
    ...defaultColumns.general,
    label: 'Created',
  },
  {
    ...defaultColumns.general,
    label: 'Unlocks in',
  },
  {
    ...defaultColumns.general,
    label: 'Status',
  },
]

const convertAgreement = (agreement: LockAgreement) => {
  const { id, availableTimestamp, amount, collected } = agreement
  const timestamp = Date.now()
  const lockedDate = new Date(availableTimestamp * 1000 - year * 1000)
  const unlocks = Math.ceil(
    (availableTimestamp * 1000 - timestamp) / (day * 1000)
  ) // day unit

  return {
    id,
    amount,
    collected,
    lockedDate: lockedDate.toLocaleDateString(),
    unlocks: Math.max(unlocks, 0),
  }
}

export const LockAgreements = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const locker = useRewardsLocker(walletId, signer)

  const lockedRows = useMemo(
    () => locker.lockAgreements.map((a) => convertAgreement(a)),
    [locker.lockAgreements]
  )

  // --------------------- ===
  //  METHOD
  // ---------------------
  const onClickCollect = useCallback(
    (agreementId) => {
      dispatch(
        showModal(modalTypes.collectLockedRewards, {
          agreementId,
        })
      )
    },
    [dispatch, showModal]
  )

  return (
    <TableWrapper
      columnData={columnData}
      title="Locked Rewards"
      isLoading={locker.loading}
    >
      {lockedRows.map((row, i) => (
        <Row id={i} key={i}>
          <Cell>
            <div className={styles.poolWrapper}>
              <div className={styles.icon}>
                <img src={stakingOpts.kap.icon} alt={stakingOpts.kap.label} />
              </div>
              <span>{stakingOpts.kap.label}</span>
            </div>
          </Cell>
          <Cell>{addNumPunctuation(row.amount, 2)}</Cell>
          <Cell>{row.lockedDate}</Cell>
          <Cell>{row.unlocks} Days</Cell>
          <Cell>
            <Status
              type={row.unlocks === 0 ? 'success' : 'inactive'}
              label={row.unlocks === 0 ? 'Unlocked' : 'Locked'}
            />
          </Cell>
          {row.unlocks === 0 ? (
            <ActionCell>
              <ActionItem
                label="Claim"
                onClick={() => onClickCollect(row.id)}
              />
            </ActionCell>
          ) : (
            <></>
          )}
        </Row>
      ))}
    </TableWrapper>
  )
}
