import type { SVGR } from './svgr'

export interface DropdownProps {
  label: string
  options: string[]
  onClick: (opt: string) => void
}

export interface Slide {
  Icon: SVGR
  label: string
  value: string | number
  currency?: string
  defaultValue?: string
}

export interface CtaProps {
  onClick?: (arg?: unknown) => void
  label: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'cancel'
  state?: 'default' | 'loading' | 'disabled'
  isFullWidth?: boolean
  icon?: string
  childrenClassName?: string
}
