import type {
  DropdownProps,
  HCPropsAdvanced,
  HCPropsBasic,
} from '@pgl-apps/shared/types'

import { HighChart } from '../../../graphs-high'
import { PrimaryBox } from '../PrimaryBox/PrimaryBox'

interface Props {
  label?: string
  dropdown?: DropdownProps
}

export const HighChartBox = (
  props: (HCPropsBasic & Props) | (HCPropsAdvanced & Props)
) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    label = '',
    series = null,
    type = null,
    options = null,
    dropdown = null,
    isLoading = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <PrimaryBox label={label} dropdown={dropdown}>
      {options ? (
        <HighChart options={options} isLoading={isLoading} />
      ) : (
        <HighChart series={series} type={type} isLoading={isLoading} />
      )}
    </PrimaryBox>
  )
}
