import { Spinner } from './Spinner'

interface Props {
  status?: 'pending' | 'complete' | 'failed'
  type?: 'absolute' | 'relative'
}

// IMPORTANT type 'absolute' requires a positioned parent
// (add position: relative) to the nearest parent that makes sense

// Makes a good isLoading overlay for components
export const SpinnerOverlay = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { status = 'pending', type = 'absolute' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const opts = {
    absolute: 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
    relative: 'w-full h-full flex items-center justify-center',
  }

  return (
    <div className={`z-lift ${opts[type]}`}>
      <div className="p-4 bg-black bg-opacity-25 rounded">
        <div className="w-12 h-12">
          <Spinner status={status} />
        </div>
      </div>
    </div>
  )
}
