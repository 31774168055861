interface Props {
  isLeft?: boolean
}

export const OverflowGradient = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { isLeft = false } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className={`w-6 absolute ${
        isLeft
          ? 'left-[-1rem] bg-gradient-to-r'
          : 'right-[-1rem] bg-gradient-to-l'
      } top-0 bottom-0 from-theme-containerBg pointer-events-none`}
    />
  )
}
