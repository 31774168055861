import { gsap } from 'gsap'
import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import { useNotifications } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { NotificationCard } from '@pgl-apps/shared/components'
import type { ClearNotificationArg } from '@pgl-apps/shared/types'

const timeoutDelay = 5000
const slideDuration = 0.8
const growDuration = 0.4

export const NotificationFactory = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const notifications = useSelector(
    (store: IRootState) => store.notifications.notifications
  )

  // --------------------- ===
  //  REFS
  // ---------------------
  const wrapperRef = useRef<HTMLDivElement>(null)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { clearNotification } = useNotifications()

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const getChildrenArr = () => {
    const { children } = wrapperRef.current
    return Array.from(children)
  }

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleHideNotification = useCallback(
    (id: string) => {
      const arr = getChildrenArr()

      // Fade out
      arr.forEach((child) => {
        if (child.id === id) {
          // Animate it out
          gsap.to(child, {
            duration: slideDuration,
            xPercent: 200,
            ease: 'elastic.inOut(2.5, 1)',
          })
          gsap.to(child, {
            duration: growDuration,
            delay: slideDuration,
            height: 0,
            margin: 0,
            padding: 0,
            onComplete: () => {
              // handleClearTimer(id)
              clearNotification(id)
            },
          })
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch] // no clearNotification
  )

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="fixed top-20 right-4 z-notifications" ref={wrapperRef}>
      {notifications.map((notification: ClearNotificationArg) => (
        <NotificationCard
          key={notification.id}
          notification={notification}
          duration={growDuration}
          timeoutDelay={timeoutDelay / 1000} // ms to s
          onClose={() => handleHideNotification(notification.id)}
        />
      ))}
    </div>
  )
}
