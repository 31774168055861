import { axisBottom, select } from 'd3'
import { useEffect, useRef } from 'react'

import styles from '../Graph.module.scss'
import type { AxisBottomProps } from '../types'

export const AxisBottom = (props: AxisBottomProps) => {
  const { scale, transform, width } = props
  const ref = useRef<SVGGElement>(null)

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(
        axisBottom(scale).tickFormat((t, i) => {
          // Hide labels if it's getting too cramped.
          const MIN_WIDTH = 50
          let skip = Math.round((MIN_WIDTH * scale.domain().length) / width)
          skip = Math.max(1, skip)

          return i % skip === 0 ? t : null
        })
      )
    }
  }, [scale, width])

  return (
    <g
      ref={ref}
      transform={transform}
      className={`font-thin ${styles.axisColors}`}
    />
  )
}
