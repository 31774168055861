import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import {
  addTransaction,
  flushTransactions,
  updateTransaction,
  useAppDispatch,
} from '@pgl-apps/kap-stake/redux'
import type { TxHash } from '@pgl-apps/shared/types'

const EMPTY_TXN_HASH = ''

export const useTransactions = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const transactions = useSelector((store: IRootState) => store.transactions)

  // --------------------- ===
  //  STATE
  // ---------------------

  // --------------------- ===
  //  METHODS
  // ---------------------
  const initPendingTxn = useCallback(
    (message: string) => {
      const id = uuidv4()
      dispatch(
        addTransaction({
          id,
          txHash: EMPTY_TXN_HASH,
          status: 'pending',
          message,
        })
      )
      return id
    },
    [dispatch]
  )

  const updatePendingTxn = useCallback(
    (id: number | string, txHash: string, message: string) => {
      dispatch(
        updateTransaction({
          id,
          txHash,
          status: 'pending',
          message,
        })
      )
    },
    [dispatch]
  )

  const completePendingTxn = useCallback(
    (id: number | string, txHash: string, message = '') => {
      dispatch(
        updateTransaction({
          id,
          txHash,
          status: 'complete',
          message,
        })
      )
    },
    [dispatch]
  )

  const errorPendingTxn = useCallback(
    (id: number | string, message: string) => {
      dispatch(
        updateTransaction({
          id,
          txHash: EMPTY_TXN_HASH,
          status: 'failed',
          message,
        })
      )
    },
    [dispatch]
  )

  const clearTransactions = useCallback(() => {
    dispatch(flushTransactions())
  }, [dispatch])

  const latestTransaction = useMemo((): TxHash | undefined => {
    return (
      transactions.list.find((txn) => txn.status === 'pending') ||
      transactions.list[transactions.list.length - 1]
    )
  }, [transactions])

  // --------------------- ===
  //  EFFECTS
  // ---------------------

  return {
    initPendingTxn,
    updatePendingTxn,
    completePendingTxn,
    errorPendingTxn,
    clearTransactions,
    latestTransaction,
    transactions,
  }
}
