import { Cell } from '../../public/Cell/Cell'
import { Row } from '../../public/Row/Row'

export const RowEmpty = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <Row id="empty">
      {[
        <Cell key="NoDataCell">
          <div className="text-theme-muted flex">No data</div>
        </Cell>,
      ]}
    </Row>
  )
}
