import { ReactComponent as SearchIcon } from '@pgl-apps/shared-images/svgr/icons/search.svg'
import { defaultInputConfig } from '@pgl-apps/shared/constants'
import type { SearchConfig } from '@pgl-apps/shared/types'

import { Input } from '../Input/Input'
import { InputWithSuffix } from '../InputWithSuffix/InputWithSuffix'
import { Select } from '../Select/Select'

// This component assumes Search is aligned right, but we could make that configurable if needed
export const Search = (props: Omit<SearchConfig, 'suggestionArr'>) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    value,
    onChange,
    onSuffixChange,
    suffixValue,
    placeholder = 'Search',
    suffixOpts = [],
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  // const activeClass = value ? '!w-64' : ''
  const inputConfig = {
    ...defaultInputConfig,
    value,
  }
  const suffixConfig = {
    ...defaultInputConfig,
    value: suffixValue,
  }

  return (
    <div className="w-full max-w-full">
      {suffixOpts.length > 1 ? (
        <InputWithSuffix
          suffix={
            <Select
              hideLabel
              inputConfig={suffixConfig}
              options={suffixOpts}
              onChange={(val) => {
                onSuffixChange(val)
              }}
            />
          }
          inputConfig={inputConfig}
          label="Search"
          onChange={onChange}
          placeholder={placeholder}
          Icon={SearchIcon}
          hideLabel
          required // hack to keep border 'active' color/>
        />
      ) : (
        <Input
          inputConfig={inputConfig}
          label="Search"
          onChange={onChange}
          placeholder={placeholder}
          Icon={SearchIcon}
          hideLabel
          required // hack to keep border 'active' color
        />
      )}
    </div>
  )
}
