import { Children, cloneElement, useEffect, useRef, useState } from 'react'
import type { ReactElement } from 'react'
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook'

import type { defaultColumns } from '@pgl-apps/shared/constants'
import { withChildComponent } from '@pgl-apps/shared/hocs'
import { useMountEffect } from '@pgl-apps/shared/hooks'
import type {
  ChildComponentCheck,
  CtaProps,
  FilterConfig,
  SearchConfig,
  SelectedActions,
  SelectedRows,
  SortConfig,
  SortOpts,
} from '@pgl-apps/shared/types'

import { TableBox } from '../../region'
import { OverflowGradient } from './private/OverflowGradient/OverflowGradient'
import { RowEmpty } from './private/RowEmpty/RowEmpty'
import { RowLoading } from './private/RowLoading/RowLoading'
import { SortableBtn } from './private/SortableBtn/SortableBtn'
import { ActionCell } from './public/ActionCell/ActionCell'
import { Cell } from './public/Cell/Cell'
import { ExpandBody } from './public/ExpandBody/ExpandBody'
import { Row } from './public/Row/Row'

interface Props {
  columnData: typeof defaultColumns.general[]
  childComponents: ChildComponentCheck
  title: string
  hideBoxContainer?: boolean
  isLoading?: boolean
  selectActions?: SelectedActions
  searchConfig?: SearchConfig
  sortConfig?: SortConfig
  filterConfig?: FilterConfig
  sortCallback?: (label: string, direction: SortOpts) => void
  sentryRef?: UseInfiniteScrollHookRefCallback
  totalCount?: number
  headerCtas?: CtaProps[]
  children: ReactElement[]
}

const maxFr = 5
const minFr = 1

export const TableWrapper = withChildComponent(
  (props: Props) => {
    // --------------------- ===
    //  PROPS
    // ---------------------
    const {
      columnData,
      childComponents,
      title,
      hideBoxContainer = false,
      isLoading = false,
      selectActions = [],
      searchConfig = null,
      sortConfig = null,
      filterConfig = null,
      sentryRef = null,
      totalCount = null, // count from api
      headerCtas = null,
      children,
    } = props

    const rowCount = children ? children.length : 0
    // --------------------- ===
    //  STATE
    // ---------------------
    const [expandedRow, setExpandedRow] = useState<number | string>(null) // null = none down
    const [selectedRows, setSelectedRows] = useState<SelectedRows>([])
    const [width, setWidth] = useState(1000)
    const [isOverflow, setIsOverFlow] = useState({ left: false, right: false })
    const [colWidths, setColWidths] = useState<number[]>(
      columnData.map((col) => col.width)
    )
    const [xOffset, setXOffset] = useState(0)

    // consts
    const hasAction = childComponents[ActionCell.name]
    const hasExpand = childComponents[ExpandBody.name]
    const hasSelect = selectActions.length > 0

    // --------------------- ===
    //  REFS
    // ---------------------
    const tableContainer = useRef<HTMLDivElement>(null)
    const scrollContainer = useRef<HTMLDivElement>(null)
    const minTableWidth = useRef(0)

    // --------------------- ===
    //  HELPERS
    // ---------------------
    const getScrollContainerDif = () => {
      const containerStyle = getComputedStyle(scrollContainer.current)
      const scrollWidth =
        scrollContainer.current.clientWidth -
        (parseInt(containerStyle.paddingLeft) +
          parseInt(containerStyle.paddingRight))
      return tableContainer.current.offsetWidth - scrollWidth
    }
    const checkAllSelected = () =>
      selectedRows.length && selectedRows.length === rowCount
    const checkSomeSelected = () =>
      selectedRows.length && selectedRows.length > 0
    const isAllSelected = checkAllSelected()
    const isSomeSelected = checkSomeSelected()

    const checkOverflow = () => {
      const { scrollLeft } = scrollContainer.current
      const dif = getScrollContainerDif()

      const left = scrollLeft > 0
      const right = Math.ceil(scrollLeft) < dif

      if (left !== isOverflow.left || right !== isOverflow.right) {
        setIsOverFlow({
          left,
          right,
        })
      }
    }

    // --------------------- ===
    //  HANDLERS
    // ---------------------
    const handleExpandClick = (id: number | string) => {
      setExpandedRow((prev) => (prev === id ? null : id))
    }

    const handleHeaderSelectClick = (id: number | string) => {
      if (checkAllSelected()) {
        setSelectedRows([]) // select none
      } else {
        const ids = []
        Children.forEach(children, (child) => {
          ids.push(child.props.id)
        })
        setSelectedRows(ids) // select all
      }
    }

    const handleRowSelectClick = (id: number | string) => {
      setSelectedRows((prev) => {
        const newState = [...prev]
        const i = newState.indexOf(id)
        if (i > -1) {
          newState.splice(i, 1)
        } else {
          newState.push(id)
        }
        return newState
      })
    }

    const handleSelectClick = (id: number | string) => {
      if (id === -1) {
        handleHeaderSelectClick(id)
      } else {
        handleRowSelectClick(id)
      }
    }

    const handleSortClick = (label: string) => {
      if (sortConfig) {
        sortConfig.onChange(label, sortConfig.type === 'asc' ? 'desc' : 'asc')
      }
    }

    const handleScroll = () => {
      checkOverflow()
    }

    const handleColResize = (changePx: number, i: number) => {
      const { clientWidth } = tableContainer.current
      const changePct = changePx / clientWidth

      const totalParts = colWidths.reduce((partial, a) => partial + a, 0)
      setColWidths((prev) => {
        const newArr = [...prev]
        newArr[i] = Math.min(
          Math.max(newArr[i] + changePct * totalParts, minFr),
          maxFr
        )
        return newArr
      })
      setXOffset((prev) => Math.max(prev + changePx, minTableWidth.current))
    }

    const resetColWidths = () => {
      setColWidths(columnData.map((col) => col.width))
    }

    // --------------------- ===
    //  EFFECTS
    // ---------------------
    useMountEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth)
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    })

    useEffect(() => {
      checkOverflow()
      // Just width & xOffset
    }, [width, xOffset]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // Reset widths if columnData changes or width
      resetColWidths()
      // Just columnData & width
    }, [columnData, width]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // On mount and after reset, colWidths should match the original columnData
      // We use this state to set a min offset
      const originalWidths = columnData.map((col) => col.width)
      const isSame = originalWidths.every((w, i) => w === colWidths[i])
      if (isSame) {
        minTableWidth.current = getScrollContainerDif()
      }
      // Just colWidths
    }, [colWidths]) // eslint-disable-line react-hooks/exhaustive-deps

    // --------------------- ===
    //  RENDER
    // ---------------------
    return (
      <TableBox
        label={title}
        onDeselect={() => setSelectedRows([])}
        selectedRows={selectedRows}
        selectActions={selectActions}
        searchConfig={searchConfig}
        filterConfig={filterConfig}
        hideBoxContainer={hideBoxContainer}
        headerCtas={headerCtas}
        totalCount={totalCount}
        currentCount={rowCount}
      >
        {
          // HEADER ROW
        }
        <div className="relative">
          <div
            className="overflow-x-auto no-scrollbar px-4 -mx-4"
            ref={scrollContainer}
            onScroll={handleScroll}
            role="table"
            aria-label={title}
          >
            <div
              // bpTable:w-full to force 100% on big screens
              className="w-fit min-w-full flex flex-col gap-3 relative"
              ref={tableContainer}
              role="rowgroup"
              style={{
                minWidth: `calc(100% + ${xOffset}px)`,
              }}
            >
              <Row
                id="header"
                isHeader
                hasAction={hasAction}
                hasExpand={hasExpand}
                hasSelect={hasSelect}
                isAllSelected={isAllSelected}
                isSomeSelected={isSomeSelected}
                onSelectClick={() => handleSelectClick(-1)} // -1 = header select click
                colWidths={colWidths}
              >
                {columnData.map((cellData, i) => {
                  let resizeCursor = 'col-resize'
                  if (colWidths[i] === minFr) resizeCursor = 'e-resize'
                  if (colWidths[i] === maxFr) resizeCursor = 'w-resize'
                  return (
                    <Cell
                      key={`header${cellData.label}`}
                      resizeCursor={resizeCursor}
                      onResize={(changePx) => handleColResize(changePx, i)}
                    >
                      <h4 className="overflow-ellipsis overflow-hidden">
                        {cellData.sortLabel ? (
                          <SortableBtn
                            label={cellData.label}
                            sortType={
                              cellData.sortLabel === sortConfig?.label
                                ? sortConfig.type
                                : null
                            }
                            onClick={() =>
                              handleSortClick(
                                cellData.sortLabel || cellData.label
                              )
                            }
                          />
                        ) : (
                          cellData.label
                        )}
                      </h4>
                    </Cell>
                  )
                })}
              </Row>
              {
                // BODY ROWS
              }
              {Children.map(children, (child) =>
                // Pass to Row
                cloneElement(child, {
                  hasAction,
                  hasExpand,
                  hasSelect,
                  isAllSelected,
                  isSomeSelected,
                  expandedRow,
                  selectedRows,
                  colWidths,
                  onExpandClick: handleExpandClick,
                  onSelectClick: handleSelectClick,
                })
              )}
              {isLoading && (
                <RowLoading colWidths={colWidths} hasAction={hasAction} />
              )}
              {children?.length < 1 && !isLoading && <RowEmpty />}
              {<div ref={sentryRef} />}
            </div>
            {isOverflow.left && <OverflowGradient isLeft />}
            {isOverflow.right && <OverflowGradient />}
          </div>
        </div>
      </TableBox>
    )
  },
  [ActionCell, ExpandBody]
)
