import type { ReactNode } from 'react'

import type { CtaProps } from '@pgl-apps/shared/types'

import { CtaBtn } from '../../buttons'
import { PrimaryModal } from './PrimaryModal'

interface Props {
  children: ReactNode
  label?: string
  confirmConfig: Omit<CtaProps, 'type' | 'isFullWidth'>
  cancelConfig?: Omit<CtaProps, 'type' | 'isFullWidth' | 'state'> | null
  hideCtaButtons?: boolean
}

export const PromptModal = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    label = '',
    confirmConfig,
    cancelConfig = null,
    hideCtaButtons = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div>
      <PrimaryModal label={label}>{children}</PrimaryModal>
      {hideCtaButtons === false && (
        <div className="flex gap-4 flex-wrap sm:flex-nowrap justify-end">
          <div className="order-2 basis-full sm:basis-0">
            <CtaBtn
              type="primary"
              onClick={confirmConfig.onClick}
              state={confirmConfig.state}
            >
              {confirmConfig.label || 'Submit'}
            </CtaBtn>
          </div>
          {cancelConfig && (
            <div className="order-1 basis-full sm:basis-0">
              <CtaBtn type="tertiary" onClick={cancelConfig.onClick}>
                {cancelConfig.label || 'Cancel'}
              </CtaBtn>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
