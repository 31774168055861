import { utils } from 'ethers'
import { useSelector } from 'react-redux'

import { stakingOpts } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Cell, Row, TableWrapper } from '@pgl-apps/shared/components'
import { defaultColumns } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import styles from '../index.module.scss'

const columnData: typeof defaultColumns.general[] = [
  {
    ...defaultColumns.general,
    label: 'Pool',
  },
  {
    ...defaultColumns.general,
    label: 'Total Locked',
  },
  {
    ...defaultColumns.general,
    label: 'My Locked',
  },
  {
    ...defaultColumns.general,
    label: 'Max APR (%)',
  },
  {
    ...defaultColumns.general,
    label: 'My Rewards',
  },
]

interface Props {
  kapMode: boolean
  staking: Record<string, any>
  tvl: string
}
export const StakingOverview = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { kapMode, staking, tvl } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )
  const kapEthPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kapEthLP].price
  )

  return (
    <TableWrapper
      columnData={columnData}
      title="Balances"
      isLoading={staking.kapEth.loading}
      hideBoxContainer
    >
      {staking.kapEth.loading ? (
        []
      ) : (
        <Row id={1}>
          <Cell>
            <div className={styles.poolWrapper}>
              <div className={styles.icon}>
                <img
                  src={stakingOpts.kapEth.icon}
                  alt={stakingOpts.kapEth.label}
                />
              </div>
              <span>{stakingOpts.kapEth.label}</span>
            </div>
          </Cell>
          <Cell>{tvl}</Cell>
          <Cell>
            {!kapMode && '$'}
            {kapMode
              ? staking.kapEth.staker
                ? addNumPunctuation(
                    utils.formatEther(staking.kapEth.staker?.totalAmount),
                    2
                  )
                : ''
              : staking.kapEth.staker && kapEthPrice
              ? addNumPunctuation(
                  parseFloat(
                    utils.formatEther(staking.kapEth.staker?.totalAmount)
                  ) * kapEthPrice,
                  2
                )
              : ''}
          </Cell>
          <Cell>{staking.kapEth.maxApr}%</Cell>
          <Cell>
            {!kapMode && '$'}
            {
              // KAP amount, not KAP/ETH LP
              kapMode
                ? staking.kapEth.pendingRewards
                  ? addNumPunctuation(staking.kapEth.pendingRewards, 2)
                  : ''
                : addNumPunctuation(
                    parseFloat(staking.kapEth.pendingRewards) * kapPrice,
                    2
                  )
            }
          </Cell>
        </Row>
      )}
    </TableWrapper>
  )
}
