import { useState } from 'react'

import type { BaseInputTypes, InputConfig, SVGR } from '@pgl-apps/shared/types'

import { InputWrapper } from '../private/InputWrapper'
import { InputBase } from './private/InputBase'

interface Props {
  inputConfig: InputConfig
  value?: string
  label?: string
  onChange: (arg0: string) => void // should be useCallback
  required?: boolean
  suffix?: string
  hasSubmitErrors?: boolean
  type?: BaseInputTypes
  placeholder?: string
  Icon?: SVGR
  hideLabel?: boolean
}

export const Input = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    label,
    onChange,
    hasSubmitErrors = false,
    type = 'text',
    placeholder = '',
    Icon = null,
    hideLabel = false,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [didBlur, setDidBlur] = useState(false)

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleBlur = () => {
    setDidBlur(true)
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <InputWrapper
      label={label}
      hideLabel={hideLabel}
      inputConfig={inputConfig}
      canShowError={hasSubmitErrors || didBlur}
    >
      <InputBase
        inputConfig={inputConfig}
        onChange={onChange}
        onBlur={handleBlur}
        type={type}
        placeholder={placeholder}
        Icon={Icon}
      />
    </InputWrapper>
  )
}
