import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

interface Props {
  numerator: number
  denominator: number
  isMaxLevel?: boolean
}

export const XpBar = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { numerator, denominator, isMaxLevel = false } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const progressBar = useRef(null)

  // --------------------- ===
  //  ANIMATION
  // ---------------------
  useEffect(() => {
    const pct = isMaxLevel
      ? 100
      : Math.min((numerator / denominator) * 100, 100)

    gsap.to(progressBar.current, {
      width: `${pct}%`,
      duration: 1,
    })
  }, [numerator, denominator, isMaxLevel])
  const bgTheme = isMaxLevel ? 'highlighted' : 'lightsaber'
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="bg-white border border-white rounded-full relative h-[12px]">
      <div
        className={`absolute rounded-full bg-theme-${bgTheme} left-0 top-0 bottom-0 w-0`}
        ref={progressBar}
      />
    </div>
  )
}
