const rinkebyChainId = '0x4' // note the wallet.provider.chainID is also a string
const goerliChainId = '0x5'

export const config = {
  production: false,
  addresses: {
    staking: {
      // kap: '', // [TODO] Remove in production
      kapEth: '0xf1ADC3A6d583a18cb8A8c8CD7f37DAa6548E658E',
    },
    rewardsLocker: '0x00Dc301441a530c288a296a24f235Fc68F01bd0B',
    vesting: '0xE45c8400D4C251335470707058da1DC6502ff3d1',
    governance: '',
    tokens: {
      kap: '0xAdbf0143B6e24ff41a49961f915FEc79CfB16436',
      kapEthLP: '0x50e7f4Fb3C54260B60c75947D0558aA1d39994a8',
      weth: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
  },
  coingeckoID: {
    // CoinGecko API IDs, used to get price
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6': 'ethereum',
    '0xAdbf0143B6e24ff41a49961f915FEc79CfB16436': 'kapital-dao',
  },
  apis: {
    coinGecko: 'https://api.coingecko.com/api/v3',
  },
  network: 'goerli',
  blockExplorer: 'https://goerli.etherscan.io/tx/',
  chainId: goerliChainId,
}
