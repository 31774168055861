import { createAction, createReducer } from '@reduxjs/toolkit'
import { BigNumber, Contract, ethers, utils } from 'ethers'

import type { RewardsEmission } from '@pgl-apps/kap-stake/constants'
import { calculateAPR, year } from '@pgl-apps/kap-stake/helpers'
import { abiUniV2Pair, config } from '@pgl-apps/shared/api'
import {
  getActionStatusObj,
  getDefaultActionStatus,
  isActionType,
  setActionStatus,
} from '@pgl-apps/shared/helpers'

// We could also create reducers dynamically if we didn't know we needed kap and kapEth on mount
// https://medium.com/@matheusmm/redux-injecting-reducers-dynamically-e4e193eaa905

// TODO does it matter if we create a new provider on each instance?
const infuraProvider = new ethers.providers.InfuraProvider(config.network)

export const createContract = (
  sliceName: string,
  address: string,
  abi, // JSON
  asset: string,
  isLpStaking: boolean
) => {
  // --------------------- ===
  //  CONSTANTS
  // ---------------------
  const FETCH_CONTRACT_DATA = `${sliceName}/FETCH_CONTRACT_DATA`
  const FETCH_STAKING_AGREEMENTS = `${sliceName}/FETCH_STAKING_AGREEMENTS`
  const CLEAR_STAKING_AGREEMENTS = `${sliceName}/CLEAR_STAKING_AGREEMENTS`

  const constants = {
    FETCH_CONTRACT_DATA,
    FETCH_STAKING_AGREEMENTS,
    CLEAR_STAKING_AGREEMENTS,
  }

  // --------------------- ===
  //  INITIAL STATE
  // ---------------------
  const initialState = {
    provider: new Contract(address, abi, infuraProvider),
    asset,
    ...getDefaultActionStatus(FETCH_CONTRACT_DATA),
    cumulative: BigNumber.from(0), // global `cumulative` of contract
    totalWeight: BigNumber.from(0), // global `totalWeight` of contract
    rewardsRule: null, // global `rewardsRule`
    boostOn: null, // global
    lockPeriodLimits: {
      min: BigNumber.from(0),
      max: BigNumber.from(0),
    }, // global
    aprDivider: BigNumber.from(1),
    maxApr: null, // global
    agreements: [], // user's staking agreements
    pendingRewards: BigNumber.from(0), // total pending rewards of user
    totalStaked: BigNumber.from(0), // total staked amount of user
  }

  // --------------------- ===
  //  ACTIONS
  // ---------------------
  // SYNC
  const clearStakingAgreements = createAction(CLEAR_STAKING_AGREEMENTS)

  // ASYNC
  // fetch global status of staking contract
  const fetchContractData = () => (dispatch, getState) => {
    const { provider, asset } = getState()[sliceName]

    return dispatch({
      type: FETCH_CONTRACT_DATA,
      payload: provider
        .emission()
        .then(async (rewardsRule: RewardsEmission) => {
          let aprDivider = BigNumber.from(1)

          // calculate KAP amount per every LP token, for APR estimation
          if (isLpStaking) {
            const _assetContract = new Contract(
              asset,
              abiUniV2Pair.abi,
              provider.provider
            )
            const _token0 = await _assetContract.token0()
            const _reserves = await _assetContract.getReserves()
            const _totalSupply = await _assetContract.totalSupply()

            aprDivider =
              _token0 === config.addresses.tokens.weth
                ? _reserves[1].div(_totalSupply)
                : _reserves[0].div(_totalSupply)
            // WETH part
            aprDivider = aprDivider.mul(2)
          }

          const boostOn = await provider.boostOn()
          const min = await provider.MIN_LOCK()
          const max = await provider.MAX_LOCK()
          const totalWeight = await provider.totalWeight()

          // estimate current APR by the amount of 1 KAP/ETH, and 52 weeks duration
          const maxApr = await calculateAPR(
            utils.parseEther('1'),
            year,
            totalWeight,
            rewardsRule,
            aprDivider,
            false
          )

          return {
            rewardsRule,
            aprDivider,
            boostOn,
            lockPeriodLimits: {
              min,
              max,
            },
            maxApr,
          }
        }),
    })
  }

  // ASYNC
  // fetch user's staking agreements
  const fetchStakingAgreements = (_address: string) => (dispatch, getState) => {
    const { provider, aprDivider, rewardsRule } = getState()[sliceName]

    return dispatch({
      type: FETCH_STAKING_AGREEMENTS,
      payload: provider.cumulative().then(async (cumulative: BigNumber) => {
        const syncdTo = await provider.syncdTo()
        const totalWeight = await provider.totalWeight()
        /**
         * @dev Estimate `cumulative`
         * timeElapsed = block.timestamp < rewardsRule.expiration ? block.timestamp - syncdTo : rewardsRule.expiration - syncdTo
         * cumulative += emission.rate * timeElapsed * multiplier / totalWeight
         */
        const currentTimestamp = Math.floor(Date.now() / 1000)
        const timeElapsed =
          currentTimestamp < Number(rewardsRule.expiration)
            ? BigNumber.from(currentTimestamp).sub(syncdTo)
            : BigNumber.from(rewardsRule.expiration).sub(syncdTo)
        if (timeElapsed.gt(0)) {
          cumulative = cumulative.add(
            BigNumber.from(rewardsRule.rate)
              .mul(timeElapsed)
              .mul(1e12)
              .div(totalWeight)
          )
        }
        const totalStaked = await provider.totalStaked(_address)
        const _agreements = await provider.getDeposits(_address)
        let totalRewards = BigNumber.from(0)

        // estimate current APR by the amount of 1 KAP/ETH, and 52 weeks duration
        // reload it whenever there is new staking/claiming/boosting etc ...
        const maxApr = await calculateAPR(
          utils.parseEther('1'),
          year,
          totalWeight,
          rewardsRule,
          aprDivider,
          false
        )

        const agreements = []
        for (let id = 0; id < _agreements.length; id++) {
          if (_agreements[id].collected) continue

          const agreement = _agreements[id]
          const cumulativeDifference = cumulative.sub(agreement.cumulative)
          const weight = agreement.amount.mul(
            agreement.end.sub(agreement.start)
          ) // amount * lock-period

          // earned rewards
          const rewards = weight
            .mul(cumulativeDifference)
            .div(BigNumber.from(10).pow(12)) // cumulative multiplier

          const lockRemaining = agreement.end.gt(syncdTo)
            ? agreement.end.sub(syncdTo)
            : BigNumber.from(0)
          const rewardsRemaining = lockRemaining
            .mul(rewardsRule.rate)
            .mul(weight)
            .div(totalWeight)
          const apr = agreement.end.gt(syncdTo)
            ? rewardsRemaining
                .mul(BigNumber.from(year))
                .mul(100) // percent unit, mitigate division losses
                .div(agreement.amount)
                .div(lockRemaining)
                .div(aprDivider)
            : BigNumber.from(0)

          /**
           * @dev Boosting formula:
           * boostRewards = pendingRewards * boostRate * (lockRemaining / lockPeriod) * (lockExtension / maxLockExtension)
           * boostRate = 1 and let's assume lockExtension = maxLockExtension
           */
          const maxBoostRewards = rewards
            .mul(lockRemaining) // lockRemaining
            .div(agreement.end.sub(agreement.start)) // lockPeriod
          totalRewards = totalRewards.add(rewards)

          agreements.push({
            id,
            amount: utils.formatEther(agreement.amount),
            lockStart: agreement.start.toNumber(),
            lockEnd: agreement.end.toNumber(),
            rewards: Number(utils.formatEther(rewards)),
            maxBoostRewards: Number(utils.formatEther(maxBoostRewards)),
            apr: apr.toNumber(),
          })
        }

        return {
          cumulative,
          totalWeight,
          maxApr,
          agreements,
          totalStaked,
          totalRewards,
        }
      }),
    })
  }

  const actions = {
    clearStakingAgreements,
    fetchContractData,
    fetchStakingAgreements,
  }

  // --------------------- ===
  //  REDUCER
  // ---------------------
  const reducer = createReducer(initialState, (builder) => {
    builder
      .addCase(clearStakingAgreements, (state) => {
        state.agreements = []
        state.pendingRewards = BigNumber.from(0)
        state.totalStaked = BigNumber.from(0)
      })
      .addMatcher(
        (action) => isActionType(action, FETCH_CONTRACT_DATA),
        (state, action) => {
          setActionStatus(state, action)
          if (getActionStatusObj(action).isFulfilled) {
            const {
              rewardsRule,
              aprDivider,
              boostOn,
              lockPeriodLimits,
              maxApr,
            } = action.payload

            // update global status
            state.rewardsRule = rewardsRule
            state.aprDivider = aprDivider
            state.boostOn = boostOn
            state.lockPeriodLimits = lockPeriodLimits
            state.maxApr = maxApr
          }
        }
      )
      .addMatcher(
        (action) => isActionType(action, FETCH_STAKING_AGREEMENTS),
        (state, action) => {
          setActionStatus(state, action)
          if (getActionStatusObj(action).isFulfilled) {
            const {
              cumulative,
              totalWeight,
              maxApr,
              agreements,
              totalStaked,
              totalRewards,
            } = action.payload

            // update global status
            state.cumulative = cumulative
            state.totalWeight = totalWeight
            state.maxApr = maxApr
            // update user's own status
            state.agreements = agreements
            state.pendingRewards = totalRewards
            state.totalStaked = totalStaked
          }
        }
      )
  })

  return {
    reducer,
    actions,
    constants,
  }
}
