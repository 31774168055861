// --------------------- ===
//  MODAL CONSTANTS
// ---------------------
export const modalTypes = {
  boostMultiple: 'BOOST_MULTIPLE',
  boostSingle: 'BOOST_SINGLE',
  claim: 'CLAIM',
  collectVesting: 'COLLECT_VESTING',
  collectLockedRewards: 'COLLECT_LOCKED_REWARDS',
  stake: 'STAKE',
  unstake: 'UNSTAKE',
  wallet: 'WALLET',
}
