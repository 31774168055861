import styles from './PrimaryNav.module.scss'
import DropdownMenu from './private/DropdownMenu/DropdownMenu'
import { MenuTrigger } from './private/MenuTrigger/MenuTrigger'

export const PrimaryNav = ({ clickHandler, navIsOpen }) => {
  // --------------------- ===
  //  RETURN
  // ---------------------
  return (
    <div className="hidden lg:block top-full left-0">
      <div className={styles.wrapper}>
        <div className="flex align-items-center">
          <div className={styles.trigger}>
            <MenuTrigger
              onClick={clickHandler}
              label="Toggle nav"
              isActive={navIsOpen}
            />
          </div>
          <DropdownMenu navIsOpen={navIsOpen} clickHandler={clickHandler} />
        </div>
      </div>
    </div>
  )
}
