import { BigNumber, Contract } from 'ethers'

export const day = 60 * 60 * 24
export const week = 7 * day
export const year = 52 * week

export const calculateAPR = async (
  amount: BigNumber,
  lockPeriod: number, // seconds
  currentTotalStakingWeight: BigNumber,
  rewardsRule, // RewardsRule{rate, expiration},
  divider, // for LP token specific, KAP/LP unit
  futureApr: boolean
) => {
  // exit if rewards rule is not set
  if (!rewardsRule || amount.toString() === '0') {
    return '0'
  }

  const stakingWeight = amount.mul(lockPeriod)
  const kapPerSecond = BigNumber.from(rewardsRule.rate)

  // Multiplier used to mitigate division losses and improve int math accuracy
  const multiplier = BigNumber.from(10).pow(12)
  /**
   * @dev if futureApr = true, then it predicts upcoming APR with new staking agreements.
   * if not, then it calculates current APR.
   */
  const newTotalStakingWeight = futureApr
    ? currentTotalStakingWeight.add(stakingWeight)
    : currentTotalStakingWeight
  const multipliedRewardsPerYear = kapPerSecond
    .mul(year)
    .mul(multiplier)
    .mul(stakingWeight)
    .div(newTotalStakingWeight)
  const rewardsPerYear = multipliedRewardsPerYear.div(multiplier)

  // Percentage unit
  const apr = rewardsPerYear.mul(100).div(amount).div(divider)
  return apr.toString()
}
