import { gsap } from 'gsap'
import type { ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { findTabbableDescendants } from '@pgl-apps/shared/helpers'
import { useMountEffect } from '@pgl-apps/shared/hooks'
import type { BaseModalProps, ModalSettings } from '@pgl-apps/shared/types'

import styles from './index.module.scss'
import {
  CenterModal,
  centerModalInParams,
  centerModalOutParams,
} from './private/CenterModal/CenterModal'
import {
  LeftModal,
  leftModalInParams,
  leftModalOutParams,
} from './private/LeftModal/LeftModal'
import type { PrivateModalProps } from './private/types'

interface Props extends BaseModalProps {
  children: ReactNode
  settings: ModalSettings
  isVisible: boolean
  onClearModalType: () => void
}

const animationDuration = 0.42 // seconds

// --------------------- ===
//  MODAL TYPE SETTINGS
// ---------------------
const modals: Record<
  ModalSettings['type'],
  {
    comp: ForwardRefExoticComponent<
      PrivateModalProps & RefAttributes<HTMLDivElement>
    >
    inParams: Record<string, string | number>
    outParams: Record<string, string | number>
  }
> = {
  left: {
    comp: LeftModal,
    inParams: {
      ...leftModalInParams,
      duration: animationDuration,
    },
    outParams: {
      ...leftModalOutParams,
      duration: animationDuration,
    },
  },
  center: {
    comp: CenterModal,
    inParams: {
      ...centerModalInParams,
      duration: animationDuration,
    },
    outParams: {
      ...centerModalOutParams,
      duration: animationDuration,
    },
  },
}

// MODAL PARAMS
const inModalParams = {
  duration: animationDuration / 2,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
}
const outModalParams = {
  duration: animationDuration,
  display: 'none',
  backgroundColor: 'rgba(0, 0, 0, 0)',
}

export const ModalWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, onCloseModal, settings, isVisible, onClearModalType } =
    props

  const modalContent = modals[settings.type]

  // --------------------- ===
  //  REFS
  // ---------------------
  const modalRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const wasVisible = useRef(false)

  const modalTween = useRef<GSAPAnimation>()
  const contentTween = useRef<GSAPAnimation>()

  const triggerElem = useRef<HTMLElement | Element | null>()

  // --------------------- ===
  //  ANIMATION FUNCTIONS
  // ---------------------
  const focusModal = () => {
    triggerElem.current = document.activeElement
    if (contentRef.current) {
      const el: HTMLElement =
        findTabbableDescendants(contentRef.current)[0] || contentRef.current
      // prevents close button from being focused if there is no other focusable content
      if (el.id !== 'modalCloseBtn') {
        el.focus()
      }
    }
  }

  const returnFocus = () => {
    if (triggerElem.current) {
      // @ts-expect-error TODO: work on improving the types
      triggerElem.current.focus()
    }
    triggerElem.current = null
  }

  const handleFocus = useCallback((isActive: boolean) => {
    if (isActive) {
      focusModal()
    } else {
      returnFocus()
    }
  }, [])

  const handleKeydown = useCallback(
    (evt: KeyboardEvent) => {
      const { key } = evt

      // Esc key
      if (key === 'Escape') {
        onCloseModal()
      }
    },
    [onCloseModal]
  )

  const handleClickOutside = useCallback(
    (evt: PointerEvent) => {
      // @ts-expect-error TODO: work on improving the types
      if (!contentRef.current?.contains(evt.target)) {
        onCloseModal()
      }
    },
    [onCloseModal]
  )

  // --------------------- ===
  //  ANIMATION CONTROL
  // ---------------------
  const modalIn = useCallback(() => {
    gsap.set(modalRef.current, {
      display: 'flex',
      onComplete: () => handleFocus(true),
    })
    modalTween.current = gsap.to(modalRef.current, {
      ...inModalParams,
    })
    contentTween.current = gsap.to(contentRef.current, {
      ...modalContent.inParams,
    })
  }, [handleFocus, modalContent.inParams])

  const modalOut = useCallback(() => {
    modalTween.current = gsap.to(modalRef.current, {
      ...outModalParams,
      onComplete: () => handleFocus(false),
    })
    contentTween.current = gsap.to(contentRef.current, {
      ...modalContent.outParams,
      onComplete: onClearModalType,
    })
  }, [handleFocus, modalContent.outParams, onClearModalType])

  const animationControl = useCallback(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleKeydown)
      document.addEventListener('pointerdown', handleClickOutside)
      modalTween.current && modalTween.current.pause()
      modalIn()
    } else {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('pointerdown', handleClickOutside)
      modalOut()
    }
  }, [handleClickOutside, handleKeydown, isVisible, modalIn, modalOut])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    // Set start
    gsap.set(modalRef.current, {
      ...outModalParams,
    })
    gsap.set(contentRef.current, {
      ...modalContent.outParams,
    })
  })

  useEffect(() => {
    if (isVisible !== wasVisible.current) {
      animationControl()
      wasVisible.current = isVisible
    }
  }, [isVisible, animationControl])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const Modal = modalContent.comp

  return (
    <div
      ref={modalRef}
      className={`${styles.modal} bg-black bg-opacity-30 fixed inset-0 flex items-center content-center z-modal`}
      tabIndex={-1}
      role={isVisible ? 'dialog' : 'presentation'}
      aria-modal={isVisible}
    >
      <Modal onCloseModal={onCloseModal} ref={contentRef}>
        {children}
      </Modal>
    </div>
  )
}
