import { ReactComponent as Plus } from '@pgl-apps/shared-images/svgr/icons/plus.svg'

interface Props {
  label: string
  isActive: boolean
  isOpen: boolean
  onClick: () => void
  onXClick?: () => void
}

export const FilterTag = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, isActive, isOpen, onClick, onXClick = null } = props

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleClick = (isX) => {
    if (isX && onXClick) onXClick()
    else onClick()
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  let stateClass =
    'bg-theme-tableBg text-theme-highlighted border-theme-tableBg'
  if (isActive)
    stateClass =
      'bg-theme-highlighted text-theme-tableBg border-theme-highlighted'
  if (isOpen) stateClass += ' !border-theme-highlighted'

  return (
    <div className={`rounded-full flex items-center border ${stateClass}`}>
      <button
        onClick={() => handleClick(true)}
        className="p-1 pl-2"
        type="button"
      >
        <Plus
          className={`w-2.5  transition-transform ${
            isActive ? '-rotate-45' : ''
          }`}
        />
      </button>
      <span className="w-px self-stretch bg-theme-tableHeaderBg m-1 mr-2" />
      <button
        onClick={() => handleClick(false)}
        className="py-1 pr-2"
        type="button"
      >
        <span className="w-px h-4 bg-theme-muted bg-opacity-50" />
        <span className="text-sm">{label}</span>
      </button>
    </div>
  )
}
