import { useMemo } from 'react'

import type { FilterOpt, Filters } from '@pgl-apps/shared/types'

// rename to handleFilterClick
export function handleFilterClick(
  key: string | null,
  defaultFilters: Filters,
  setFilters: (value: React.SetStateAction<Filters>) => void,
  opt?: FilterOpt
) {
  if (key && opt) {
    setFilters((prev: Filters) => {
      const index = prev[key].options.findIndex((o) => o === opt)
      const newState = {
        ...prev,
      }
      const { options } = newState[key]
      if (index > -1) {
        options[index].isSelected = !options[index].isSelected
      }
      if (defaultFilters[key].isSingleChoice) {
        // reset all others
        options.forEach((opt, i) => {
          if (i !== index) opt.isSelected = false
        })
      }
      return newState
    })
  } else if (key) {
    setFilters((prev: Filters) => {
      const newState = {
        ...prev,
      }
      const { options } = newState[key]
      options.forEach((opt) => {
        opt.isSelected = false
      })
      return newState
    })
  } else setFilters(JSON.parse(JSON.stringify(defaultFilters)))
}

/**
 * Constructs API parameters based on filter selections.
 * @param filters Filters with their options and selections.
 * @returns A constructed parameter object to pass to API call.
 */
export const useGetApiFilterParams = (filters: Filters) => {
  const filterParams = useMemo(() => {
    const params = {}
    Object.keys(filters).forEach((key) => {
      const selectedOptions = []
      filters[key].options.forEach((option) => {
        // TODO: Backend should be changed to accept quotas as a single parameter vs two.
        // Once that's done, write logic to parse filters based on Filter.isSingleChoice.
        if (key === 'quota') {
          if (option.apiKey === 'above_quota' && option.isSelected)
            params[option.apiKey] = true
          if (option.apiKey === 'below_quota' && option.isSelected)
            params[option.apiKey] = true
        }
        option.isSelected && selectedOptions.push(option.apiKey)
      })

      // TODO: Ignore quota key param until backend is changed.
      if (key !== 'quota') {
        params[key] = selectedOptions
      }
    })
    return params
  }, [filters])
  return filterParams
}
