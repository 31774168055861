import React from 'react'
import type { Location } from 'react-router-dom'
import { Link } from 'react-router-dom'

interface Props {
  location: Location
}

const cleanPath = (path: string) => path.replace('-', ' ')

export const Breadcrumbs = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { location } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const pathArr = location.pathname.substring(1).split('/') // substring removes first "/"
  let builtHref = ''

  return (
    <div className="w-full">
      {pathArr.map((path, i) => {
        const cleanedPath = cleanPath(path)
        builtHref = `${builtHref}/${path}`
        return (
          <span key={i} className="text-sm capitalize">
            {i !== 0 && <span> &#8250; </span>}
            {i === pathArr.length - 1 ? (
              <span className="text-theme-muted">{cleanedPath}</span>
            ) : (
              <Link to={builtHref}>{cleanedPath}</Link>
            )}
          </span>
        )
      })}
    </div>
  )
}
