import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as ExternalIcon } from '@pgl-apps/shared-images/svgr/icons/external-link.svg'

interface Props {
  children?: ReactNode
  className?: string
  type?: 'internal' | 'external'
  path: string
}

export const TextLink = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, className = '', type = 'internal', path } = props

  const linkClass = `text-theme-highlighted flex items-center gap-1 ${className}`
  // --------------------- ===
  //  RENDER
  // ---------------------
  if (type === 'external') {
    return (
      <a className={linkClass} href={path} target="_blank" rel="noreferrer">
        <span>{children}</span>
        <span className="w-3 grow-0 shrink-0">
          <ExternalIcon />
        </span>
      </a>
    )
  }

  return (
    <Link className={linkClass} to={path}>
      {children}
    </Link>
  )
}
