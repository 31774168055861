import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createReducer } from '@reduxjs/toolkit'

import type { TxHash } from '@pgl-apps/shared/types'

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'transactions'

const ADD_TRANSACTION = `${sliceName}/ADD_TRANSACTION`
const UPDATE_TRANSACTION = `${sliceName}/UPDATE_TRANSACTION`
const REMOVE_TRANSACTION = `${sliceName}/REMOVE_TRANSACTION`
const FLUSH_TRANSACTIONS = `${sliceName}/FLUSH_TRANSACTIONS`

type TransactionState = {
  list: TxHash[]
}

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState: TransactionState = {
  list: [],
}

// --------------------- ===
//  ACTIONS
// ---------------------
export const addTransaction = createAction<TxHash>(ADD_TRANSACTION)
export const updateTransaction = createAction<TxHash>(UPDATE_TRANSACTION)
export const removeTransaction = createAction<number>(REMOVE_TRANSACTION)
export const flushTransactions = createAction(FLUSH_TRANSACTIONS)

// --------------------- ===
//  REDUCER
// ---------------------
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addTransaction, (state, action: PayloadAction<TxHash>) => {
      state.list.push(action.payload)
    })
    .addCase(updateTransaction, (state, action: PayloadAction<TxHash>) => {
      const target = state.list.find((x) => x.id === action.payload.id)
      target.txHash = action.payload.txHash
      target.status = action.payload.status
      target.message = action.payload.message
    })
    .addCase(removeTransaction, (state, action: PayloadAction<number>) => {
      const target = state.list.find((x) => x.id === action.payload)
      if (target) {
        state.list.splice(state.list.indexOf(target), 1)
      }
    })
    .addCase(flushTransactions, (state) => {
      state.list = []
    })
})

export default reducer
