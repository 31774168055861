import { useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'

import styles from './index.module.scss'
import { Trigger } from './private/Trigger'

interface Props {
  children?: ReactNode
}

export const ActionCell = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const proxy = useRef<HTMLDivElement>(null)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDown, setIsDown] = useState(false)
  const [bucketPosition, setBucketPosition] = useState({
    x: 0,
    y: 0,
  })

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const handleDropdownPos = () => {
      const { top, right } = proxy.current.getBoundingClientRect()

      setBucketPosition({ x: right, y: top })
    }
    if (isDown) {
      handleDropdownPos()
      document.addEventListener('scroll', handleDropdownPos)
      window.addEventListener('resize', handleDropdownPos)
    }
    return () => {
      document.removeEventListener('scroll', handleDropdownPos)
      window.removeEventListener('resize', handleDropdownPos)
    }
  }, [isDown])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const downClass = isDown ? styles.action__down : ''
  return (
    <div className={`${styles.action} ${downClass}`}>
      <Trigger isDown={isDown} onClick={() => setIsDown((prev) => !prev)} />
      <div ref={proxy} />
      <div
        className={styles.bucket}
        style={{ top: bucketPosition.y + 8, left: bucketPosition.x }}
      >
        {children}
      </div>
    </div>
  )
}
