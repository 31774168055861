import { useRef, useState } from 'react'

import dinolg from '@pgl-apps/shared-images/decoration/dino@1x.png'

interface Props {
  src: string
  placeholderSrc: string
  backupSrc?: string
  alt?: string
  className?: string
}

export const ProgressiveImgLoader = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    src,
    placeholderSrc,
    backupSrc = dinolg,
    alt = '',
    className = '',
  } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const highResImage = useRef<HTMLImageElement>(null)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isTinyError, setIsTinyError] = useState(false)

  return (
    <div className={`relative w-full h-full ${className}`}>
      <img
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
        ref={highResImage}
        src={isError ? backupSrc : src}
        alt={alt}
        className=""
      />
      <img
        className={`blur-[1px] absolute inset-0 transition-opacity duration-1000 w-full ${
          isLoaded || isError ? 'opacity-0' : ''
        }`}
        onError={() => setIsTinyError(true)}
        src={isTinyError ? backupSrc : placeholderSrc}
        alt={alt}
      />
    </div>
  )
}
