import { createAction, createReducer } from '@reduxjs/toolkit'
import { BigNumber, Contract, ethers, utils } from 'ethers'

import { config } from '@pgl-apps/shared/api'
import { abiVesting } from '@pgl-apps/shared/api'
import {
  getActionStatusObj,
  isActionType,
  setActionStatus,
} from '@pgl-apps/shared/helpers'

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'vestings'

// TODO does it matter if we create a new provider on each instance?
const infuraProvider = new ethers.providers.InfuraProvider(config.network)

// --------------------- ===
//  CONSTANTS
// ---------------------
const FETCH_VESTING_AGREEMENTS = `${sliceName}/FETCH_VESTING_AGREEMENTS`
const CLEAR_VESTING_AGREEMENTS = `${sliceName}/CLEAR_VESTING_AGREEMENTS`

const constants = {
  FETCH_VESTING_AGREEMENTS,
  CLEAR_VESTING_AGREEMENTS,
}

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState = {
  provider: new Contract(
    config.addresses.vesting,
    abiVesting.abi,
    infuraProvider
  ),
  agreements: [],
  balances: BigNumber.from(0),
}

// --------------------- ===
//  ACTIONS
// ---------------------
const clearVestingAgreements = createAction(CLEAR_VESTING_AGREEMENTS)
const fetchVestingAgreements = (_address: string) => (dispatch, getState) => {
  const { provider } = getState()[sliceName]

  return dispatch({
    type: FETCH_VESTING_AGREEMENTS,
    payload: provider.balances(_address).then(async (balances) => {
      const agreements = []

      // [TODO] Fetch maximum 5 agreements
      for (let id = 0; id < 5; id++) {
        try {
          const agreement = await provider.vestingAgreements(_address, id)
          // skip collected agreement
          if (agreement.totalAmount == agreement.amountCollected) continue

          agreements.push({
            id,
            vestStart: agreement.vestStart.toNumber(),
            vestPeriod: agreement.vestPeriod.toNumber(),
            totalAmount: utils.formatEther(agreement.totalAmount),
            amountCollected: utils.formatEther(agreement.amountCollected),
          })
        } catch (e) {
          // it means, no more agreements available
          break
        }
      }

      return {
        balances,
        agreements,
      }
    }),
  })
}

const actions = {
  clearVestingAgreements,
  fetchVestingAgreements,
}

// --------------------- ===
//  REDUCER
// ---------------------
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearVestingAgreements, (state) => {
      state.agreements = []
      state.balances = BigNumber.from(0)
    })
    .addMatcher(
      (action) => isActionType(action, FETCH_VESTING_AGREEMENTS),
      (state, action) => {
        setActionStatus(state, action)
        if (getActionStatusObj(action).isFulfilled) {
          state.agreements = action.payload.agreements
          state.balances = action.payload.balances
        }
      }
    )
})

export const vestings = {
  reducer,
  actions,
  constants,
}

export default reducer
