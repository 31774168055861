import type { AxiosResponse } from 'axios'
import { useRef, useState } from 'react'

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>
  readonly preDownloading: () => void
  readonly postDownloading: () => void
  readonly onError: () => void
  readonly onSuccess: () => void
  readonly filename: string
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
  readonly name: string | undefined
  readonly url: string | undefined
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  onSuccess,
  filename,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const [url, setFileUrl] = useState<string>()
  const [name, setFileName] = useState<string>()

  const download = async () => {
    try {
      preDownloading()
      const { data } = await apiDefinition()
      const url = URL.createObjectURL(new Blob([data]))
      setFileUrl(url)
      setFileName(filename)
      ref.current?.click()
      postDownloading()
      URL.revokeObjectURL(url)
      onSuccess()
    } catch (error) {
      onError()
    }
  }

  return { download, ref, url, name }
}
