import type { Status as StatusProps } from '@pgl-apps/shared/types'

interface Props {
  type: StatusProps
  label: string
}

const statusClasses = {
  success: 'text-theme-success',
  error: 'text-theme-error',
  warning: 'text-theme-warning',
  info: 'text-theme-info',
  inactive: 'text-theme-muted',
}

export const Status = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { type, label } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <span className={`capitalize ${statusClasses[type]}`}>{label}</span>
}
