import type { ReactNode } from 'react'
import React from 'react'

import { Heading } from '../../typography'

interface Props {
  children: ReactNode
  headline?: string
}

export const Section = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, headline } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <section className="mb-8">
      {headline && (
        <Heading type="paragraph" tag="h3">
          {headline}
        </Heading>
      )}
      {children}
    </section>
  )
}
