import type * as Highcharts from 'highcharts'

import type { HCChartTypes } from '@pgl-apps/shared/types'

import { hcDefaultOptions } from '../hcDefaults'

export const optionsWithTypeAndSeries = (
  type: HCChartTypes,
  series: Highcharts.SeriesOptionsType[]
) => ({
  ...hcDefaultOptions,
  chart: {
    ...hcDefaultOptions.chart,
    type,
  },
  series,
})
