export interface ModalTypes {
  [key: string]: string
}

export interface BaseModalProps {
  onCloseModal: (arg?: unknown) => void
}

export interface ModalSettings {
  modalClass: string
  type: 'center' | 'left'
}
