import type { ElementType } from 'react'
import { useEffect, useState } from 'react'

import { Heading } from '../../typography'
import { ProgressBar } from './private/ProgressBar'

export interface WizardStep {
  title?: string
  description?: string
  key: string
  stepNumber: number
  component?: ElementType
}

interface WizardWrapperProps {
  steps: WizardStep[]
  currentStep?: number
  handleNext: () => void
}

export const WizardWrapper = (props: WizardWrapperProps) => {
  const { steps, currentStep = 1, handleNext } = props

  const [activeStep, setActiveStep] = useState<WizardStep>(
    steps.find((step) => step.stepNumber === currentStep)
  )

  useEffect(() => {
    const newStep = steps.find((step) => step.stepNumber === currentStep)
    setActiveStep(newStep)
  }, [currentStep, steps])

  return (
    <div className="w-full flex flex-col items-center mt-10 mb-10">
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div className="flex flex-col items-center text-center mb-16">
        <Heading>
          <span className="text-3xl font-normal">{activeStep.title}</span>
        </Heading>
        {/* <span className="w-11/12 lg:w-7/12">{activeStep.description}</span> */}
        <span className="w-11/12 lg:w-7/12">
          <div dangerouslySetInnerHTML={{ __html: activeStep.description }} />
        </span>
      </div>
      <activeStep.component handleNext={handleNext} />
    </div>
  )
}
