import type { ReactNode } from 'react'

import { Heading } from '../../typography'

interface Props {
  children: ReactNode
  label?: string
}

export const PrimaryModal = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, label = '' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="text-left">
      {label && (
        <Heading type="article" tag="h4" hasDivider={true}>
          {label}
        </Heading>
      )}
      <div className="py-12">{children}</div>
    </div>
  )
}
