import { utils } from 'ethers'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { BOOSTING_DELAY, apyDisclaimer } from '@pgl-apps/kap-stake/constants'
import { stakingOpts, useStaking, useToken } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Input, Label, Tag } from '@pgl-apps/shared/components'
import { defaultInputConfig } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../buttons'
import { DurationSlider } from '../../../lockups'
import { AprBox } from '../../../messaging'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { WrongChain } from '../Stake/WrongChain'

const secondsPerWeek = 604800

interface Props {
  onCloseModal: (arg?: unknown) => void
  agreementId?: number
  hookKey?: keyof typeof stakingOpts
}

export const BoostSingle = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onCloseModal, agreementId, hookKey } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const chainId = useSelector((store: IRootState) =>
    store.wallet.provider ? store.wallet.walletNetwork : null
  )

  const wrongChain = chainId && chainId !== Number(config.chainId)

  const { walletBalanceBN } = useToken(walletId)[hookKey]
  const {
    stakingAgreements,
    currentLockPeriod,
    setCurrentLockPeriod,
    setStakeInputAmount,
    claimRewards,
    lockPeriodLimits,
    currentApr,
  } = useStaking(walletId, signer)[hookKey]

  const [selectedAgreement, lockPeriod, remainingSeconds, remainingWeeks] =
    useMemo(() => {
      const _agreement = stakingAgreements.find((a) => a.id === agreementId)
      const _lockPeriod = _agreement.lockEnd - _agreement.lockStart
      const _remainingSeconds =
        _agreement.lockEnd - Math.floor(Date.now() / 1000)
      const _remainingWeeks = Math.floor(_remainingSeconds / secondsPerWeek)
      return [_agreement, _lockPeriod, _remainingSeconds, _remainingWeeks]
    }, [agreementId])

  const isInvalidExtend = useMemo(
    () => currentLockPeriod <= remainingSeconds,
    [currentLockPeriod, remainingSeconds]
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = () => {
    const extendPeriod = currentLockPeriod - remainingSeconds - BOOSTING_DELAY
    console.log('Extend period: ', extendPeriod / 86400)
    claimRewards([agreementId], [extendPeriod])

    onCloseModal()
  }

  // --------------------- ===
  //  HOOKS
  // ---------------------
  useEffect(() => {
    if (selectedAgreement) {
      setStakeInputAmount(selectedAgreement.amount)
    }
  }, [selectedAgreement])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const amountInputConfig = {
    ...defaultInputConfig,
    state: 'disabled' as const,
    value: selectedAgreement.amount,
    validation: (val) => '',
  }

  /**
   * @dev Boosting formula:
   * boostRewards = pendingRewards * boostRate * (remainingPeriod / lockPeriod) * (extendedLockPeriod / maxExtendableLockPeriod), boostRate = 1
   * totalRewards = pendingRewards + boostRewards = pendingRewards * [1 + (remainingPeriod / lockPeriod) * (extendedLockPeriod / maxExtendableLockPeriod)]
   */
  const boostKap = selectedAgreement
    ? (((selectedAgreement.rewards * remainingSeconds) / lockPeriod) *
        (currentLockPeriod - remainingSeconds)) /
      (lockPeriodLimits.max - remainingSeconds)
    : 0

  return (
    <ModalContentWrapper>
      {wrongChain ? (
        <WrongChain />
      ) : (
        <div>
          <div className="divider-b flex flex-wrap w-full items-center justify-between">
            <div className="flex">
              <button
                key={hookKey}
                className="mr-2 hover:underline"
                type="button"
              >
                <Tag type="default">{stakingOpts[hookKey].label}</Tag>
              </button>
            </div>
            <div className="flex flex-col text-left">
              <Label>Balance</Label>
              <div>
                <span className="text-xl">
                  {addNumPunctuation(utils.formatEther(walletBalanceBN), 2)}
                </span>
                <Label className="ms-2 text-uppercase">
                  {stakingOpts[hookKey].label}
                </Label>
              </div>
            </div>
          </div>

          <div className="divider-b text-left py-4 grid grid-cols-1 sm:grid-cols-2">
            <div className="flex items-center content-center mx-auto">
              <div className="flex flex-col content-center mb-8">
                <div className="flex items-end">
                  <Input
                    inputConfig={amountInputConfig}
                    label="Staking Amount"
                    type="number"
                    onChange={undefined} // disabled on boost
                    placeholder="0"
                    hasSubmitErrors={false}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center content-center mx-auto">
              {
                // Wait for lockPeriodLimts to be created
                lockPeriodLimits.min.toNumber() <
                  lockPeriodLimits.max.toNumber() && (
                  <DurationSlider
                    min={lockPeriodLimits.min.div(secondsPerWeek).toNumber()} // seconds in a week
                    max={lockPeriodLimits.max.div(secondsPerWeek).toNumber()} // seconds in a week
                    onChange={(weeks: number) => {
                      // boosted period should be greater than remaining weeks
                      if (weeks >= remainingWeeks) {
                        setCurrentLockPeriod(weeks * secondsPerWeek)
                      }
                    }}
                    label="Week"
                    value={Math.max(
                      Math.round(currentLockPeriod / secondsPerWeek),
                      remainingWeeks
                    )}
                    title="Total Boost Duration"
                  />
                )
              }
            </div>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center mt-4 gap-4">
            <div className="flex items-center gap-2 w-full sm:w-1/2">
              <div className="grow basis-full">
                <AprBox label="Current APR" unit="%" value={currentApr} />
              </div>
              <div className="grow basis-full">
                <AprBox
                  label="Bonus KAP"
                  isHighlighted
                  value={addNumPunctuation(Math.max(boostKap, 0), 1)}
                />
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <CtaPrimary
                onClick={handleSubmit}
                isDisabled={isInvalidExtend}
                label="Boost Now"
              />
            </div>
          </div>
          <p className="text-xs text-theme-muted text-start font-bold mt-6">
            <span className="align-super">*</span>&nbsp; Boosting your stake
            will extend your lock period and grant you additional tokens from
            the bonus pool.
          </p>
          <p className="text-xs text-theme-muted text-start mt-4">
            <span className="align-super">*</span>&nbsp;
            {apyDisclaimer}&nbsp;
            <a
              href="https://docs.kapital.gg/disclaimer"
              target="_blank"
              rel="noreferrer"
              className="text-xs underline"
            >
              See More
            </a>
          </p>
        </div>
      )}
    </ModalContentWrapper>
  )
}
