import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { BOOSTING_DELAY } from '@pgl-apps/kap-stake/constants'
import { year } from '@pgl-apps/kap-stake/helpers'
import { useNotifications, useStaking } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { kapEth, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Heading } from '@pgl-apps/shared/components'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'
import { NotificationTypes } from '@pgl-apps/shared/types'

import { CtaPrimary } from '../../../buttons'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { CancelBtn } from '../Claim/private/CancelBtn'
import { WrongChain } from '../Stake/WrongChain'

interface Props {
  onCloseModal: (arg?: unknown) => void
  selectedAgreements?: any
}

export const BoostMultiple = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onCloseModal, selectedAgreements } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )
  const chainId = useSelector((store: IRootState) =>
    store.wallet.provider ? store.wallet.walletNetwork : null
  )

  const wrongChain = chainId && chainId !== Number(config.chainId)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const staking = useStaking(walletId, signer)
  const { addNotification } = useNotifications()

  const totalBoostRewardsAmount = useMemo(() => {
    if (!selectedAgreements) return 0

    let amountSum = 0
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const id of selectedAgreements?.kapEth) {
      const agreement = staking.kapEth.stakingAgreements.find(
        (a) => a.id === id
      )
      amountSum += agreement.maxBoostRewards
    }
    return amountSum
  }, [selectedAgreements])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = useCallback(async () => {
    const calcMaxExtendPeriod = (agreement) => {
      const remaining = agreement.lockEnd - Math.ceil(Date.now() / 1000)
      return year - remaining - BOOSTING_DELAY
    }

    onCloseModal()

    if (selectedAgreements?.kapEth?.length) {
      const ids = []
      const extPeriods = []
      selectedAgreements.kapEth.forEach((id) => {
        const agreement = staking.kapEth.stakingAgreements.find(
          (a) => a.id === id
        )
        const maxExtPeriod = calcMaxExtendPeriod(agreement)
        console.log('Max extend period: ', maxExtPeriod / 86400)
        if (maxExtPeriod > 0) {
          ids.push(id)
          extPeriods.push(maxExtPeriod)
        } else {
          addNotification({
            message: 'You cannot boost this stake. Please try again later.',
            type: NotificationTypes.error,
          })
        }
      })

      await staking.kapEth.claimRewards(ids, extPeriods)
    }

    // update all agreements
    dispatch(kapEth.actions.fetchStakingAgreements(walletId))
  }, [selectedAgreements, walletId])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <ModalContentWrapper>
      {wrongChain ? (
        <WrongChain />
      ) : (
        <div>
          <div className="flex flex-col items-center pt-16">
            <Heading type="page" tag="h2">
              Boost selected Staking Agreements?
            </Heading>
            <div className="my-12">
              <Heading type="paragraph" tag="h4" className="mb-2">
                Additional Earnings
              </Heading>
              <p className="flex items-end">
                <span className="text-4xl text-theme-highlighted">
                  {addNumPunctuation(totalBoostRewardsAmount, 1)} KAP
                </span>
                <span className="mb-1 text-xs text-theme-muted">
                  ($
                  {addNumPunctuation(totalBoostRewardsAmount * kapPrice, 1)})
                </span>
              </p>
            </div>
            <p className="text-theme-muted">
              Clicking "Boost Selected" will boost all your <br />
              selected staking agreements to 52 weeks.
            </p>
          </div>
          <div className="flex justify-end items-center gap-4 mt-16">
            <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
            <div className="w-full max-w-[14rem]">
              <CtaPrimary label="Boost Selected" onClick={handleSubmit} />
            </div>
          </div>
        </div>
      )}
    </ModalContentWrapper>
  )
}
