import { line, scaleLinear, select } from 'd3'
import { useCallback, useEffect, useRef } from 'react'

import { error, success } from '../colors'

interface SparkLineProps {
  values: number[]
  className?: string
}

const margin = { top: 0, right: 0, bottom: 0, left: 0 }

export const SparkLine = (props: SparkLineProps) => {
  const { values = [], className = '' } = props
  // --------------------- ===
  //  REFS
  // ---------------------
  const outerRef = useRef(null)
  const svgRef = useRef(null)

  // --------------------- ===
  //  FUNCS
  // ---------------------
  const renderGraph = useCallback(() => {
    // remove old graph
    select(svgRef.current).selectAll('*').remove()

    const outerWidth = outerRef.current.clientWidth
    const outerHeight = outerRef.current.clientHeight
    // ternary here to catch if sparkline is initially rendered inside a closed table row
    const width = outerWidth ? outerWidth - margin.left - margin.right : 600
    const height = outerHeight ? outerHeight - margin.top - margin.bottom : 200

    const x = scaleLinear().domain([0, values.length]).range([0, width])
    const y = scaleLinear()
      .domain([Math.min(...values), Math.max(...values)])
      .range([height, 0])

    const svg = select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    const drawLine = line()
      .x((d, i) => x(i))
      // @ts-expect-error Not sure what's going on here
      .y((d) => y(d))

    const color = values[0] <= values[values.length - 1] ? success : error

    svg
      .append('path')
      .datum(values)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .attr('vector-effect', 'non-scaling-stroke')
      // @ts-expect-error Not sure what's going on here
      .attr('d', drawLine)
  }, [values])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    renderGraph()
  }, [renderGraph])

  return (
    <div className={`${className}`} ref={outerRef}>
      {values.length ? <svg ref={svgRef} /> : null}
    </div>
  )
}
