import type { ReactNode } from 'react'

import type { CtaProps } from '@pgl-apps/shared/types'

import { Spinner } from '../../decorative'

const hoverGlow =
  'transition-shadow duration-200 shadow-transparent hover:shadow-theme-primaryCtaBg hover:no-underline hover:shadow-[0_0_6px_1px]'
const cancelHoverGlow =
  'transition-shadow duration-200 shadow-transparent hover:shadow-theme-cancelCtaBorder hover:no-underline hover:shadow-[0_0_6px_1px]'
const typeClasses = {
  primary: `text-theme-primaryCtaText bg-theme-primaryCtaBg border-theme-primaryCtaBorder ${hoverGlow}`,
  secondary: `text-theme-secondaryCtaText bg-theme-secondaryCtaBg border-theme-secondaryCtaBorder ${hoverGlow}`,
  tertiary:
    'border-theme-containerBg text-theme-secondaryCtaText decoration-2 active:no-underline hover:underline',
  cancel: `text-theme-cancelCtaText bg-theme-secondaryCtaBg border-theme-cancelCtaBorder ${cancelHoverGlow}`,
}

interface Props extends Omit<CtaProps, 'label'> {
  children: ReactNode | string
}

export const CtaBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onClick,
    type = 'secondary',
    state = 'default',
    children,
    isFullWidth = false,
    icon = null,
    childrenClassName = '',
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const disabledClass =
    state === 'disabled' || state === 'loading'
      ? 'pointer-events-none opacity-50'
      : ''

  const widthClass = isFullWidth
    ? 'w-full'
    : 'w-full sm:w-max px-6 sm:max-w-[15rem]'
  return (
    <button
      className={`${disabledClass} ${typeClasses[type]} py-2 ${widthClass} rounded-md border text-sm relative`}
      onClick={() => state !== 'loading' && state !== 'disabled' && onClick()}
      type="button"
    >
      <span className="absolute left-0 top-0 bottom-0 w-6 p-1 flex items-center">
        {state === 'loading' && <Spinner status="pending" />}
      </span>
      {icon ? (
        <span className={`flex justify-center gap-2 ${childrenClassName}`}>
          {children}
          <img src={icon} alt="" className="w-4 h-auto" />
        </span>
      ) : (
        children
      )}
    </button>
  )
}
