import { utils } from 'ethers'

import { tokenDecimals } from '@pgl-apps/shared/constants'
import type { TokenOpts } from '@pgl-apps/shared/types'

export const addTokenDecimals = (token: string, amount: string) => {
  const decimals = tokenDecimals[token]
  const str = amount.toString()
  const splitStr = str.split('.')
  let left = splitStr[0]
  if (left === '0') left = ''
  let right = splitStr[1] || ''
  // assuming here that there will be zeroes to add and that the number is not as large as the decimals
  const zeroesToAdd = decimals - (right?.length || 0)
  const zeroes = '0'.repeat(zeroesToAdd)
  while (right[0] === '0') right = right.slice(1, right.length)
  right = right + zeroes
  const stringFormat = left + right
  return stringFormat
}

// round to three decimal places
export const removeTokenDecimals = (
  token: string,
  amount: string,
  format: 'string' | 'number' = 'string'
) => {
  if (!amount) return '0'
  const decimals = tokenDecimals[token]
  if (decimals === 0) {
    if (format === 'number') {
      return Math.round(parseInt(amount))
    }
    return amount.toLocaleString()
  }
  let str = amount.toString()
  if (str.length < decimals) {
    str = '0'.repeat(decimals - str.length) + str
  }
  const left = str.slice(0, -decimals) || '0'
  const right = str.slice(-decimals, 4 - decimals) || ''
  const stringFormat = parseFloat(left + '.' + right)
  if (format === 'number') return stringFormat
  return stringFormat.toLocaleString()
}

// TODO I think these can replace above?
export const parseUnits = (token: TokenOpts, amount: string) =>
  utils.parseUnits(amount, tokenDecimals[token]) // small number to BigNumber

export const formatUnits = (token: TokenOpts, amount: string | number) =>
  utils.formatUnits(amount, tokenDecimals[token]) // BigNumber to small number
