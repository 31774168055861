import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { stakingOpts, useStaking } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Heading, Label } from '@pgl-apps/shared/components'
import { stakingKeys } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../buttons'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { CancelBtn } from './private/CancelBtn'

interface Props {
  hookKey?: keyof typeof stakingOpts
  agreementId?: number
  onCloseModal: () => void
}
export const Claim = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { hookKey, agreementId, onCloseModal } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const staking = useStaking(walletId, signer)
  const currentStakingHook = staking[hookKey]
  const { stakingAgreements, claimRewards } = currentStakingHook
  const selectedAgreement = useMemo(
    () => stakingAgreements.find((a) => a.id == agreementId),
    [stakingAgreements, agreementId]
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = () => {
    claimRewards([agreementId], [0])
    onCloseModal()
  }

  return (
    <ModalContentWrapper>
      <div>
        <div className="flex flex-col items-center pt-16">
          <Heading type="page" tag="h2">
            Are you sure you want to claim?
          </Heading>
          <p className="mb-16">
            You are claiming about &nbsp;
            <span>{addNumPunctuation(selectedAgreement.rewards, 1)}</span>
            &nbsp;
            <span>{stakingOpts[stakingKeys.kap].label}</span>
            <span>
              (Est. $
              {addNumPunctuation(kapPrice * selectedAgreement.rewards, 1)})
            </span>
          </p>

          <div className="text-center">
            <Label>Claimed rewards will be locked for a 1 year period.</Label>
          </div>
        </div>

        <div className="flex justify-end items-center gap-4 mt-16">
          <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
          <div className="w-48">
            <CtaPrimary label="Claim Now" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ModalContentWrapper>
  )
}
