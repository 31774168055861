import React from 'react'

interface Props {
  message: string
}

export const Error = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { message } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <p className="text-xs text-theme-error">{message}</p>
}
