import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import { useEffect, useRef, useState } from 'react'

import { usePrevious } from '@pgl-apps/shared/hooks'

// https://greensock.com/forums/topic/26288-what-is-the-proper-way-to-import-inside-nexxtjs/
if (typeof window !== 'undefined') {
  gsap.registerPlugin(InertiaPlugin)
  gsap.registerPlugin(Draggable)
}

interface CallbackArg {
  isOverflow: boolean
  isMaxLeft: boolean
  isMaxRight: boolean
}

const clickRebound = 20 // px it can move beyond before snap back

interface Props {
  // Callback if consumers need this data
  overflowCallback?: (arg1: CallbackArg) => void
  sliderBump?: number
  gap?: 'gap-3' | 'gap-5'
  children: ReactNode
}

// Boiler plate carousel
export const CarouselRoot = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    overflowCallback = () => null,
    sliderBump = 0,
    gap = 'gap-3',
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isMaxLeft, setIsMaxLeft] = useState(true)
  const [isMaxRight, setIsMaxRight] = useState(false)
  const [isOverflow, setIsOverflow] = useState(true) // is the content wider than the container

  // --------------------- ===
  //  REFS
  // ---------------------
  const sliderRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const minXRef = useRef(0)
  const maxXRef = useRef(0)

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const getDraggable = () => Draggable.get(sliderRef.current)

  const updateDraggable = () => {
    getDraggable().update()
  }

  const checkLimits = () => {
    const { x } = getDraggable()
    if (x >= maxXRef.current) setIsMaxLeft(true)
    else setIsMaxLeft(false)
    if (x <= minXRef.current) setIsMaxRight(true)
    else setIsMaxRight(false)
  }

  const ensureBounds = () => {
    const { x } = getDraggable()

    if (x > maxXRef.current) {
      gsap.to(sliderRef.current, {
        duration: 0.22,
        x: maxXRef.current,
        onComplete: updateDraggable,
      })
    } else if (x < minXRef.current) {
      gsap.to(sliderRef.current, {
        duration: 0.22,
        x: minXRef.current,
        onComplete: updateDraggable,
      })
    }
  }

  const checkOverflow = () => {
    const { scrollWidth, offsetWidth } = containerRef.current
    if (scrollWidth > offsetWidth) {
      setIsOverflow(true)
    } else {
      setIsOverflow(false)
    }
  }

  // --------------------- ===
  //  BUILD DRAGGABLE
  // ---------------------
  const buildSlider = () => {
    minXRef.current =
      -containerRef.current.scrollWidth + containerRef.current.offsetWidth
    Draggable.create(sliderRef.current, {
      type: 'x',
      bounds: {
        minX: minXRef.current,
        maxX: maxXRef.current,
      },
      inertia: true,
      edgeResistance: 0.85,
      throwResistance: 10,
      zIndexBoost: false,
      onDrag: checkLimits,
    })
  }

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const prevSliderBump = usePrevious(sliderBump, 0)

  const handleBump = useCallback(() => {
    const bump = sliderBump - prevSliderBump
    if (bump) {
      setIsMaxRight(false) // kinda a hack to make grad show instantly
      const { x: currentX } = getDraggable()
      const x = Math.min(
        maxXRef.current + clickRebound,
        Math.max(minXRef.current - clickRebound, currentX + bump)
      )
      gsap.to(sliderRef.current, {
        duration: 0.32,
        // x: `+=${bump}`,
        x,
        onComplete: () => {
          updateDraggable()
          ensureBounds()
          checkLimits()
        },
      })
    }
    // just need sliderBump
  }, [sliderBump]) // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const handleResize = () => {
      buildSlider()
      ensureBounds()
      checkLimits()
      checkOverflow()
    }
    handleResize() // run on mount
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isOverflow])

  useEffect(() => {
    const draggable = getDraggable()
    if (isOverflow) {
      draggable.enable()
    } else {
      draggable.disable()
    }
  }, [isOverflow])

  useEffect(() => {
    overflowCallback({ isOverflow, isMaxLeft, isMaxRight })
    // Don't need to include overflowCallback
  }, [isOverflow, isMaxLeft, isMaxRight]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleBump()
  }, [handleBump])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="w-full overflow-hidden" ref={containerRef}>
      <div
        ref={sliderRef}
        className={`flex items-center ${gap} ${
          isOverflow ? 'w-max' : 'w-full'
        }`}
      >
        {children}
      </div>
    </div>
  )
}
