import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import { stakingOpts, useStaking, useToken } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { showModal, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'

import { ModalContentWrapper } from '../../ModalContentWrapper'
import { AcquireToken } from './AcquireToken'
import { Allowance } from './Allowance'
import { ApproveStep } from './ApproveStep'
import { StakeStep } from './StakeStep'
import { WrongChain } from './WrongChain'

interface Props {
  hookKey?: string
  onCloseModal: (arg?: any) => void
  defaultAmount?: string
}

export const Stake = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    hookKey = Object.values(stakingOpts)[0].key,
    onCloseModal,
    defaultAmount,
  } = props // just for initial state

  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const chainId = useSelector((store: IRootState) =>
    store.wallet.provider ? store.wallet.walletNetwork : null
  )

  // --------------------- ===
  //  REFS
  // ---------------------
  const initRef = useRef(false) // Track if component has finished being initialized

  // --------------------- ===
  //  STATE
  // ---------------------
  const wrongChain = chainId && chainId !== Number(config.chainId)
  const [currentKey, setCurrentKey] = useState(hookKey)
  const [step, setStep] = useState(
    // if on the wrong chain show
    wrongChain ? 0 : 1
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const staking = useStaking(walletId, signer)
  const currentStakingHook = staking[currentKey]
  const { userAllowanceBN, approveUserAllowance } = currentStakingHook

  const { walletBalanceBN } = useToken(walletId)[currentKey]
  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const onClickApprove = useCallback(async () => {
    onCloseModal()
    const isApproveSuccess = await approveUserAllowance(walletId)

    // after succeed approving, show stake modal
    if (isApproveSuccess) {
      dispatch(
        showModal(modalTypes.stake, {
          hookKey,
        })
      )
    }
  }, [approveUserAllowance, walletId])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    if (!walletBalanceBN || !userAllowanceBN || initRef.current || wrongChain) {
      // On initial load it's null
      return
    }

    initRef.current = true

    if (walletBalanceBN.eq(0)) {
      setStep(2)
    } else if (userAllowanceBN.eq(0)) {
      setStep(3)
    } else {
      setStep(4)
    }
  }, [userAllowanceBN, walletBalanceBN])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <ModalContentWrapper>
      {step === 0 && <WrongChain />}
      {step === 1 && <Allowance />}
      {step === 2 && <AcquireToken onCloseModal={onCloseModal} />}
      {step === 3 && (
        <ApproveStep
          key={currentKey}
          label={stakingOpts[currentKey].label}
          onClick={onClickApprove}
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
        />
      )}
      {step === 4 && (
        <StakeStep
          key={currentKey}
          currentStakingHook={currentStakingHook}
          label={stakingOpts[currentKey].label}
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
          onCloseModal={onCloseModal}
          defaultAmount={defaultAmount}
        />
      )}
    </ModalContentWrapper>
  )
}
