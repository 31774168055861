export interface GameConfigType {
  game_id: number
  chain: {
    id: number
    rpc_url: string
    name: string
    address_explorer_url: string
    transaction_explorer_url: string
  }
  tokens: {
    erc_20?: {
      [key: string]: {
        address: string
        decimals: number
      }
    }
    nft?: {
      [key: string]: {
        address: string
        symbol: string
      }
    }
    erc_1155?: {
      [key: string]: {
        address: string
        tokens: {
          [key: string]: {
            symbol: string
            ids: number[]
          }
        }
      }
    }
  }
}
