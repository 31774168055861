import { axisLeft, select } from 'd3'
import { useEffect, useRef } from 'react'

import styles from '../Graph.module.scss'
import type { AxisLeftProps } from '../types'

export const AxisLeft = (props: AxisLeftProps) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { scale, transform } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const ref = useRef<SVGGElement>(null)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisLeft(scale).ticks(5, 's'))
    }
  }, [scale])

  return (
    <g
      ref={ref}
      transform={transform}
      className={`font-thin ${styles.axisColors}`}
    />
  )
}
