import { LinkExternal } from './private/LinkExternal'
import { LinkInternal } from './private/LinkInternal'
import type { PrimaryLnk } from './types'

export const Lnk = (props: PrimaryLnk) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { to, isExternal, ...rest } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isLinkExternal = isExternal ?? to.startsWith('http')
  return isLinkExternal ? (
    <LinkExternal href={to} {...rest} />
  ) : (
    <LinkInternal to={to} {...rest} />
  )
}
