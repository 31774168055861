import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import { day } from '@pgl-apps/kap-stake/helpers'
import { stakingOpts, useVesting } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { showModal, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import type { VestingAgreement } from '@pgl-apps/kap-stake/types'
import kapCoin from '@pgl-apps/shared-images/logos/kap-games-icon.svg'
import {
  ActionCell,
  ActionItem,
  Cell,
  Row,
  Status,
  TableWrapper,
} from '@pgl-apps/shared/components'
import { defaultColumns } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import styles from '../index.module.scss'

const columnData: typeof defaultColumns.general[] = [
  {
    ...defaultColumns.general,
    label: 'Pool',
  },
  {
    ...defaultColumns.general,
    label: 'Total Amount',
  },
  {
    ...defaultColumns.general,
    label: 'Collected',
  },
  {
    ...defaultColumns.general,
    label: 'Unlocked',
  },
  {
    ...defaultColumns.general,
    label: 'Date vested',
  },
  {
    ...defaultColumns.general,
    label: 'Unlocks in',
  },
  {
    ...defaultColumns.general,
    label: 'Status',
  },
]

const convertAgreement = (agreement: VestingAgreement) => {
  const { id, vestStart, vestPeriod, totalAmount, amountCollected } = agreement

  const timestamp = Date.now()
  const date = new Date(vestStart * 1000)
  const unlocks = Math.ceil(
    ((vestStart + vestPeriod) * 1000 - timestamp) / (day * 1000)
  ) // day unit
  const amountUnlocked =
    timestamp <= vestStart * 1000
      ? 0
      : timestamp >= (vestStart + vestPeriod) * 1000
      ? parseFloat(totalAmount)
      : (parseFloat(totalAmount) * (timestamp - vestStart * 1000)) /
        (vestPeriod * 1000)

  return {
    id,
    date: date.toLocaleDateString(),
    unlocks: Math.max(unlocks, 0),
    totalAmount,
    amountCollected,
    amountUnlocked,
  }
}

export const VestingAgreements = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const vesting = useVesting(walletId, signer)

  const vestingRows = useMemo(
    () => vesting.vestingAgreements.map((a) => convertAgreement(a)),
    [vesting.vestingAgreements]
  )

  // --------------------- ===
  //  METHOD
  // ---------------------
  const onClickCollect = useCallback(
    (agreementId) => {
      dispatch(
        showModal(modalTypes.collectVesting, {
          agreementId,
        })
      )
    },
    [dispatch, showModal]
  )

  return (
    <TableWrapper
      columnData={columnData}
      title="Vesting Agreements"
      isLoading={vesting.loading}
    >
      {vestingRows.map((row, i) => (
        <Row id={i} key={i}>
          <Cell>
            <div className={styles.poolWrapper}>
              <div className={styles.icon}>
                <img src={kapCoin} alt={stakingOpts.kap.label} />
              </div>
              <span>{stakingOpts.kap.label}</span>
            </div>
          </Cell>
          <Cell>{addNumPunctuation(row.totalAmount, 2)}</Cell>
          <Cell>{addNumPunctuation(row.amountCollected, 2)}</Cell>
          <Cell>{addNumPunctuation(row.amountUnlocked, 2)}</Cell>
          <Cell>{row.date}</Cell>
          <Cell>{row.unlocks} Days</Cell>
          <Cell>
            <Status
              type={row.amountUnlocked > 0 ? 'success' : 'inactive'}
              label={
                row.amountUnlocked > 0 &&
                row.amountUnlocked > parseFloat(row.amountCollected)
                  ? 'Available'
                  : row.amountUnlocked > 0
                  ? 'Collected'
                  : 'Locked'
              }
            />
          </Cell>
          {row.amountUnlocked > 0 &&
          row.amountUnlocked > parseFloat(row.amountCollected) ? (
            <ActionCell>
              <ActionItem
                label="Collect"
                onClick={() => onClickCollect(row.id)}
              />
            </ActionCell>
          ) : (
            <></>
          )}
        </Row>
      ))}
    </TableWrapper>
  )
}
