import { Heading } from '@pgl-apps/shared/components'

interface Props {
  label?: string
  value: string | number
  unit?: string
  isHighlighted?: boolean
}

export const AprBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label = 'Est Apr', value, unit = '', isHighlighted = false } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const highlightedClass = isHighlighted
    ? 'border border-theme-primaryCtaBorder'
    : ''
  return (
    <div
      className={`${highlightedClass} flex flex-col bg-theme-bg rounded px-2 py-1 text-theme-base text-center`}
    >
      <p
        className={`text-xs ${
          isHighlighted ? 'text-theme-highlighted' : 'text-theme-muted'
        }`}
      >
        {label}
      </p>
      <div className="flex items-center justify-center">
        <Heading tag="span" type="article" className="mb-0">
          {value}
        </Heading>
        <p className="text-sm text-theme-muted">{unit}</p>
      </div>
    </div>
  )
}
