import { commonErrorMsgs } from '@pgl-apps/shared/constants'

export const getKapErrorMsg = (errResponse: any, customMsg?: string) => {
  // Priority 1
  if (customMsg) return customMsg

  // Priority 2
  if (typeof errResponse?.data?.detail?.error_msg === 'string') {
    return errResponse.data.detail.error_msg
  }

  // Priority 3
  if (errResponse?.status && commonErrorMsgs[errResponse.status]) {
    return commonErrorMsgs[errResponse.status]
  }

  // Priority 4
  return 'An unknown error has occurred. Please try again later.' // global fallback
}
