import gsap from 'gsap'
import { useMemo, useRef } from 'react'

import { ReactComponent as Close } from '@pgl-apps/shared-images/svgr/icons/close.svg'
import { ReactComponent as Error } from '@pgl-apps/shared-images/svgr/icons/error.svg'
import { ReactComponent as Info } from '@pgl-apps/shared-images/svgr/icons/info.svg'
import { ReactComponent as Success } from '@pgl-apps/shared-images/svgr/icons/success.svg'
import { ReactComponent as Warning } from '@pgl-apps/shared-images/svgr/icons/warning.svg'
import { notificationTypes } from '@pgl-apps/shared/constants'
import { useMountEffect } from '@pgl-apps/shared/hooks'
import type { ClearNotificationArg } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

const getIcon = (type: ClearNotificationArg['type']) => {
  switch (type) {
    case notificationTypes.success:
      return Success
    case notificationTypes.error:
      return Error
    case notificationTypes.warning:
      return Warning
    case notificationTypes.info:
    default:
      return Info
  }
}

interface Props {
  notification: ClearNotificationArg
  onClose: () => void
  duration: number
  timeoutDelay: number
}

export const NotificationCard = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { notification, onClose, duration, timeoutDelay } = props

  const { id, message, type } = notification

  // --------------------- ===
  //  REF
  // ---------------------
  const cardRef = useRef(null)
  const barRef = useRef(null)
  const tl = useRef(gsap.timeline())

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const buildTl = () => {
    tl.current.to(barRef.current, {
      duration: timeoutDelay,
      width: 0,
      ease: 'none',
      onComplete: onClose,
    })
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    gsap.set(cardRef.current, { display: 'flex' }) // Keeps card from flashing
    gsap.from(cardRef.current, {
      height: 0,
      marginBottom: 0,
      padding: 0,
      duration,
      ease: 'none',
    })
    gsap.from(cardRef.current, {
      duration: duration * 2,
      delay: duration / 2,
      xPercent: 150,
      ease: 'elastic.out(1, 1)',
    })
    buildTl()
  })

  // --------------------- ===
  //  RENDER
  // ---------------------
  const Icon = useMemo(() => getIcon(type), [type])

  return (
    <div
      className={`${styles.card} ${styles[`card__${type}`]}`}
      key={id}
      id={id}
      ref={cardRef}
      onMouseEnter={() => {
        tl.current.pause()
      }}
      onMouseLeave={() => {
        tl.current.play()
      }}
    >
      <Icon className={styles.icon} />
      <div className={styles.message} role="alert">
        {message}
      </div>
      <button className={styles.close} type="button" onClick={onClose}>
        <Close className="fill-gray-600" />
      </button>
      <div className={styles.bar} ref={barRef} />
    </div>
  )
}
