import type { TokenOpts } from '@pgl-apps/shared/types'

export const tokenDecimals: Record<TokenOpts, number> = {
  ETH: 18,
  SLP: 0,
  AXS: 18,
  VIS: 18,
  RBW: 18,
  UNIM: 18,
  MATIC: 18,
  RON: 18,
  RON_WETH: 18,
  FNC: 18,
  sFNC: 18,
}
