import LogRocket from 'logrocket'

export const initLogRocket = () => {
  // TODO should we add this to environments folder?
  const logRocketId = process.env.LOG_ROCKET_ID
  if (logRocketId) {
    LogRocket.init(logRocketId, {
      network: {
        requestSanitizer: (request) => {
          // remove request bodies to custody service
          if (
            request.body &&
            (request.body.includes('password') ||
              request.body.includes('wallet_file'))
          ) {
            // remove body from request
            request.body = null
          }

          // otherwise log the request normally
          return request
        },
      },
    })
  }
}
export const initLogRocketUser = (user) => {
  const logRocketId = process.env.LOG_ROCKET_ID
  if (logRocketId) {
    LogRocket.identify(user.id, {
      name: user.username,
      email: user.guild.title,
      guild_id: user.guild_id,
    })
  }
}
