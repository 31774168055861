// import CoinGecko from 'coingecko-api'
import axios from 'axios'

import { config } from '@pgl-apps/shared/api'

export async function getCoingeckoPrice(coinIds: Array<string>) {
  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${coinIds.join(
    ','
  )}&vs_currencies=usd`
  const { data } = await axios.get(url)
  return data

  // // [TODO] Remove in production
  // return {
  //   "kapital-dao": {
  //     usd: 0.015,
  //   },
  //   "ethereum": {
  //     usd: 1300,
  //   },
  // }
}

export async function getUniswapDayPrice(tokenAddress: string) {
  if (config.coingeckoID[tokenAddress] === 'ethereum') {
    return await getUniswapEthPrice()
  }
  const url = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  const query = `
  query tokenDayDatas{
    tokenDayDatas(where: {token: "${tokenAddress.toLowerCase()}"} orderBy: date orderDirection: desc first: 1){
      id
      token {
        id
      }
      priceUSD
      date
  }
}
  `
  const dataQuery = { query }
  const { data } = await axios.post(url, dataQuery)
  return parseFloat(data.data.tokenDayDatas[0].priceUSD)
}

export async function getUniswapEthPrice() {
  const url = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  const query = `
      query bundle{
        bundle(id: "1" ) {
          ethPrice
      }
    }
  `
  const dataQuery = { query }
  const { data } = await axios.post(url, dataQuery)
  return parseFloat(data.data.bundle.ethPrice)
}
