import type { ReactNode } from 'react'

import styles from './index.module.scss'

interface Props {
  children: ReactNode | string
  onClick(): void
}

export const CancelBtn = ({ children, onClick }: Props) => {
  return (
    <button
      className={`${styles.cancelBtnWrapper} border hover:underline`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
