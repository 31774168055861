import type { Signer } from 'ethers'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { kapEth } from '@pgl-apps/kap-stake/redux'
import kapCoin from '@pgl-apps/shared-images/logos/kap-coin.png'
import kapEthCoin from '@pgl-apps/shared-images/logos/kap-eth-coin.png'
import { stakingKeys } from '@pgl-apps/shared/constants'

import useStakingTemplate from './templates/useStakingTemplate'

export const stakingOpts = {
  [stakingKeys.kap]: {
    key: stakingKeys.kap,
    label: 'KAP',
    icon: kapCoin,
  },
  [stakingKeys.kapEth]: {
    key: stakingKeys.kapEth,
    label: 'KAP/ETH LP',
    icon: kapEthCoin,
  },
}

export const useStaking = (walletId: string, signer: Signer) => {
  // --------------------- ===
  //  STAKING CONTRACTS
  // ---------------------
  // initialize providers for all staking contracts
  // const kapSlice = useSelector((store: IRootState) => store.kap)
  const kapEthSlice = useSelector((store: IRootState) => store.kapEth)

  // const kapStake = useStakingTemplate(kapSlice, kap.actions, walletId, signer)
  const kapEthStake = useStakingTemplate(
    kapEthSlice,
    kapEth.actions,
    kapEth.constants,
    walletId,
    signer
  )

  return {
    // [stakingOpts.kap.key]: kapStake,
    [stakingOpts.kapEth.key]: kapEthStake,
  }
}
