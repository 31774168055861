import type { Dispatch, SetStateAction } from 'react'

import type { SortOpts } from './table'

export interface SearchConfig {
  onChange: (val: string) => void
  value: string
  suggestionArr?: string[]
  onSuffixChange?: (val: string) => void
  suffixValue?: string
  suffixOpts?: string[]
  placeholder?: string
}

export interface SearchSuffixOpt {
  apiKey: string
  label: string
}

export interface SortConfig {
  onChange: (label: string, type: SortOpts) => void
  label: string
  type: SortOpts
}

export interface FilterOpt {
  isSelected?: boolean
  label: string
  apiKey: string | number
}

export interface RoleOpt {
  isSelected?: boolean
  label: string
  key: string
}

export interface Filter {
  label: string
  options: FilterOpt[]
  isSingleChoice?: boolean
}

export interface Role {
  label: string
  options: RoleOpt[]
}

export interface Roles {
  [key: string]: Role
}

export interface Filters {
  [key: string]: Filter
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SecondaryFilters {}

export interface FilterConfig {
  onClick: (key: string, opt: FilterOpt, isSecondary?: boolean) => void
  filters: Filters
}

interface InputBase {
  label?: string // shouldn't be optional after inputs are updated to using this
  isSuccess?: boolean
  isHighlighted?: boolean
  isError?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  onChange?: Dispatch<SetStateAction<InputBase>>
  state?:
    | 'normal'
    | 'highlighted'
    | 'success'
    | 'disabled'
    | 'loading'
    | 'loadingPassive'
    | 'error'
  validation?: (val: string | number | boolean) => string
}

export type PlayerLoginInfoPerms = 'everything' | 'qr_code' | 'none'

export interface InputConfig extends InputBase {
  value: string
}

export interface InputConfigCheckbox extends InputBase {
  value: boolean
}

export type BaseInputTypes = 'text' | 'number' | 'password'
