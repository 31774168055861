import { createAction, createReducer } from '@reduxjs/toolkit'
import { Contract, ethers, utils } from 'ethers'

import { config } from '@pgl-apps/shared/api'
import { abiRewardsLocker } from '@pgl-apps/shared/api'
import {
  getActionStatusObj,
  isActionType,
  setActionStatus,
} from '@pgl-apps/shared/helpers'

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'rewards'

// TODO does it matter if we create a new provider on each instance?
const infuraProvider = new ethers.providers.InfuraProvider(config.network)

// --------------------- ===
//  CONSTANTS
// ---------------------
const FETCH_REWARDS_LOCK_AGREEMENTS = `${sliceName}/FETCH_REWARDS_LOCK_AGREEMENTS`
const CLEAR_REWARDS_LOCK_AGREEMENTS = `${sliceName}/CLEAR_REWARDS_LOCK_AGREEMENTS`

const constants = {
  FETCH_REWARDS_LOCK_AGREEMENTS,
  CLEAR_REWARDS_LOCK_AGREEMENTS,
}

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState = {
  provider: new Contract(
    config.addresses.rewardsLocker,
    abiRewardsLocker.abi,
    infuraProvider
  ),
  agreements: [],
}

// --------------------- ===
//  ACTIONS
// ---------------------
const clearRewardsLockAgreements = createAction(CLEAR_REWARDS_LOCK_AGREEMENTS)

const fetchRewardsLockAgreements =
  (_address: string) => (dispatch, getState) => {
    const { provider } = getState()[sliceName]

    return dispatch({
      type: FETCH_REWARDS_LOCK_AGREEMENTS,
      payload: provider
        .getLockAgreements(_address)
        .then((_agreements: Array<any>) => {
          const agreements = []
          for (let id = 0; id < _agreements.length; id++) {
            if (_agreements[id].collected) continue

            agreements.push({
              id,
              availableTimestamp: _agreements[id].availableTimestamp.toNumber(),
              amount: utils.formatEther(_agreements[id].amount),
              collected: _agreements[id].collected,
            })
          }

          return agreements
        }),
    })
  }

const actions = {
  fetchRewardsLockAgreements,
  clearRewardsLockAgreements,
}

// --------------------- ===
//  REDUCER
// ---------------------
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearRewardsLockAgreements, (state) => {
      state.agreements = []
    })
    .addMatcher(
      (action) => isActionType(action, FETCH_REWARDS_LOCK_AGREEMENTS),
      (state, action) => {
        setActionStatus(state, action)
        if (getActionStatusObj(action).isFulfilled) {
          state.agreements = action.payload
        }
      }
    )
})

export const rewards = {
  reducer,
  actions,
  constants,
}

export default reducer
