import type { Signer } from 'ethers'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { rewards, tokens, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { actionStatuses, isActionStatus } from '@pgl-apps/shared/helpers'
import { NotificationTypes } from '@pgl-apps/shared/types'

import { useNotifications } from './useNotifications'
import { useTransactions } from './useTransactions'

export const useRewardsLocker = (walletId: string, signer: Signer) => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const {
    initPendingTxn,
    updatePendingTxn,
    completePendingTxn,
    errorPendingTxn,
  } = useTransactions()

  const slice = useSelector((store: IRootState) => store.rewards)
  const { provider, agreements } = slice
  const loading = isActionStatus(
    slice,
    rewards.constants.FETCH_REWARDS_LOCK_AGREEMENTS,
    actionStatuses.PENDING
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { addNotification } = useNotifications()

  // --------------------- ===
  //  METHODS
  // ---------------------
  const collectRewards = useCallback(
    async (agreementId: number) => {
      const newTxnID = initPendingTxn('Waiting for Confirmation')
      try {
        const userContract = provider.connect(signer)
        const txn = await userContract.collectRewards(agreementId)
        addNotification({
          message: `Transaction submitted: ${txn.hash}`,
          type: NotificationTypes.info,
        })
        updatePendingTxn(
          newTxnID,
          txn.hash,
          'Collect is pending. This could take a few minutes.'
        )
        await txn.wait()
        completePendingTxn(newTxnID, txn.hash, 'Transaction Success')
      } catch (err: any) {
        errorPendingTxn(newTxnID, 'Collect failed.')
      }

      // reload rewards agreements
      dispatch(rewards.actions.fetchRewardsLockAgreements(walletId))
      // reload token balances
      dispatch(
        tokens.actions.fetchBalances(config.addresses.tokens.kap, [walletId])
      )
    },
    [provider, walletId]
  )

  return {
    loading,
    lockAgreements: agreements,
    collectRewards,
  }
}

export default useRewardsLocker
