export const hcDefaultOptions: Highcharts.Options = {
  // options - see https://api.highcharts.com/highcharts
  chart: {
    styledMode: true,
    type: 'area',
    zooming: {
      type: 'x',
    },
    panning: {
      enabled: true,
      type: 'x',
    },
    panKey: 'shift',
    scrollablePlotArea: {
      minWidth: 300,
    },
  },
  accessibility: {
    enabled: true,
  },
  colorAxis: {
    accessibility: {
      enabled: true,
    },
  },
  title: {
    text: undefined,
  },
  yAxis: {
    title: {
      text: 'Amount',
    },
    showLastLabel: true,
    gridLineDashStyle: 'Dot',
  },
  xAxis: {
    accessibility: {
      rangeDescription: 'Date',
    },
    // minPadding: 0,
    // maxPadding: 0,
    startOnTick: false,
    endOnTick: false,
    labels: {
      padding: 20, // not working
    },
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: true,
      },
      // minPointLength: 10,
    },
  },
  legend: {
    itemMarginBottom: 8,
    // useHTML: true,
  },
  series: [],
  tooltip: {
    // xDateFormat: '%Y-%m-%d',
    xDateFormat: '%b %d, %Y',
    valueDecimals: 2, // round to two places
    // shared: true, // show all from x axis
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1000,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
  credits: {
    enabled: false,
  },
}

// xAxis {}
export const hcXAxisDateTime: Highcharts.Options['xAxis'] = {
  type: 'datetime',
  // gridLineWidth: 1,
  // min: Date.UTC(2022, 8, 1),
  // max: Date.UTC(2022, 8, 30),
  // dateTimeLabelFormats: {
  //   second: '%H:%M:%S',
  //   minute: '%H:%M',
  //   hour: '%H:%M',
  //   day: '%e. %b',
  //   week: '%e. %b',
  //   month: "%b '%y",
  //   year: '%Y',
  // },
  tickInterval: undefined,
}
