import * as Highcharts from 'highcharts'
// import highchartsAccessibility from 'highcharts/modules/accessibility'
import { useEffect, useRef } from 'react'

import { useMountEffect } from '@pgl-apps/shared/hooks'
import type { HCPropsAdvanced, HCPropsBasic } from '@pgl-apps/shared/types'

import { SpinnerOverlay } from '../../decorative'
import { optionsWithTypeAndSeries } from './private/utils'

// TODO Improve accessibility
// highchartsAccessibility(Highcharts)

export const HighChart = (props: HCPropsBasic | HCPropsAdvanced) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    series = null,
    type = null,
    options = null,
    isLoading = false,
  } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const containerRef = useRef(null)
  const chartRef = useRef<Highcharts.Chart>()

  // --------------------- ===
  //  BUILD GRAPH
  // ---------------------
  useMountEffect(() => {
    if (series && type) {
      chartRef.current = Highcharts.chart(
        containerRef.current,
        optionsWithTypeAndSeries(type, series)
      )
      return
    }

    if (options) {
      chartRef.current = Highcharts.chart(containerRef.current, options)
      return
    }
    console.warn(
      'Chart should get either series && type or options. Received neither.'
    )
  })

  // --------------------- ===
  //  DATA UPDATERS
  // ---------------------
  useEffect(() => {
    // TODO https://api.highcharts.com/class-reference/Highcharts.Series.html#setData
    // chartRef.current.series[0].setData(series)
    // chartRef.current.series[0].update({data: series})
    chartRef.current = Highcharts.chart(
      containerRef.current,
      optionsWithTypeAndSeries(type, series)
    )
  }, [series])

  useEffect(() => {
    // TODO
    // if (options) {
    //   chartRef.current.update(options)
    // }
    if (options) {
      chartRef.current = Highcharts.chart(containerRef.current, options)
      return
    }
  }, [options])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="relative">
      <div ref={containerRef} className={isLoading ? 'opacity-50' : ''} />
      {isLoading && <SpinnerOverlay />}
    </div>
  )
}
