// This component doesn't exist in designs and might be refactored in the future
import { ReactComponent as Trash } from '@pgl-apps/shared-images/svgr/icons/trash.svg'

interface Props {
  onClick: () => void
  type: 'remove'
}

const config = {
  remove: {
    Icon: <Trash />,
    label: 'Remove',
    bgColor: '#DE9898', // TODO we need to get tailwind these colors
    fgColor: '#1A1A1A', // TODO we need to get tailwind these colors
  },
}

export const IconBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onClick, type } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      onClick={onClick}
      type="button"
      style={{ color: config[type].fgColor, background: config[type].bgColor }}
    >
      <span>{config[type].Icon}</span>
      <span>{config[type].label}</span>
    </button>
  )
}
