interface Props {
  onClick: () => void
  isLoading: boolean
  imgSrc: string
  label: string
}

export const WalletBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onClick, isLoading, imgSrc, label } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      onClick={onClick}
      type="button"
      className={`w-full border border-gray-300 hover:border-theme-primaryCtaBorder focus-visible:border-theme-primaryCtaBorder border-solid shadow-none hover:shadow-lg py-2 px-4 text-center transition-all duration-200 ${
        isLoading ? 'opacity-75 hover:!bg-gray-200' : ''
      }`}
      disabled={isLoading}
    >
      <span className="w-14 h-14 flex items-center mx-auto">
        <img src={imgSrc} alt="" />
      </span>
      <span className="w-full">{label}</span>
    </button>
  )
}
