export const promiseTypeDelimiter = '__'

export const actionStatuses = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
}

// Does triggered action match this action type
export const isActionType = (action, type: string) =>
  action.type.startsWith(`${type}${promiseTypeDelimiter}`) ||
  action.type === type

// ASYNC HELPERS
// Takes action type (wallet/ADD_WALLET__PENDING) and returns status key (ADD_WALLET/status)
export const getStatusKey = (type: string) => {
  // Convention expects ${sliceName}/${type}(optional /${status})
  let str = type.substring(type.indexOf('/') + 1)
  ;[str] = str.split(promiseTypeDelimiter)
  return `${str}/status`
}
// Initial actionStatus (ADD_WALLET/status: 'NONE')
export const getDefaultActionStatus = (type: string) => ({
  [getStatusKey(type)]: actionStatuses.NONE,
})
// TODO this is maybe depreciated
export const getActionStatusKey = (
  type: string,
  status: typeof actionStatuses[keyof typeof actionStatuses]
) => `${type}/${status}`

// Is value in store equal to some status
export const isActionStatus = (
  slice: Record<string, any>,
  type: string,
  status: typeof actionStatuses[keyof typeof actionStatuses]
) => {
  return slice[getStatusKey(type)] === status
}

// Takes an action and returns a status object for easy status check
export const getActionStatusObj = (action) => ({
  isPending: action.type.endsWith(actionStatuses.PENDING),
  isFulfilled: action.type.endsWith(actionStatuses.FULFILLED),
  isRejected: action.type.endsWith(actionStatuses.REJECTED),
})
// Returns a string which can be compared to the triggered action ('wallet/ADD_WALLET__PENDING')
export const getTypeWithStatus = (type, status) =>
  `${type}${promiseTypeDelimiter}${status}`
// Sets status to state (state.wallet['ADD_WALLET/status'] = 'PENDING')
export const setActionStatus = (state, action) => {
  const status = action.type.split(promiseTypeDelimiter)[1]
  state[getStatusKey(action.type)] = status // eslint-disable-line no-param-reassign
}
