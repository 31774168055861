import background from '@pgl-apps/shared-images/backgrounds/adCardBg.png'

import { ContentBox } from '../../../region/ContentBox/ContentBox'

const bgStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'no-repeat',
}

export const KapStakeAd = () => {
  return (
    <ContentBox className="flex flex-col w-[275px] h-[400px] relative overflow-hidden">
      <div className="w-full h-full rounded-lg p-[.06rem] bg-gradient-to-tr from-transparent via-yellow-700 to-yellow-500">
        <div style={bgStyle} className="bg-cover flex-row h-full rounded-lg">
          <div className="pt-4 h-3/4">
            <div className="pl-4">
              <div className="text-xl tracking-wide">KAP STAKING</div>
              <div className="text-3xl text-theme-highlighted tracking-wide">
                NOW LIVE
              </div>
            </div>
            <div className="flex flex-col items-center w-36 pt-16">
              <p className="text-center font-semibold text-[.9rem] pb-2">
                1,000%<span className="text-sm align-text-bottom">+</span> APR
                <span className="text-xs font-extrabold align-text-top">*</span>
              </p>
              <a
                className="font-base px-6 py-1 text-center rounded-xl bg-gradient-to-br from-yellow-500 via-yellow-600 to-yellow-700"
                href="https://staking.kapital.gg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-lg tracking-wide">STAKE</span>
              </a>
              <p className="text-[.4rem] text-center px-2 pt-2">
                <span className="align-text-top">*</span>APR is denominated in
                KAP and will vary based on user volumes in the pool.
              </p>
            </div>
          </div>
          <div className="absolute bottom-0 w-full text-center text-theme-highlighted pb-2 bg-gradient-to-b from-transparent to-black">
            <div className="flex justify-center text-[.6rem] align-middle -mb-2 pt-10">
              <span className="">━━━━━━ +</span>
              <span className="px-3 text-sm">SPONSORED</span>
              <span className="">+ ━━━━━━</span>
            </div>
            <a
              href="https://docs.kapital.gg/disclaimer"
              target="_blank"
              rel="noopener noreferrer"
              className="text-theme-highlighted text-[.5rem]"
            >
              www.kapital.gg/disclaimers
            </a>
          </div>
        </div>
      </div>
    </ContentBox>
  )
}
