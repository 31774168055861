import { gsap } from 'gsap'
import { useRef } from 'react'

import { useMountEffect } from '@pgl-apps/shared/hooks'

import { Cell } from '../../public/Cell/Cell'
import { Row } from '../../public/Row/Row'

interface Props {
  colWidths: number[]
  hasAction: boolean
}

export const RowLoading = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { colWidths, hasAction } = props

  // --------------------- ===
  //  REF
  // ---------------------
  const ref = useRef(null)

  // --------------------- ===
  //  ANIMATION
  // ---------------------
  useMountEffect(() => {
    gsap.from(ref.current, { height: 0, duration: 0.18 })
  })
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="overflow-hidden" ref={ref}>
      <Row id="loading" colWidths={colWidths} hasAction={hasAction}>
        {colWidths.map((w, i) => (
          <Cell key={i}>
            <div className="bg-theme-muted bg-opacity-30 animate-pulse rounded-sm w-full h-4" />
          </Cell>
        ))}
      </Row>
    </div>
  )
}
