import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useVesting } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Heading } from '@pgl-apps/shared/components'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../buttons'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { CancelBtn } from '../Claim/private/CancelBtn'

interface Props {
  agreementId?: number
  onCloseModal: () => void
}
export const CollectVesting = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { agreementId, onCloseModal } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { vestingAgreements, collect } = useVesting(walletId, signer)

  const vestingAmount = useMemo(() => {
    const agreement = vestingAgreements.find((a) => a.id == agreementId)
    const now = Date.now() / 1000
    const amountUnlocked =
      now > agreement.vestStart + agreement.vestPeriod
        ? agreement.totalAmount
        : (agreement.totalAmount * (now - agreement.vestStart)) /
          agreement.vestPeriod

    return amountUnlocked - agreement.amountCollected
  }, [vestingAgreements, agreementId])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = () => {
    collect(agreementId)
    onCloseModal()
  }

  return (
    <ModalContentWrapper>
      <div>
        <div className="flex flex-col items-center pt-16">
          <Heading type="page" tag="h2">
            Are you sure you want to collect vesting?
          </Heading>
          <p className="mb-16">
            You are collecting about &nbsp;
            <span>{addNumPunctuation(vestingAmount, 1)}</span>
            &nbsp;
            <span>KAP</span>
            <span>
              (Est. ${addNumPunctuation(kapPrice * vestingAmount, 1)})
            </span>
          </p>
        </div>

        <div className="flex justify-end items-center gap-4 mt-16">
          <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
          <div className="w-48">
            <CtaPrimary label="Collect Now" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ModalContentWrapper>
  )
}
