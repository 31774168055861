import { createAction, createReducer } from '@reduxjs/toolkit'

import { isActionType } from '@pgl-apps/shared/helpers'

export interface ModalInitialState {
  type: string | null
  props: Record<string, any>
  isVisible: boolean
}

export const buildModalSlice = () => {
  // --------------------- ===
  //  SETUP
  // ---------------------
  const sliceName = 'modal'
  const initialState: ModalInitialState = {
    type: null,
    props: {},
    isVisible: false,
  }

  // --------------------- ===
  //  CONSTANTS
  // ---------------------
  const SHOW_MODAL = `${sliceName}/SHOW_MODAL`
  const HIDE_MODAL = `${sliceName}/HIDE_MODAL`
  const CLEAR_MODAL_TYPE = `${sliceName}/CLEAR_MODAL_TYPE`
  const SET_MODAL_PROPS = `${sliceName}/SET_MODAL_PROPS`

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const localShowModal = (type: string, props = {}) => ({
    type: SHOW_MODAL,
    payload: {
      type,
      props,
    },
  })

  // --------------------- ===
  //  ACTIONS
  // ---------------------
  // SYNC
  const hideModal = createAction(HIDE_MODAL)
  const clearModalType = createAction(CLEAR_MODAL_TYPE)
  const setModalProps = createAction<Record<string, any>>(SET_MODAL_PROPS)

  // ASYNC
  const showModal =
    (type: string, props = {}) =>
    (dispatch, getState) => {
      const { isVisible } = getState().modal

      // If there's already a modal visible, let's hide it first
      if (isVisible) {
        dispatch(hideModal())
        setTimeout(() => {
          dispatch(localShowModal(type, props))
        }, 300)
      } else {
        dispatch(localShowModal(type, props))
      }
    }

  // --------------------- ===
  //  REDUCER
  // ---------------------
  const reducer = createReducer(initialState, (builder) => {
    builder
      .addCase(hideModal, (state) => {
        state.isVisible = false
      })
      .addCase(clearModalType, (state) => {
        state.type = null
      })
      .addCase(setModalProps, (state, action) => {
        const { payload } = action
        // state.props = payload.props
        state.props = payload
      })
      .addMatcher(
        (action) => isActionType(action, SHOW_MODAL),
        (state, action) => {
          const { payload } = action
          state.type = payload.type
          state.props = payload.props
          state.isVisible = true
        }
      )
  })

  return {
    reducer,
    actions: {
      hideModal,
      clearModalType,
      setModalProps,
      showModal,
    },
  }
}
