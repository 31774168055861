import { abiStaking, config } from '@pgl-apps/shared/api'
import { stakingKeys } from '@pgl-apps/shared/constants'

import { createContract } from './_stakingContract'

const { reducer, actions, constants } = createContract(
  stakingKeys.kapEth,
  config.addresses.staking.kapEth,
  abiStaking.abi,
  config.addresses.tokens.kapEthLP,
  true
)

export const kapEth = {
  reducer,
  actions,
  constants,
}
