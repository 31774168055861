import type { Slide } from '@pgl-apps/shared/types'

import { Loading } from '../../../messaging/Loading/Loading'
import { SecondaryBox } from '../../../region/ContentBox/SecondaryBox/SecondaryBox'
import type { HeadingColors } from '../../../typography/Heading/Heading'
import { Heading } from '../../../typography/Heading/Heading'
import { Carousel } from '../Carousel'

interface Props {
  slides: Slide[]
  headingColor?: HeadingColors
  isLoading?: boolean
}

export const BoxCarousel = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { slides, isLoading = false, headingColor = 'muted' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return isLoading ? (
    <div className="h-[7rem] w-full flex justify-center items-center">
      <Loading type="ThreeDots" height={40} width={40} />
    </div>
  ) : (
    <Carousel>
      {slides.map((d, i) => (
        <div className="flex-grow flex-shrink-0 basis-80" key={i}>
          <SecondaryBox label={d.label} Icon={d.Icon} color={headingColor}>
            <p className="font-bold mt-2">
              <span>{d.value}</span>
              {d.currency && (
                <span className="text-sm text-theme-muted font-normal">
                  &nbsp;{d.currency}
                </span>
              )}
            </p>
          </SecondaryBox>
        </div>
      ))}
    </Carousel>
  )
}
