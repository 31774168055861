import amd from '@pgl-apps/shared-images/logos/a-z-team-logos/A@0.5x.png'
import agm from '@pgl-apps/shared-images/logos/a-z-team-logos/A@0.25x.png'
import alg from '@pgl-apps/shared-images/logos/a-z-team-logos/A@1x.png'
import atiny from '@pgl-apps/shared-images/logos/a-z-team-logos/A@tiny.png'
import bmd from '@pgl-apps/shared-images/logos/a-z-team-logos/B@0.5x.png'
import bgm from '@pgl-apps/shared-images/logos/a-z-team-logos/B@0.25x.png'
import blg from '@pgl-apps/shared-images/logos/a-z-team-logos/B@1x.png'
import btiny from '@pgl-apps/shared-images/logos/a-z-team-logos/B@tiny.png'
import cmd from '@pgl-apps/shared-images/logos/a-z-team-logos/C@0.5x.png'
import cgm from '@pgl-apps/shared-images/logos/a-z-team-logos/C@0.25x.png'
import clg from '@pgl-apps/shared-images/logos/a-z-team-logos/C@1x.png'
import ctiny from '@pgl-apps/shared-images/logos/a-z-team-logos/C@tiny.png'
import dmd from '@pgl-apps/shared-images/logos/a-z-team-logos/D@0.5x.png'
import dgm from '@pgl-apps/shared-images/logos/a-z-team-logos/D@0.25x.png'
import dlg from '@pgl-apps/shared-images/logos/a-z-team-logos/D@1x.png'
import dtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/D@tiny.png'
import emd from '@pgl-apps/shared-images/logos/a-z-team-logos/E@0.5x.png'
import egm from '@pgl-apps/shared-images/logos/a-z-team-logos/E@0.25x.png'
import elg from '@pgl-apps/shared-images/logos/a-z-team-logos/E@1x.png'
import etiny from '@pgl-apps/shared-images/logos/a-z-team-logos/E@tiny.png'
import fmd from '@pgl-apps/shared-images/logos/a-z-team-logos/F@0.5x.png'
import fgm from '@pgl-apps/shared-images/logos/a-z-team-logos/F@0.25x.png'
import flg from '@pgl-apps/shared-images/logos/a-z-team-logos/F@1x.png'
import ftiny from '@pgl-apps/shared-images/logos/a-z-team-logos/F@tiny.png'
import gmd from '@pgl-apps/shared-images/logos/a-z-team-logos/G@0.5x.png'
import ggm from '@pgl-apps/shared-images/logos/a-z-team-logos/G@0.25x.png'
import glg from '@pgl-apps/shared-images/logos/a-z-team-logos/G@1x.png'
import gtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/G@tiny.png'
import hmd from '@pgl-apps/shared-images/logos/a-z-team-logos/H@0.5x.png'
import hgm from '@pgl-apps/shared-images/logos/a-z-team-logos/H@0.25x.png'
import hlg from '@pgl-apps/shared-images/logos/a-z-team-logos/H@1x.png'
import htiny from '@pgl-apps/shared-images/logos/a-z-team-logos/H@tiny.png'
import imd from '@pgl-apps/shared-images/logos/a-z-team-logos/I@0.5x.png'
import igm from '@pgl-apps/shared-images/logos/a-z-team-logos/I@0.25x.png'
import ilg from '@pgl-apps/shared-images/logos/a-z-team-logos/I@1x.png'
import itiny from '@pgl-apps/shared-images/logos/a-z-team-logos/I@tiny.png'
import jmd from '@pgl-apps/shared-images/logos/a-z-team-logos/J@0.5x.png'
import jgm from '@pgl-apps/shared-images/logos/a-z-team-logos/J@0.25x.png'
import jlg from '@pgl-apps/shared-images/logos/a-z-team-logos/J@1x.png'
import jtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/J@tiny.png'
import kmd from '@pgl-apps/shared-images/logos/a-z-team-logos/K@0.5x.png'
import kgm from '@pgl-apps/shared-images/logos/a-z-team-logos/K@0.25x.png'
import klg from '@pgl-apps/shared-images/logos/a-z-team-logos/K@1x.png'
import ktiny from '@pgl-apps/shared-images/logos/a-z-team-logos/K@tiny.png'
import lmd from '@pgl-apps/shared-images/logos/a-z-team-logos/L@0.5x.png'
import lgm from '@pgl-apps/shared-images/logos/a-z-team-logos/L@0.25x.png'
import llg from '@pgl-apps/shared-images/logos/a-z-team-logos/L@1x.png'
import ltiny from '@pgl-apps/shared-images/logos/a-z-team-logos/L@tiny.png'
import mmd from '@pgl-apps/shared-images/logos/a-z-team-logos/M@0.5x.png'
import mgm from '@pgl-apps/shared-images/logos/a-z-team-logos/M@0.25x.png'
import mlg from '@pgl-apps/shared-images/logos/a-z-team-logos/M@1x.png'
import mtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/M@tiny.png'
import nmd from '@pgl-apps/shared-images/logos/a-z-team-logos/N@0.5x.png'
import ngm from '@pgl-apps/shared-images/logos/a-z-team-logos/N@0.25x.png'
import nlg from '@pgl-apps/shared-images/logos/a-z-team-logos/N@1x.png'
import ntiny from '@pgl-apps/shared-images/logos/a-z-team-logos/N@tiny.png'
import number_2md from '@pgl-apps/shared-images/logos/a-z-team-logos/Number_2@0.5x.png'
import number_2gm from '@pgl-apps/shared-images/logos/a-z-team-logos/Number_2@0.25x.png'
import number_2lg from '@pgl-apps/shared-images/logos/a-z-team-logos/Number_2@1x.png'
import number_2tiny from '@pgl-apps/shared-images/logos/a-z-team-logos/Number_2@tiny.png'
import omd from '@pgl-apps/shared-images/logos/a-z-team-logos/O@0.5x.png'
import ogm from '@pgl-apps/shared-images/logos/a-z-team-logos/O@0.25x.png'
import olg from '@pgl-apps/shared-images/logos/a-z-team-logos/O@1x.png'
import otiny from '@pgl-apps/shared-images/logos/a-z-team-logos/O@tiny.png'
import pmd from '@pgl-apps/shared-images/logos/a-z-team-logos/P@0.5x.png'
import pgm from '@pgl-apps/shared-images/logos/a-z-team-logos/P@0.25x.png'
import plg from '@pgl-apps/shared-images/logos/a-z-team-logos/P@1x.png'
import ptiny from '@pgl-apps/shared-images/logos/a-z-team-logos/P@tiny.png'
import qmd from '@pgl-apps/shared-images/logos/a-z-team-logos/Q@0.5x.png'
import qgm from '@pgl-apps/shared-images/logos/a-z-team-logos/Q@0.25x.png'
import qlg from '@pgl-apps/shared-images/logos/a-z-team-logos/Q@1x.png'
import qtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/Q@tiny.png'
import rmd from '@pgl-apps/shared-images/logos/a-z-team-logos/R@0.5x.png'
import rgm from '@pgl-apps/shared-images/logos/a-z-team-logos/R@0.25x.png'
import rlg from '@pgl-apps/shared-images/logos/a-z-team-logos/R@1x.png'
import rtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/R@tiny.png'
import smd from '@pgl-apps/shared-images/logos/a-z-team-logos/S@0.5x.png'
import sgm from '@pgl-apps/shared-images/logos/a-z-team-logos/S@0.25x.png'
import slg from '@pgl-apps/shared-images/logos/a-z-team-logos/S@1x.png'
import stiny from '@pgl-apps/shared-images/logos/a-z-team-logos/S@tiny.png'
import tmd from '@pgl-apps/shared-images/logos/a-z-team-logos/T@0.5x.png'
import tgm from '@pgl-apps/shared-images/logos/a-z-team-logos/T@0.25x.png'
import tlg from '@pgl-apps/shared-images/logos/a-z-team-logos/T@1x.png'
import ttiny from '@pgl-apps/shared-images/logos/a-z-team-logos/T@tiny.png'
import umd from '@pgl-apps/shared-images/logos/a-z-team-logos/U@0.5x.png'
import ugm from '@pgl-apps/shared-images/logos/a-z-team-logos/U@0.25x.png'
import ulg from '@pgl-apps/shared-images/logos/a-z-team-logos/U@1x.png'
import utiny from '@pgl-apps/shared-images/logos/a-z-team-logos/U@tiny.png'
import vmd from '@pgl-apps/shared-images/logos/a-z-team-logos/V@0.5x.png'
import vgm from '@pgl-apps/shared-images/logos/a-z-team-logos/V@0.25x.png'
import vlg from '@pgl-apps/shared-images/logos/a-z-team-logos/V@1x.png'
import vtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/V@tiny.png'
import wmd from '@pgl-apps/shared-images/logos/a-z-team-logos/W@0.5x.png'
import wgm from '@pgl-apps/shared-images/logos/a-z-team-logos/W@0.25x.png'
import wlg from '@pgl-apps/shared-images/logos/a-z-team-logos/W@1x.png'
import wtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/W@tiny.png'
import xmd from '@pgl-apps/shared-images/logos/a-z-team-logos/X@0.5x.png'
import xgm from '@pgl-apps/shared-images/logos/a-z-team-logos/X@0.25x.png'
import xlg from '@pgl-apps/shared-images/logos/a-z-team-logos/X@1x.png'
import xtiny from '@pgl-apps/shared-images/logos/a-z-team-logos/X@tiny.png'
import ymd from '@pgl-apps/shared-images/logos/a-z-team-logos/Y@0.5x.png'
import ygm from '@pgl-apps/shared-images/logos/a-z-team-logos/Y@0.25x.png'
import ylg from '@pgl-apps/shared-images/logos/a-z-team-logos/Y@1x.png'
import ytiny from '@pgl-apps/shared-images/logos/a-z-team-logos/Y@tiny.png'
import zmd from '@pgl-apps/shared-images/logos/a-z-team-logos/Z@0.5x.png'
import zgm from '@pgl-apps/shared-images/logos/a-z-team-logos/Z@0.25x.png'
import zlg from '@pgl-apps/shared-images/logos/a-z-team-logos/Z@1x.png'
import ztiny from '@pgl-apps/shared-images/logos/a-z-team-logos/Z@tiny.png'
import type { Alphabet } from '@pgl-apps/shared/types'

import { ProgressiveImgLoader } from '../ProgressiveImgLoader/ProgressiveImgLoader'

const allAlphas = {
  amd,
  agm,
  alg,
  atiny,
  bmd,
  bgm,
  blg,
  btiny,
  cmd,
  cgm,
  clg,
  ctiny,
  dmd,
  dgm,
  dlg,
  dtiny,
  emd,
  egm,
  elg,
  etiny,
  fmd,
  fgm,
  flg,
  ftiny,
  gmd,
  ggm,
  glg,
  gtiny,
  hmd,
  hgm,
  hlg,
  htiny,
  imd,
  igm,
  ilg,
  itiny,
  jmd,
  jgm,
  jlg,
  jtiny,
  kmd,
  kgm,
  klg,
  ktiny,
  lmd,
  lgm,
  llg,
  ltiny,
  mmd,
  mgm,
  mlg,
  mtiny,
  nmd,
  ngm,
  nlg,
  ntiny,
  omd,
  ogm,
  olg,
  otiny,
  pmd,
  pgm,
  plg,
  ptiny,
  qmd,
  qgm,
  qlg,
  qtiny,
  rmd,
  rgm,
  rlg,
  rtiny,
  smd,
  sgm,
  slg,
  stiny,
  tmd,
  tgm,
  tlg,
  ttiny,
  umd,
  ugm,
  ulg,
  utiny,
  vmd,
  vgm,
  vlg,
  vtiny,
  wmd,
  wgm,
  wlg,
  wtiny,
  xmd,
  xgm,
  xlg,
  xtiny,
  ymd,
  ygm,
  ylg,
  ytiny,
  zmd,
  zgm,
  zlg,
  ztiny,
  number_2md,
  number_2gm,
  number_2lg,
  number_2tiny,
}

interface Props {
  alpha: Alphabet
  size?: 'sm' | 'md' | 'lg' // 400 x 270 | 800 x 540 | 1600 x 1080 px
}

export const AlphaLogo = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { alpha, size = 'sm' } = props

  return (
    <div className="relative">
      <ProgressiveImgLoader
        placeholderSrc={allAlphas[`${alpha}tiny`]}
        src={allAlphas[`${alpha}${size}`]}
        className={`inset-0 w-full`}
        alt=""
      />
    </div>
  )
}
