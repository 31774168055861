import type { ReactNode } from 'react'

import type { TypographyTags } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

export type HeadingColors = 'muted' | 'punched' | 'highlighted'

// Usually page = h1 | section = h2 | article = h3 | paragraph = h4
type Type = 'page' | 'section' | 'article' | 'paragraph'

interface Props {
  tag?: TypographyTags
  type?: Type
  className?: string // Only use if necessary
  color?: HeadingColors // kinda a hack for demo
  hasDivider?: boolean
  children: ReactNode
}

// TODO refactor to fit kap designs
export const Heading = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    tag: Tag = 'h1',
    type = 'page',
    className = '',
    color = 'punched',
    hasDivider = false,
    children,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const dividerClass = hasDivider ? 'divider-b pb-3' : ''
  return (
    <Tag
      className={`${styles.heading} ${dividerClass} ${
        styles[`heading__${type}`]
      } ${className} text-theme-${color}`}
    >
      {children}
    </Tag>
  )
}
