import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'

export type TypeOpts = 'solid' | 'outline' | 'text'

export interface PrimaryBtnLink {
  label?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  type?: TypeOpts | 'danger'
  state?: 'default' | 'disabled'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  srOnly?: string
  className?: string
}

export interface PrimaryBtn extends PrimaryBtnLink {
  onClick?: () => void
  btnType?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['type']
}

export interface PrimaryLnk extends PrimaryBtnLink {
  to: string
  analyticsParams?: Record<string, string>
  isExternal?: boolean
}
