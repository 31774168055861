import { ReactComponent as ExtLink } from '@pgl-apps/shared-images/svgr/icons/external-link.svg'

import { button, chamfer } from '../styles'
import type { PrimaryBtnLink } from '../types'
import { Inner } from './Inner'

interface Props extends PrimaryBtnLink {
  href: string
}

export const LinkExternal = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    href,
    label,
    leftIcon,
    type = 'solid',
    state = 'default',
    size = 'md',
    srOnly,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isDisabled = state === 'disabled'
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={button({ size, type, isDisabled })}
      style={chamfer}
    >
      <Inner
        leftIcon={leftIcon}
        rightIcon={<ExtLink />}
        label={label}
        srOnly={srOnly}
      />
    </a>
  )
}
