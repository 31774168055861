interface Props {
  searchValue: string
  suggestion: string
}

export const Suggestion = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { searchValue, suggestion } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const i = suggestion.toLowerCase().indexOf(searchValue.toLowerCase())
  const first = suggestion.substring(0, i)
  const second = suggestion.substring(i, i + searchValue.length)
  const third = suggestion.substring(i + searchValue.length, suggestion.length)

  return (
    <span>
      {first && <span>{first}</span>}
      {second && <span className={i !== -1 ? 'font-bold' : ''}>{second}</span>}
      {third && <span>{third}</span>}
    </span>
  )
}
