import { NavLink } from 'react-router-dom'

import { routePaths } from '@pgl-apps/kap-stake/constants'

import styles from './index.module.scss'

const getClassName = (isActive: boolean) =>
  `${styles.navItem} ${isActive ? styles.navItem__active : ''}`

export const SecondaryNav = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div>
      {Object.keys(routePaths).map((key) => (
        <NavLink
          key={routePaths[key].label}
          to={routePaths[key].path}
          className={(c) => getClassName(c.isActive)}
          end
        >
          {routePaths[key].label}
        </NavLink>
      ))}
    </div>
  )
}
