import clsx from 'clsx'

import { Inner } from './private/Inner'
import { button, chamfer } from './styles'
import type { PrimaryBtn } from './types'

export const Btn = (props: PrimaryBtn) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    label,
    leftIcon,
    rightIcon,
    onClick,
    type = 'solid',
    state = 'default',
    size = 'md',
    srOnly,
    btnType = 'button',
    className,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isDisabled = state === 'disabled'
  return (
    <button
      type={btnType}
      onClick={onClick}
      className={clsx(button({ size, type, isDisabled }), className)}
      disabled={isDisabled}
      style={type === 'text' ? {} : chamfer}
    >
      <Inner
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
        srOnly={srOnly}
      />
    </button>
  )
}
