import { useEffect, useRef } from 'react'

import { ReactComponent as Dots } from '@pgl-apps/shared-images/svgr/icons/three-dots.svg'

import styles from '../index.module.scss'

interface Props {
  isDown: boolean
  onClick: () => void
}

export const Trigger = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { isDown, onClick } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const dropdownBtnRef = useRef(null)

  // --------------------- ===
  //  EFFECT
  // ---------------------
  useEffect(() => {
    const escapeListener = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape' && isDown) {
        onClick()
      }
    }
    const clickListener = (evt: PointerEvent) => {
      if (!dropdownBtnRef.current.contains(evt.target) && isDown) {
        onClick()
      }
    }
    // Attach the listener when visible
    if (isDown) {
      document.addEventListener('click', clickListener)
      document.addEventListener('keyup', escapeListener)
    }

    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [isDown])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const downClass = isDown ? styles.trigger__down : ''
  return (
    <button
      className={`${styles.trigger} ${downClass}`}
      onClick={onClick}
      ref={dropdownBtnRef}
      type="button"
    >
      <span className={styles.img}>
        <Dots />
      </span>
      <span className="sr-only">Toggle menu</span>
    </button>
  )
}
