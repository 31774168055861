import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import dragArrows from '@pgl-apps/shared-images/icons/drag-arrows.svg'
import type { InputConfig } from '@pgl-apps/shared/types'

import { InputBase } from '../Input/private/InputBase'
import { InputWrapper } from '../private/InputWrapper'

gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)

interface Props {
  inputConfig: InputConfig
  label?: string
  min: number
  max: number
  onChange: (val: string) => void
  screenWidth: number // prop bc redux shouldn't be in a shared component
  type?: 'x' | 'y'
}

export const RangeSlider = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    label,
    min,
    max,
    onChange,
    screenWidth,
    type = 'x',
  } = props
  const inputValNum = parseInt(inputConfig.value)

  // --------------------- ===
  //  REFS
  // ---------------------
  const thumbRef = useRef(null)
  const trackRef = useRef(null)
  const draggableRef = useRef(null)

  const thumbOffset = useRef(0)
  const trackSize = useRef(100)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDragging, setIsDragging] = useState(false)

  // --------------------- ===
  //  DRAGGABLE
  // ---------------------
  const buildDraggable = () => {
    const tracker = InertiaPlugin.track(thumbRef.current, 'rotation')[0] // allows getVolicity to work

    draggableRef.current = Draggable.create(thumbRef.current, {
      type,
      cursor: 'grab',
      activeCursor: 'grabbing',
      inertia: true,
      onDragStart: () => {
        setIsDragging(true)
      },
      onDrag: handleSlideChange,
      onDragEnd: () => {
        const velocity = Math.round(
          InertiaPlugin.getVelocity(thumbRef.current, type)
        )

        if (velocity === 0) {
          setIsDragging(false)
        }
      },
      onThrowUpdate: handleSlideChange,
      onThrowComplete: () => {
        setIsDragging(false)
      },
    })
  }

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const checkHorizontal = () => type === 'x'
  const getDimension = () => (checkHorizontal() ? 'width' : 'height')
  const getPct = () => (inputValNum - min) / (max - min)
  const getDraggable = () => draggableRef.current[0]

  const getValueFromPosition = (position: number) => {
    const ratio = (position + thumbOffset.current) / trackSize.current
    const sliderValue = (max - min) * ratio
    return sliderValue
  }

  const getPositionFromValue = useCallback(() => {
    const { maxX, minX } = getDraggable()
    const pct = getPct()
    const position = (maxX - minX) * pct + minX
    return position
    // Don't need getPct or getDraggable
  }, [inputConfig.value, min, max]) // eslint-disable-line react-hooks/exhaustive-deps

  const setThumbOffset = () => {
    const dimension = getDimension()
    const thumbSize = thumbRef.current.getBoundingClientRect()[dimension]
    thumbOffset.current = thumbSize / 2
  }

  const setTrackSize = () => {
    const dimension = getDimension()
    trackSize.current = trackRef.current.getBoundingClientRect()[dimension]
  }

  const setDraggableBounds = () => {
    const bound = checkHorizontal() ? 'X' : 'Y'
    getDraggable().applyBounds({
      [`min${bound}`]: -thumbOffset.current,
      [`max${bound}`]: trackSize.current - thumbOffset.current,
    })
  }

  const setSizeRefs = () => {
    setThumbOffset()
    setTrackSize()
    setDraggableBounds()
  }

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleBlur = () => {
    if (isNaN(inputValNum)) {
      onChange(min.toString())
    }
  }

  const handleChange = (val: number) => {
    // Value can be NaN if no user hits backspace
    const limitedVal = isNaN(val) ? val : Math.min(max, Math.max(min, val))
    onChange(limitedVal.toString())
  }

  const handleInputChange = (val: string) => {
    handleChange(parseInt(val))
  }

  const handleSlideChange = () => {
    const position = draggableRef.current[0][type]
    const value = Math.round(getValueFromPosition(position))
    handleChange(value)
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    buildDraggable()
    setSizeRefs()
    // Just need screenWidth
  }, [screenWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Keep thumb in sync if input changes
    if (!isDragging) {
      const position = getPositionFromValue()
      gsap.to(thumbRef.current, {
        [type]: position,
      })
    }
  }, [getPositionFromValue, isDragging, type, screenWidth])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <InputWrapper label={label} inputConfig={inputConfig}>
      <div className="flex">
        <div className="px-5 grow flex">
          <div className="relative flex items-center grow">
            <div
              className="rounded bg-theme-muted h-px w-full"
              ref={trackRef}
            />
            <div
              className="rounded bg-theme-lightsaber h-[3px] w-full absolute"
              style={{ width: `${getPct() * 100}%` }}
            />
            <div
              className={`w-10 h-6 absolute rounded-full border-[2px] border-theme-fieldBorder bg-theme-punched shadow-sm flex px-2 items-center ${
                inputConfig.isDisabled || inputConfig.state === 'disabled'
                  ? 'hidden'
                  : ''
              }`}
              ref={thumbRef}
            >
              <img src={dragArrows} alt="" />
            </div>
          </div>
        </div>
        <div className="w-16 ml-4">
          <InputBase
            inputConfig={inputConfig}
            type="number"
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </InputWrapper>
  )
}
