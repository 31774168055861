import { useEffect, useState } from 'react'

import type { SVGR } from '@pgl-apps/shared/types'

import { Loading } from '../../messaging'
import { PrimaryBox } from '../../region'
import { Donut } from '../private/Donut'
import type { DonutChartValue } from '../types'
import { ValuesKey } from './private/ValuesKey'

export interface DonutChartProps {
  title?: string
  loading?: boolean
  values: DonutChartValue[]
  Icon?: SVGR
  minSize?: number
  className?: string
}

export function DonutChart(props: DonutChartProps) {
  const {
    title = '',
    loading = false,
    values = [],
    Icon,
    minSize = 150,
    className = 'h-full',
  } = props

  const [valuesSum, setValuesSum] = useState(null)

  useEffect(() => {
    const sum = values.reduce((prev, curr) => {
      return prev + curr.value
    }, 0)
    setValuesSum(sum)
  }, [values])

  return (
    <PrimaryBox label={title}>
      {loading ? (
        <div className={`flex justify-center items-center ${className}`}>
          <Loading type="ThreeDots" height={40} width={40} />
        </div>
      ) : values.length ? (
        <div
          className={`flex flex-wrap sm:flex-nowrap h-full gap-5 ${className}`}
        >
          <div className="w-full flex flex-wrap gap-3 sm:inline sm:w-[280px] mb-3 pb-3 border-b border-dotted border-theme-tableHeaderBg sm:border-none">
            <div className="flex w-full pb-[15px] border-b border-dotted border-theme-tableHeaderBg items-center">
              <span className="h-[15px] mr-3">{Icon && <Icon />}</span>
              <span className="font-bold text-lg">{valuesSum}</span>
            </div>
            <ValuesKey values={values} sum={valuesSum} />
          </div>

          <div className="w-full flex gap-3">
            <div className={`grow ${className}`}>
              <Donut title={title} values={values} minSize={minSize} />
            </div>
          </div>
        </div>
      ) : (
        <div className={`${className} flex justify-center items-center`}>
          No values
        </div>
      )}
    </PrimaryBox>
  )
}

export default DonutChart
