import type { ReactNode } from 'react'

import type { CtaProps } from '@pgl-apps/shared/types'

import { CtaBtn } from '../../buttons'
import { Error } from '../../typography'

// import styles from './index.module.scss'\

interface Props {
  children: ReactNode
  onSubmit: () => void
  isSubmitting?: boolean
  showSubmitButton?: boolean
  btnLabel?: string
  errorMsgs?: string[]
  className?: string
  buttonConfig?: Omit<CtaProps, 'onClick' | 'label'>
}

export const FormWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    onSubmit,
    isSubmitting = false,
    showSubmitButton = true,
    btnLabel = 'Submit',
    errorMsgs = [],
    className = 'grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6',
    buttonConfig = null,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <form onSubmit={onSubmit}>
      <div className={className}>{children}</div>
      <div className="w-full text-right mt-4">
        {showSubmitButton && (
          <CtaBtn
            onClick={onSubmit}
            state={isSubmitting ? 'loading' : 'default'}
            {...buttonConfig}
          >
            {btnLabel}
          </CtaBtn>
        )}
        {errorMsgs.length > 0 && (
          <div className="mt-4">
            <Error message={`You have (${errorMsgs.length}) errors.`} />
          </div>
        )}
      </div>
    </form>
  )
}
