import { BigNumber, ethers } from 'ethers'
import { AbiCoder } from 'ethers/lib/utils'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'

export const useGovernance = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const governanceProvider = useSelector(
    (store: IRootState) => store.ethers.governanceProvider
  )

  // --------------------- ===
  //  STATE
  // ---------------------
  const [proposeThreshold, setProposeThreshold] = useState('')
  const [proposalId, setProposalId] = useState('')
  const [snapshotKAPWeight, setSnapshotKAPWeight] = useState('')
  const [transactParamsHash, setTransactParamsHash] = useState('')
  const [proposalParamsHash, setProposalParamsHash] = useState('')
  const [voteWindow, setVoteWindow] = useState('')
  const [quorum, setQuorum] = useState('')
  const [voteCount, setVoteCount] = useState('')
  const [hasVoted, setHasVoted] = useState('')

  // --------------------- ===
  //  HELPERS
  // ---------------------
  // const iface = new ethers.utils.Interface([
  //   'function getRewardsRuleLength() view returns (uint256)'
  // ]);

  // const encodedCallData = iface.encodeFunctionData('getRewardsRuleLength', []);

  // const transactParams = [
  //   ['0xeD31b8C8853Bd6C596327DA8240e5C810a43aD23'],
  //   [0],
  //   [encodedCallData],
  //   [false],
  // ];

  // --------------------- ===
  //  METHODS
  // ---------------------
  // User has to meet weight threshold in order to propose
  const checkProposeThreshold = useCallback(async () => {
    const threshold = governanceProvider.threshold()
    setProposeThreshold(threshold.toString())
  }, [governanceProvider])

  const getLatestProposalID = useCallback(async () => {
    const id = governanceProvider.latestProposalID()
    setProposalId(id.toString())
  }, [governanceProvider])

  const getSnapshotKAPWeight = useCallback(
    async (staker) => {
      // TODO is this right?
      const provider = new ethers.providers.InfuraProvider('rinkeby')
      const voidSigner = new ethers.VoidSigner(staker, provider)
      // Specify which sources to get weight from
      const weightSources = [
        [true, true], // KAP: vesting, kapStaking
        [true], // LP: kapEthStaking
      ]
      const [weightKAP, weightLP] = await governanceProvider
        .connect(voidSigner)
        .getWeights(weightSources)
      // TODO: UniswapV2 pair has to be created in order to convert weightLP
      // return (weightKAP.add(await governanceContractProvider.convertLP(weightLP))).toString();
      setSnapshotKAPWeight(weightKAP.toString())
    },
    [governanceProvider]
  )

  // It is important to verify that the manually calc'ed paramsHash is the same as the hash stored on chain
  // Calculates the hash for the given params
  const calcTransactParamsHash = useCallback(async (transactParams) => {
    const abiEncoded = new AbiCoder().encode(
      ['address[]', 'uint256[]', 'bytes[]', 'bool[]'],
      transactParams
    )
    const ethersHash = BigNumber.from(ethers.utils.keccak256(abiEncoded))
    // Can verify manual method with contract call
    // const solidityHash = await governanceContractProvider.getTransactParamsHash(..._transactParams);
    // console.log(ethersHash.toString(), solidityHash.toString());
    setTransactParamsHash(ethersHash.toString())
  }, [])

  // Retrieves the hash for the given proposal ID
  const getProposalParamsHash = useCallback(
    async (proposalID) => {
      const pph = await governanceProvider.proposals(proposalID)
      setProposalParamsHash(pph.transactParamsHash.toString())
    },
    [governanceProvider]
  )

  const checkVoteWindow = useCallback(
    async (proposalID) => {
      const vw = await governanceProvider.checkVoteWindow(proposalID)
      setVoteWindow(vw.toString())
    },
    [governanceProvider]
  )

  const checkQuorum = useCallback(
    async (proposalID) => {
      const qrm = await governanceProvider.checkQuorum(proposalID)
      setQuorum(qrm.toString())
    },
    [governanceProvider]
  )

  const checkVoteCount = useCallback(
    async (proposalID) => {
      const vc = await governanceProvider.checkVoteCount(proposalID)
      setVoteCount(vc.toString())
    },
    [governanceProvider]
  )

  const checkHasVoted = useCallback(
    async (proposalID, staker) => {
      const hv = await governanceProvider.checkHasVoted(proposalID, staker)
      setHasVoted(hv.toString())
    },
    [governanceProvider]
  )

  return {
    checkProposeThreshold,
    getLatestProposalID,
    getSnapshotKAPWeight,
    calcTransactParamsHash,
    getProposalParamsHash,
    checkVoteWindow,
    checkQuorum,
    checkVoteCount,
    checkHasVoted,
    proposeThreshold,
    proposalId,
    snapshotKAPWeight,
    transactParamsHash,
    proposalParamsHash,
    voteWindow,
    quorum,
    voteCount,
    hasVoted,
  }
}

export default useGovernance
