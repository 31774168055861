import { createReducer } from '@reduxjs/toolkit'
import { Contract, ethers } from 'ethers'

import { abiGovernance, config } from '@pgl-apps/shared/api'

// --------------------- ===
//  SETUP
// ---------------------
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sliceName = 'ethers'
const provider = new ethers.providers.InfuraProvider(config.network)

const governanceProvider = new Contract(
  config.addresses.governance,
  abiGovernance.abi,
  provider
)

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState = {
  provider,
  governanceProvider,
}

// --------------------- ===
//  ACTIONS
// ---------------------
// None yet

// --------------------- ===
//  REDUCER
// ---------------------
// IMPORTANT: All addCases(s) must come before addMatcher(s)
// https://redux-toolkit.js.org/api/createreducer#builderaddmatcher
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reducer = createReducer(initialState, (builder) => {
  // None yet
})

// export { actions };
export default reducer
