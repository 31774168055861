import React from 'react'

import styles from './index.module.scss'

interface Props {
  label: string
}

// Note we can probably get rid of this component in favor of
// Tailwind's sr-only class
export const SrOnly = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <span className="visually-hidden sr-only">{label}</span>
}
