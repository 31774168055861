import type { InputConfigCheckbox } from '@pgl-apps/shared/types'

import { InputWrapper } from '../private/InputWrapper'

interface Props {
  className?: string
  error?: string
  hideLabel?: boolean
  inputConfig: InputConfigCheckbox
  onChange: () => void
}

export const Toggle = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { inputConfig, onChange, hideLabel = false, className = '' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isChecked = inputConfig.value
  const activeClass = isChecked ? '!left-6 active:!left-4' : ''
  return (
    <div className={`${className}`}>
      <InputWrapper hideLabel={hideLabel} inputConfig={inputConfig}>
        <div className="relative">
          <input
            className={`w-0 h-0 hidden`}
            type="checkbox"
            value={inputConfig.label}
            checked={inputConfig.value}
            onChange={onChange}
          />
          <div
            className="w-12 h-6 rounded-full bg-zinc-500"
            style={{
              background: isChecked ? '#7FEB8B' : '',
            }}
          />
          <div
            className={`${activeClass} w-6 h-6 rounded-full bg-white active:w-8 absolute left-0 top-0 transition-all`}
          />
        </div>
      </InputWrapper>
    </div>
  )
}
