// const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

// Add opacity
function withOpacity(variableName) {
  return ({ opacityValue }) => {
    if (opacityValue !== undefined) {
      return `rgba(var(${variableName}) / ${opacityValue})`
    }
    return `rgb(var(${variableName}))`
  }
}

const headerHeight = '4rem'

/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: 'class',
  theme: {
    container: {
      padding: '1rem', // x padding on container class
    },
    extend: {
      colors: {
        kap: {
          black: withOpacity('--color-kap-black'),
          white: withOpacity('--color-kap-white'),
          neon: withOpacity('--color-kap-neon'),
          teal: withOpacity('--color-kap-teal'),
          blue: withOpacity('--color-kap-blue'),
          purple: withOpacity('--color-kap-purple'),
          gray: withOpacity('--color-kap-gray'),
          'light-gray': withOpacity('--color-kap-light-gray'),
          warning: withOpacity('--color-kap-warning'),
          danger: withOpacity('--color-kap-danger'),
          success: withOpacity('--color-kap-success'),
        },
        theme: {
          base: withOpacity('--color-text-base'),
          muted: withOpacity('--color-text-muted'),
          punched: withOpacity('--color-text-punched'),
          highlighted: withOpacity('--color-text-highlighted'),
          bg: withOpacity('--color-bg'),
          bgMuted: withOpacity('--color-bg-muted'),
          containerBg: withOpacity('--color-container-bg'),
          fieldBg: withOpacity('--color-field-bg'),
          fieldBorder: withOpacity('--color-field-border'),
          fieldPlaceholder: withOpacity('--color-field-placeholder'),
          primaryCtaText: withOpacity('--color-primary-cta-text'),
          primaryCtaBg: withOpacity('--color-primary-cta-bg'),
          primaryCtaBorder: withOpacity('--color-primary-cta-border'),
          secondaryCtaText: withOpacity('--color-secondary-cta-text'),
          secondaryCtaBg: withOpacity('--color-secondary-cta-bg'),
          secondaryCtaBorder: withOpacity('--color-secondary-cta-border'),
          cancelCtaText: withOpacity('--color-cancel-cta-text'),
          cancelCtaBorder: withOpacity('--color-cancel-cta-border'),
          tableBg: withOpacity('--color-table-bg'),
          tableText: withOpacity('--color-table-text'),
          tableHeaderBg: withOpacity('--color-table-header-bg'),
          tableHeaderText: withOpacity('--color-table-header-text'),
          lightsaber: withOpacity('--color-lightsaber'),
          success: withOpacity('--color-success'),
          error: withOpacity('--color-error'),
          warning: withOpacity('--color-warning'),
          info: withOpacity('--color-info'),
        },
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
        heading: ['Transducer', ...defaultTheme.fontFamily.sans], // 800
      },
      screens: {
        bpNav: defaultTheme.screens.xl,
        bpTable: defaultTheme.screens.lg,
        bpSearch: defaultTheme.screens.sm,
      },
      borderRadius: {
        wrapperBox: '1rem',
        innerBox: '0.5rem',
      },
      zIndex: {
        lift: '1',
        nav: '9',
        header: '19',
        modal: '29',
        notifications: '39',
      },
      maxWidth: {
        site: 1568,
      },
      spacing: {
        headerHeight,
        navWidth: '22rem',
      },
      boxShadow: {
        profileLink:
          '0px 0px 20px ' +
          withOpacity('--color-text-highlighted')({ opacityValue: 0.4 }),
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
  ],
  content: [], // This empty property is here to satisfy `Config` type. This value is replaced by project-specific configurations so it doesn't have any effect (https://tailwindcss.com/docs/presets#merging-logic-in-depth)
}
