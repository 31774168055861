import type * as Highcharts from 'highcharts'

interface ChartValue {
  value: number
  key: string
  selected?: boolean
}

export interface ChartValues {
  values: ChartValue[]
  label: string
}

/**
 * A dictionary of charts that can be displayed.
 * Used to determine which chart(s) should be displayed for given data.
 * When used, populate with every possible chart key.
 * E.g. for games:
 * ["Axie Infinity"]: true,
 * ["Pegaxy"]: false
 */
export interface ChartConfig {
  [key: string]: boolean
}

// High Charts
export type HCChartTypes = 'area' | 'line' | 'column' | 'bar'

export interface HCPropsBasic {
  type: HCChartTypes
  series: Highcharts.SeriesOptionsType[]
  options?: never
  isLoading?: boolean
}
export interface HCPropsAdvanced {
  type?: never
  series?: never
  options: Highcharts.Options
  isLoading?: boolean
}
