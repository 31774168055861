import { ReactComponent as Arrow } from '@pgl-apps/shared-images/svgr/icons/arrow-min-right.svg'

interface Props {
  direction: 'left' | 'right'
}

export const ArrowButton = (
  props: Props & React.ComponentPropsWithoutRef<'button'>
) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { direction, ...buttonProps } = props

  const isLeft = direction === 'left'

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      type="button"
      className={`px-1 fill-current hover:text-white disabled:text-theme-muted`}
      {...buttonProps}
    >
      <Arrow
        className="w-full"
        style={{
          transform: isLeft ? 'scale(-1)' : '',
        }}
      />
      <span className="sr-only">{isLeft ? 'go left' : 'go right'}</span>
    </button>
  )
}
