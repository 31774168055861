import { ReactComponent as Arrows } from '@pgl-apps/shared-images/svgr/specialized/sort-arrows.svg'
import type { SortOpts } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

interface Props {
  label: string
  sortType: SortOpts | null
  onClick: () => void
}

export const SortableBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, sortType, onClick } = props

  // --------------------- ===
  //  RENDER
  // ---------------------

  return (
    <button
      className="flex uppercase relative pr-4 max-w-full"
      onClick={onClick}
      type="button"
    >
      <span className="overflow-hidden text-ellipsis">{label}</span>
      <span
        className={`w-2 text-theme-muted opacity-75 absolute right-1 top-0 bottom-0 ${
          sortType ? styles[`arrows__${sortType}`] : ''
        }`}
      >
        <Arrows />
      </span>
    </button>
  )
}
