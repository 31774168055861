import { useState } from 'react'

import type { InputConfig, SVGR } from '@pgl-apps/shared/types'

import { InputBase } from '../Input/private/InputBase'
import { InputWrapper } from '../private/InputWrapper'
import styles from './index.module.scss'

interface Props {
  inputConfig: InputConfig
  label?: string
  onChange: (arg0: string) => void // should be useCallback
  onFocus?: () => void
  onBlur?: (e) => void
  suffix: React.ReactNode
  hasSubmitErrors?: boolean
  required?: boolean
  type?: 'text' | 'number'
  placeholder?: string
  Icon?: SVGR
  hideLabel?: boolean
  showSuffix?: boolean
  error?: string
}

export const InputWithSuffix = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    label,
    inputConfig,
    onChange,
    onFocus,
    onBlur,
    suffix,
    hasSubmitErrors = false,
    required = false,
    type = 'text',
    placeholder = '',
    Icon = null,
    hideLabel = false,
    showSuffix = true,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [didBlur, setDidBlur] = useState(false)

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleBlur = (e) => {
    setDidBlur(true)
    onBlur(e)
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  // TODO error, success and valid styles should be moved to _twLayers.scss
  return (
    <InputWrapper
      label={label}
      hideLabel={hideLabel}
      inputConfig={inputConfig}
      required={required}
      canShowError={hasSubmitErrors || didBlur}
    >
      <div className="relative">
        <InputBase
          inputConfig={inputConfig}
          onChange={onChange}
          required={required}
          onFocus={onFocus}
          onBlur={handleBlur}
          className="pr-14"
          type={type}
          placeholder={placeholder}
          Icon={Icon}
        />
        {showSuffix && (
          <div
            className={`${styles.selectWrapper} absolute top-px bottom-px right-px max-w-[50%] items-center flex gap-4`}
          >
            <div className="w-px bg-theme-muted h-1/2" />
            {suffix}
          </div>
        )}
      </div>
    </InputWrapper>
  )
}
