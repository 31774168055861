import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import { useState } from 'react'
import type { ReactNode } from 'react'

import { CarouselRoot } from './CarouselRoot'
import { ArrowButton } from './private/ArrowButton'

// https://greensock.com/forums/topic/26288-what-is-the-proper-way-to-import-inside-nexxtjs/
if (typeof window !== 'undefined') {
  gsap.registerPlugin(InertiaPlugin)
  gsap.registerPlugin(Draggable)
}

type CarouselShadowColor = 'theme-bg' | 'theme-containerBg'
type CarouselShadowGradientColor = 'from-theme-bg' | 'from-theme-containerBg'
interface Props {
  shadowColor?: CarouselShadowColor
  gap?: 'gap-3' | 'gap-5'
  children: ReactNode
}

// Common carousel with arrows
export const Carousel = (props: Props) => {
  // --------------------- ===
  //  STATE
  // ---------------------
  const [overflowStates, setOverflowStates] = useState({
    isOverflow: true,
    isMaxLeft: true,
    isMaxRight: false,
  })
  const [sliderBump, setSliderBump] = useState(0)

  // --------------------- ===
  //  PROPS
  // ---------------------
  const { shadowColor = 'theme-bg', gap, children } = props

  const gradientColor = gradientColorForColor(shadowColor)

  // --------------------- ===
  //  RENDER
  // ---------------------
  const { isOverflow, isMaxLeft, isMaxRight } = overflowStates
  // Note w-6 and p-6 need to match and w-12 should be double
  return (
    <div
      className={`relative h-full flex justify-center ${
        isOverflow ? 'px-8' : 'px-0'
      }`}
    >
      <CarouselRoot
        overflowCallback={(states) => setOverflowStates(states)}
        sliderBump={sliderBump}
        gap={gap}
      >
        {children}
      </CarouselRoot>
      {isOverflow && (
        <>
          <div className="absolute left-0 top-0 bottom-0">
            {!isMaxLeft && (
              <div className="absolute top-0 left-0 bottom-0 flex">
                <div className={`w-6 bg-${shadowColor}`} />
                {/* "from-${shadowColor}" did not work here so we are getting the color from gradientColorForColor() helper function */}
                <div className={`w-12 bg-gradient-to-r ${gradientColor}`} />
              </div>
            )}
            <div className="w-6 h-full flex relative">
              <ArrowButton
                direction="left"
                onClick={() => setSliderBump((prev) => prev + 400)}
                disabled={isMaxLeft}
              />
            </div>
          </div>
          <div className="absolute right-0 top-0 bottom-0">
            {!isMaxRight && (
              <div className="absolute top-0 right-0 bottom-0 flex">
                {/* "from-${shadowColor}" did not work here so we are getting the color from gradientColorForColor() helper function */}
                <div className={`w-12 bg-gradient-to-l ${gradientColor}`} />
                <div className={`w-6 bg-${shadowColor}`} />
              </div>
            )}
            <div className="w-6 h-full flex relative">
              <ArrowButton
                direction="right"
                onClick={() => setSliderBump((prev) => prev - 400)}
                disabled={isMaxRight}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

// Creating gradient color with "from-${shadowColor}" didn't work so we need this function
const gradientColorForColor = (
  color: CarouselShadowColor
): CarouselShadowGradientColor => {
  if (color === 'theme-bg') {
    return 'from-theme-bg'
  } else {
    return 'from-theme-containerBg'
  }
}
