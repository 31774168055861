// Used in useStakingTemplate
export interface TxHash {
  txHash: string
  status: 'pending' | 'complete' | 'failed'
  message: string
}

export interface RewardsEmission {
  rate: string // kapPerSecond
  expiration: string // expiration timestamp
}
