import { createAction, createReducer } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import type {
  ClearNotificationArg,
  NotificationArg,
} from '@pgl-apps/shared/types'

type NotificationState = {
  notifications: ClearNotificationArg[]
}

export const buildNotificationSlice = () => {
  // --------------------- ===
  //  SETUP
  // ---------------------
  const sliceName = 'notifications'

  const ADD_NOTIFICATION = `${sliceName}/ADD_NOTIFICATION`
  const CLEAR_NOTIFICATION = `${sliceName}/CLEAR_NOTIFICATION`

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const messageConstructor = (
    message: NotificationArg['message'],
    type: NotificationArg['type']
  ) => ({
    message,
    type,
    id: uuidv4(),
  })

  // --------------------- ===
  //  INITIAL STATE
  // ---------------------
  const initialState: NotificationState = {
    notifications: [],
  }

  // --------------------- ===
  //  ACTIONS
  // ---------------------
  // SYNC
  const addNotification = createAction<NotificationArg>(ADD_NOTIFICATION)
  const clearNotification = createAction<{
    id: ClearNotificationArg['id']
  }>(CLEAR_NOTIFICATION)

  // --------------------- ===
  //  REDUCER
  // ---------------------
  const reducer = createReducer(initialState, (builder) => {
    builder
      .addCase(addNotification, (state, action) => {
        const { message, type } = action.payload
        state.notifications.push(messageConstructor(message, type))
      })
      .addCase(clearNotification, (state, action) => {
        const { id } = action.payload

        const index = state.notifications.findIndex((item) => item.id === id)

        state.notifications.splice(index, 1)
      })
  })

  return {
    reducer,
    actions: {
      addNotification,
      clearNotification,
    },
  }
}
