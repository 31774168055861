import type { ReactNode } from 'react'

interface Props {
  children: ReactNode // trigger for dropdown
  showDivider?: boolean
}

export const DropdownBucket = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, showDivider = true } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className={`
        min-w-[11rem]
        bg-theme-bg
        border
        border-theme-muted
        rounded-innerBox
        p-4
        text-sm
        gap-3
        flex
        flex-col
        text-left
        ${showDivider ? '[&>*:not(:last-child)]:divider-b' : ''}
        [&>*:not(:last-child)]:pb-2
        overflow-y-auto
        max-h-[250px]
        no-scrollbar`}
    >
      {children}
    </div>
  )
}
