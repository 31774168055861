export const Footer = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <footer className="z-header text-xs relative text-theme-muted px-12 py-8 mt-48">
      <div className="text-sm flex flex-col items-center w-full pb-8">
        <span>
          Analytics powered by{' '}
          <a
            href="https://www.treehouse.finance/"
            target="_blank"
            rel="noreferrer"
          >
            Treehouse
          </a>
          .
        </span>
        <span>
          Data provided by{' '}
          <a href="https://www.alchemy.com/" target="_blank" rel="noreferrer">
            Alchemy
          </a>
          ,{' '}
          <a href="https://thegraph.com/" target="_blank" rel="noreferrer">
            The Graph
          </a>
          , and{' '}
          <a href="https://www.coingecko.com/" target="_blank" rel="noreferrer">
            CoinGecko
          </a>
          .
        </span>
      </div>
      <div className="flex flex-col gap-4 md:flex-row justify-between text-xs">
        <div className="flex gap-2 items-center">
          <a
            href="https://docs.kapital.gg/disclaimer"
            target="_blank"
            rel="noreferrer"
            className="pr-3 border-r-2 border-gray-500"
          >
            Disclaimer
          </a>
          <span>&copy; 2022 Kapital DAO, ALL RIGHTS RESERVED.</span>
        </div>

        <div className="py-1">Version - 1.329.32420</div>
      </div>
      <div className="mt-4">
        <p className="mb-2 text-xs">
          This content is for informational purposes only and is not legal, tax,
          investment, financial, or other advice. You should not take, or
          refrain from taking, any action based on any information contained
          herein, or any other information that we make available at any time,
          including blog posts, data, articles, links to third-party content,
          discord content, news feeds, tutorials, tweets, and videos. Before you
          make any financial, legal, technical, or other decisions, you should
          seek independent professional advice from a licensed and qualified
          individual in the area for which such advice would be appropriate.
          This information is not intended to be comprehensive or address all
          aspects of Index or its products. There is additional documentation on
          the Kapital DAO's website about its ecosystem and community.
        </p>
        <p className="text-xs">
          You shall not purchase or otherwise acquire any token related to the
          Kapital DAO if you are: a citizen, resident (tax or otherwise), green
          card holder, incorporated in, owned or controlled by a person or
          entity in, located in, or have a registered office or principal place
          of business in the U.S. (a “U.S. Person”), or if you are a person in
          any jurisdiction in which such offer, sale, and/or purchase of any of
          our token products is unlawful, prohibited, or unauthorized (together
          with U.S. Person, a “Restricted Person”). The term “Restricted Person”
          includes, but is not limited to, any natural person residing in, or
          any firm, company, partnership, trust, corporation, entity,
          government, state or agency of a state, or any other incorporated or
          unincorporated body or association, association or partnership
          (whether or not having separate legal personality) that is established
          and/or lawfully existing under the laws of, a jurisdiction in which
          such offer, sale, and/or purchase of any of our token products is
          unlawful, prohibited, or unauthorized). You shall not resell or
          otherwise transfer any of our token products to any Restricted Person.
          The transfer or resale of any of our token products to any Restricted
          Person is not permitted. You shall read the Terms of Service and use
          our Website in compliance with the Terms of Service.
        </p>
      </div>
    </footer>
  )
}
