interface Props {
  isPending: boolean
}

export const Circle = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { isPending } = props

  return (
    <div className="relative flex justify-center items-center rounded-full bg-theme-containerBg border border-theme-muted h-9 w-9">
      {!isPending && (
        <>
          <div className="flex justify-center items-center bg-theme-highlighted opacity-50 h-9 w-9 rounded-full shadow-md" />
          <div className="absolute flex justify-center font-bold items-center text-theme-bg bg-theme-highlighted h-6 w-6 rounded-full">
            &#10003;
          </div>
        </>
      )}
    </div>
  )
}
