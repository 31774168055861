interface Props {
  type?: 'default' | 'highlight' | 'none'
  children: string | number
}

const styles = {
  default: 'bg-theme-bg text-theme-base',
  highlight: 'bg-theme-highlighted text-theme-base',
  none: 'text-theme-base',
}

export const Tag = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { type = 'default', children } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <span className={`inline-block py-2 bggray px-1 rounded ${styles[type]}`}>
      {children}
    </span>
  )
}
