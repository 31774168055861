import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  type?: 'base' | 'highlighted' | 'punched' | 'muted'
  className?: string
}

export const DividerText = (props: Props) => {
  const { children, type = 'base', className } = props

  return (
    <div className={`flex items-center w-full gap-4 ${className}`}>
      <div className={`border-b border-theme-${type} h-0 grow opacity-25`} />
      <span className={`text-theme-${type}`}>{children}</span>
      <div className={`border-b border-theme-${type} h-0 grow opacity-25`} />
    </div>
  )
}
