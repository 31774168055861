import type { SearchConfig } from '@pgl-apps/shared/types'

import { DropdownOption, DropdownWrapper } from '../../utility'
import { Suggestion } from '../../utility/dropdown/private/Suggestion'
import { Search } from '../Search/Search'

// TODO loading state
// This component assumes Search is aligned right, but we could make that configurable if needed
export const SearchWithSuggestions = (props: SearchConfig) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onChange,
    value,
    suggestionArr = [],
    onSuffixChange = () => null,
    suffixValue,
    suffixOpts = [],
    placeholder = 'Search',
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <DropdownWrapper
      trigger={
        <Search
          onChange={onChange}
          value={value}
          onSuffixChange={onSuffixChange}
          suffixValue={suffixValue}
          suffixOpts={suffixOpts}
          placeholder={placeholder}
        />
      }
      isDown={!!value}
    >
      {suggestionArr.map((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase()) ? (
          <DropdownOption key={suggestion} onClick={() => onChange(suggestion)}>
            <Suggestion searchValue={value} suggestion={suggestion} />
          </DropdownOption>
        ) : null
      )}
    </DropdownWrapper>
  )
}
