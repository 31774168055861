import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { ReactNode } from 'react'

import { SpinnerOverlay } from '../../decorative'
import { ContentBox } from '../../region'
import { Status } from '../../typography'
import { buildErrorMessage } from './private/buildErrorMessage'

interface Props {
  isFetching?: boolean
  error?: FetchBaseQueryError | SerializedError
  customErrorMsg?: string // if string provided, it will show the message
  putInBox?: boolean
  children: ReactNode
}

export const RTKStatusController = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    isFetching = false,
    error = null,
    customErrorMsg = '',
    putInBox = false,
    children,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const errorMessage = buildErrorMessage(error, customErrorMsg)
  const Wrapper = putInBox ? ContentBox : 'div'
  return !isFetching && !errorMessage ? (
    <div>{children}</div>
  ) : (
    <Wrapper>
      {isFetching && <SpinnerOverlay type="relative" />}
      {errorMessage && <Status type="error" label={errorMessage} />}
    </Wrapper>
  )
}
