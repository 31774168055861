import type { ChangeEvent } from 'react'

import type { BaseInputTypes, InputConfig, SVGR } from '@pgl-apps/shared/types'

interface Props {
  inputConfig: InputConfig
  required?: boolean
  suffix?: string
  hasSubmitErrors?: boolean
  type?: BaseInputTypes
  placeholder?: string
  className?: string
  Icon?: SVGR
  isSuccess?: boolean
  isHighlighted?: boolean
  isError?: boolean
  isDisabled?: boolean
  onChange: (arg0: string) => void // should be useCallback
  onFocus?: () => void
  onBlur?: (e) => void
}

export const InputBase = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    onChange,
    type = 'text',
    placeholder = '',
    Icon = null,
    className = '',
    onFocus = () => null,
    onBlur = () => null,
  } = props

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value)
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  // TODO error, success and valid styles should be moved to _twLayers.scss
  const isDisabled =
    inputConfig.isDisabled || // depricate
    inputConfig.state === 'disabled' ||
    inputConfig.state === 'loading'

  const errorClass =
    inputConfig.isError || inputConfig.state === 'error'
      ? '!border-theme-error'
      : ''
  const successClass =
    inputConfig.isSuccess || inputConfig.state === 'success'
      ? '!border-theme-success'
      : ''
  const highlightClass =
    inputConfig.isHighlighted || inputConfig.state === 'highlighted'
      ? '!border-theme-highlighted'
      : ''
  const iconClass = Icon ? 'pl-8' : '' // w-8
  return (
    <span className="relative">
      <input
        className={`w-full ${errorClass} ${successClass} ${highlightClass} ${iconClass} ${className}`}
        value={inputConfig.value}
        type={type}
        placeholder={placeholder}
        required={inputConfig.isRequired}
        aria-invalid={inputConfig.isError}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isDisabled}
      />
      {Icon && (
        <Icon className="absolute top-0 flex items-center left-0 bottom-0 w-8 p-1" />
      )}
    </span>
  )
}
