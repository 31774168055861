import { gsap } from 'gsap'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTransactions } from '@pgl-apps/kap-stake/hooks'
import { ReactComponent as Close } from '@pgl-apps/shared-images/svgr/icons/footer-close.svg'
import { Heading, SrOnly, TxLink } from '@pgl-apps/shared/components'
import { useMountEffect } from '@pgl-apps/shared/hooks'

import styles from './index.module.scss'
import { Coin } from './private/Coin'

// --------------------- ===
//  ANIMATION CONSTANTS
// ---------------------
const animationDuration = 0.42 // seconds
const inParams = {
  duration: animationDuration,
  yPercent: 0,
}
const outParams = {
  duration: animationDuration,
  yPercent: 100,
}

export const TransactionFooter = () => {
  // --------------------- ===
  //  TRANSACTIONS
  // ---------------------
  const { latestTransaction, transactions } = useTransactions()

  // const isVisible = useMemo(() => transactions.list.length > 0, [transactions])

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isActive, setIsActive] = useState(true)

  // --------------------- ===
  //  REFS
  // ---------------------
  const tweenRef = useRef<GSAPAnimation>()
  const modalRef = useRef<HTMLDivElement>()

  // --------------------- ===
  //  ANIMATION
  // ---------------------
  const showFooter = useCallback(() => {
    gsap.set(modalRef.current, {
      display: 'flex',
    })
    tweenRef.current = gsap.to(modalRef.current, {
      ...inParams,
    })
  }, [])

  const hideFooter = useCallback(() => {
    tweenRef.current = gsap.to(modalRef.current, {
      ...outParams,
    })
  }, [])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    // set start
    if (!isActive) {
      gsap.set(modalRef.current, {
        ...outParams,
      })
    }
  })

  useEffect(() => {
    if (isActive) {
      showFooter()
    } else {
      hideFooter()
    }
  }, [hideFooter, isActive, showFooter])

  useEffect(() => {
    // If new transactions, open the modal
    setIsActive(true)
  }, [transactions.list.length])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const successTransactions = transactions.list.filter(
    (txn) => txn.status === 'complete'
  ) // just 'complete'
  const failedTransactions = transactions.list.filter(
    (txn) => txn.status === 'failed'
  ) // just 'failed'
  const pendingTransaction = transactions.list.filter(
    (txn) => txn.status === 'pending'
  ) // just 'pending'
  const finishedTransactionsLength =
    successTransactions.length + failedTransactions.length // 'complete' or 'failed'

  // should animation run
  const areAllTransactionsComplete =
    finishedTransactionsLength > 0 &&
    finishedTransactionsLength === transactions.list.length &&
    latestTransaction.status === 'complete'

  const isApprovalStep = latestTransaction?.message.includes('Approval') // could be better
  const isFailed = latestTransaction?.status === 'failed'

  const borderClass = isFailed
    ? 'border-theme-error'
    : 'border-theme-highlighted'

  return (
    <div
      ref={modalRef}
      className={`fixed bottom-0 left-1/2 -translate-x-1/2 max-w-7xl bg-theme-containerBg border ${borderClass} shadow-gray-500 text-kap-white shadow-lg w-10/12 z-header`}
      role={isActive ? 'dialog' : 'presentation'}
      aria-modal={isActive}
    >
      <div className="flex gap-8 items-center w-full p-6">
        <div className="w-28">
          <Coin
            startAnimation={areAllTransactionsComplete && !isApprovalStep}
            isError={isFailed}
          />
        </div>
        <div className="grow">
          <Heading tag="h4" type="section">
            {latestTransaction?.message}
          </Heading>
          {latestTransaction?.txHash && (
            <TxLink txHash={latestTransaction.txHash} />
          )}
          {pendingTransaction.length > 1 && (
            <>
              <p className="mt-2">You have multiple transactions pending.</p>
              <p className="font-semibold">
                Do not use the back button or reload this page.
              </p>
            </>
          )}
        </div>
        <div>
          <p>
            <span
              className={`text-4xl ${
                isFailed ? 'text-theme-error' : 'text-theme-highlighted'
              }`}
            >
              {finishedTransactionsLength}
            </span>
            &nbsp;
            <span>of {transactions.list.length} Complete</span>
          </p>
          {failedTransactions.length > 0 && (
            <p className="text-xs text-theme-muted">{`(${successTransactions.length}) Succeeded, (${failedTransactions.length}) Failed`}</p>
          )}
        </div>
      </div>
      <button
        onClick={() => setIsActive((prev) => !prev)}
        className="w-8 absolute left-0 -top-10 bg-theme-containerBg p-2 hover:scale-105 transition-transform"
      >
        <Close
          className={`fill-white ${styles.close} ${
            isActive ? '' : styles.close__inactive
          }`}
        />
        <SrOnly label="Close Modal" />
      </button>
    </div>
  )
}
