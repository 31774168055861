import { useEffect } from 'react'
import type { ReactNode } from 'react'

import type {
  CtaProps,
  FilterConfig,
  SearchConfig,
  SelectedActions,
} from '@pgl-apps/shared/types'

import { CtaBtn } from '../../../buttons'
import { SearchWithSuggestions } from '../../../form'
import { Filter } from '../../../table/TableWrapper/public/Filter/Filter'
import { PrimaryBox } from '../PrimaryBox/PrimaryBox'

interface Props {
  children: ReactNode
  label: string
  onDeselect: () => void
  selectedRows?: unknown[]
  selectActions?: SelectedActions
  searchConfig?: SearchConfig
  filterConfig?: FilterConfig
  headerCtas?: CtaProps[]
  hideBoxContainer?: boolean
  totalCount?: number
  currentCount?: number
}

// for the zendesk global object
declare global {
  const zE: any
}

export const TableBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    label,
    onDeselect,
    selectedRows = [],
    selectActions = [],
    searchConfig = null,
    filterConfig = null,
    headerCtas = null,
    hideBoxContainer = false,
    totalCount,
    currentCount,
  } = props

  // -------------------- ===
  // RENDER
  // --------------------
  const isSomeSelected = selectedRows.length > 0
  const boxClass = hideBoxContainer ? '' : 'p-4'

  useEffect(() => {
    try {
      // based on the zendesk docs https://developer.zendesk.com/api-reference/widget/api/#zehide
      if (zE && isSomeSelected) {
        zE.hide()
      } else if (zE && !isSomeSelected) {
        zE.show()
      }
      return () => zE.show()
    } catch (err) {
      // suppress the error message for now
      return undefined
    }
  }, [isSomeSelected])

  return (
    <PrimaryBox
      label={label}
      className={`relative w-full overflow-visible flex flex-col max-h-screen text-sm bpTable:ml-0 ${boxClass}`}
    >
      {headerCtas && (
        <div className="flex flex-col sm:flex-row justify-end mb-4 gap-4">
          {headerCtas.map((cta, i) => (
            <div className={`sm:order-${headerCtas.length - i}`} key={i}>
              <CtaBtn
                onClick={cta.onClick}
                type={cta.type}
                state={cta.state}
                isFullWidth={cta.isFullWidth}
              >
                {cta.label}
              </CtaBtn>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-wrap md:flex-nowrap justify-between items-center mb-4 gap-4">
        <div className="flex-shrink-0 basis-full md:flex-1">
          {filterConfig && <Filter filterConfig={filterConfig} />}
        </div>
        {searchConfig && (
          // TODO suggestions
          <div className="flex-shrink-0 flex-grow-0 flex">
            <SearchWithSuggestions
              onChange={searchConfig.onChange}
              value={searchConfig.value}
              suggestionArr={searchConfig.suggestionArr || []}
              onSuffixChange={searchConfig.onSuffixChange}
              suffixOpts={searchConfig.suffixOpts}
              suffixValue={searchConfig.suffixValue}
              placeholder={searchConfig.placeholder}
            />
          </div>
        )}
      </div>
      {children}
      {isSomeSelected && (
        <div className="sticky bottom-0 left-0 right-0 -ml-4 -mr-4 -mb-4 bg-theme-containerBg border border-theme-punched rounded-b-lg flex items-center justify-between p-4">
          <div className="flex gap-4">
            {selectActions.map((act) => (
              <CtaBtn
                key={act.label}
                onClick={() => act.callback(selectedRows)}
              >
                {act.label}
              </CtaBtn>
            ))}
          </div>
          <div>
            <CtaBtn type="tertiary" onClick={() => onDeselect()}>
              Deselect
            </CtaBtn>
          </div>
        </div>
      )}
      {totalCount ? (
        <p className="text-right text-theme-muted text-sm">
          {currentCount} of {totalCount}
        </p>
      ) : null}
    </PrimaryBox>
  )
}
