import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { notifications, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import type {
  ClearNotificationArg,
  NotificationArg,
} from '@pgl-apps/shared/types'

// Convenience hook
export const useNotifications = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const notificationsList = useSelector(
    (store: IRootState) => store.notifications.notifications
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const { actions } = notifications
  const addNotification = (arg: NotificationArg) => {
    dispatch(actions.addNotification(arg))
  }
  const clearNotification = (id: ClearNotificationArg['id']) => {
    dispatch(actions.clearNotification({ id }))
  }

  return {
    addNotification,
    clearNotification,
    notificationsList,
  }
}
