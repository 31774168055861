import { useEffect, useState } from 'react'

import type { ChartValues } from '@pgl-apps/shared/types'

import { PrimaryBox } from '../../../index'
import { Loading } from '../../messaging/Loading/Loading'
import { BarGraph } from '../BarGraph/BarGraph'
import { LineGraph } from '../LineGraph/LineGraph'
import { colors } from '../colors'
import type { GraphData } from '../types'
import { FilterTag } from './private/FilterTag'

interface Props {
  type: 'bar' | 'line'
  title?: string
  values: ChartValues[]
  showTags?: boolean
  alwaysOneActiveTag?: boolean
  loading: boolean
  className?: string
}

const typeGraphMappings = {
  bar: BarGraph,
  line: LineGraph,
}

const buildGraphKeyData = (
  values: ChartValues[],
  alwaysOneActiveTag: boolean
) => {
  // Parse values for the keys in each "values" and assign each a color
  // and build tags
  const graphKeyData = {}

  if (values) {
    values.forEach((d) => {
      d.values.forEach((v, i) => {
        if (!Object.keys(graphKeyData).includes(v.key)) {
          // get next color in the list or start back at the beginning if exceeding the number of colors
          const color = colors[Object.keys(graphKeyData).length % colors.length]
          graphKeyData[v.key] = {
            key: v.key,
            color,
            isActive: alwaysOneActiveTag ? i === 0 : true,
          }
        }
      })
    })
  }
  return graphKeyData
}

const valuesCheck = (values: ChartValues[]) => {
  if (values.length === 0) return false
  if (
    values.every((val) =>
      // @ts-expect-error I'm seeing strings in some values, but fixing the type breaks other components
      // not sure what's correct
      val.values.every((v) => v.value === 0 || v.value === '0')
    )
  )
    return false
  return true
}

export const GraphWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    type,
    title,
    values,
    showTags = false,
    alwaysOneActiveTag = false,
    loading,
    className = '',
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [graphKeyData, setGraphKeyData] = useState<Record<string, GraphData>>(
    {}
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleTagClick = (key: string) => {
    if (alwaysOneActiveTag) {
      const keyData = {}
      Object.keys(graphKeyData).forEach((x) => {
        keyData[x] = { ...graphKeyData[x], isActive: x === key }
      })
      setGraphKeyData(keyData)
    } else {
      let activeCount = 0
      Object.keys(graphKeyData).forEach((key) => {
        if (graphKeyData[key].isActive) {
          activeCount += 1
        }
      })
      // prevent de-selecting the item if it is the only one selected
      if (activeCount > 1 || !graphKeyData[key].isActive) {
        setGraphKeyData((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            isActive: !prev[key].isActive,
          },
        }))
      }
    }
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const gd = buildGraphKeyData(values, alwaysOneActiveTag)
    setGraphKeyData(gd)
  }, [values, alwaysOneActiveTag])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const GraphComponent = typeGraphMappings[type]
  const hasValues = valuesCheck(values)

  return (
    <PrimaryBox label={title}>
      {loading ? (
        <div className={`flex justify-center items-center ${className}`}>
          <Loading type="ThreeDots" height={40} width={40} />
        </div>
      ) : hasValues ? (
        <>
          <div className={`${className}`}>
            <GraphComponent values={values} graphKeyData={graphKeyData} />
          </div>
          {showTags && (
            <div className="flex w-full h-[50px] overflow-auto">
              <div className="flex flex-wrap items-center gap-x-1 gap-y-1 w-full">
                {Object.keys(graphKeyData).map((key) => (
                  <FilterTag
                    key={key}
                    color={graphKeyData[key].color}
                    label={key}
                    isActive={graphKeyData[key].isActive}
                    onClick={() => handleTagClick(key)}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={`flex justify-center items-center ${className}`}>
          No values
        </div>
      )}
    </PrimaryBox>
  )
}
