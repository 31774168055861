import background from '@pgl-apps/shared-images/backgrounds/adCardBg.png'

import { ContentBox } from '../../../region/ContentBox/ContentBox'

const bgStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'no-repeat',
}

export const KapDaoAd = () => {
  return (
    <ContentBox className="flex flex-col w-[275px] h-[400px] relative overflow-hidden">
      <div className="w-full h-full rounded-lg p-0.5 bg-gradient-to-bl from-[#C20071] to-transparent via-[#eba9f113]">
        <div style={bgStyle} className="bg-cover flex-row h-full rounded-lg">
          <div className="pt-14 pl-4 font-extrabold text-4xl space-y-3 h-3/4">
            <div>Play</div>
            <div>Earn</div>
            <div>Evolve</div>
          </div>
          <div className="h-1/4 flex items-end">
            <a
              className="font-semibold py-3 px-5 m-5 rounded-full bg-gradient-to-br from-[#eba9f1] to-[#db0078] to-[#C20071]`"
              href="https://kapital.gg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Start Now
            </a>
          </div>
        </div>
      </div>
    </ContentBox>
  )
}
