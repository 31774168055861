import { forwardRef } from 'react'

import { ReactComponent as Close } from '@pgl-apps/shared-images/svgr/icons/close-stroke.svg'
import { modalId } from '@pgl-apps/shared/helpers'

import { SrOnly } from '../../../../typography'
import type { PrivateModalProps } from '../types'

export const leftModalInParams = {
  xPercent: 0,
}
export const leftModalOutParams = {
  xPercent: -100,
}

export const LeftModal = forwardRef<HTMLDivElement, PrivateModalProps>(
  (props, contentRef) => {
    // --------------------- ===
    //  PROPS
    // ---------------------
    const { onCloseModal, children } = props

    // --------------------- ===r
    //  RENDER
    // ---------------------
    return (
      <div
        ref={contentRef}
        className="absolute top-0 bottom-0 left-0 w-[40rem] max-w-full bg-theme-containerBg p-16 text-theme-base"
        id={modalId} // Don't remove - used by modalTrap
      >
        {children}
        <button
          onClick={onCloseModal}
          id="modalCloseBtn" // Don't remove - used by modal handling
          type="button"
          className="w-16 p-6 absolute top-0 right-0"
        >
          <Close />
          <SrOnly label="Close Modal" />
        </button>
      </div>
    )
  }
)
