import type { BarsProps } from '../types'
import { Bar } from './Bar'

export const BarsBuilder = (props: BarsProps) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    values,
    height,
    scaleX,
    subscaleX,
    scaleY,
    barTransform,
    graphKeyData,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <>
      {values.map(({ label, values }, groupIndex) => (
        <g
          key={`rect-group-${groupIndex}`}
          transform={`translate(${scaleX(label)}, 0)`}
        >
          {values.map(({ value, key }, barIndex) => {
            // Show only if bar chart visibility is set to true.
            if (graphKeyData[key].isActive) {
              return (
                <Bar
                  key={`rect-${barIndex}`}
                  x={subscaleX(key) || 0}
                  y={scaleY(value)}
                  width={subscaleX.bandwidth()}
                  height={height - scaleY(value) || 1} // min height of 1
                  color={graphKeyData[key].color}
                  transform={barTransform}
                />
              )
            }
            return null
          })}
        </g>
      ))}
    </>
  )
}
