import { chamfer } from '../styles'
import type { PrimaryBtnLink } from '../types'

export const Inner = (props: Partial<PrimaryBtnLink>) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, leftIcon, rightIcon, srOnly } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <>
      <span style={chamfer} className="inner absolute inset-px -z-[1]" />
      {leftIcon && <span className="icon">{leftIcon}</span>}
      {label && <span>{label}</span>}
      {rightIcon && <span className="icon">{rightIcon}</span>}
      {srOnly && (
        <span className="sr-only" style={{}}>
          {srOnly}
        </span>
      )}
    </>
  )
}
