import { Suspense, lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import { MainLayout } from '@pgl-apps/kap-stake/components'
import { routePaths } from '@pgl-apps/kap-stake/constants'

const Home = lazy(() =>
  import('@pgl-apps/kap-stake/pages').then((module) => ({
    default: module.Home,
  }))
)
const Vesting = lazy(() =>
  import('@pgl-apps/kap-stake/pages').then((module) => ({
    default: module.Vesting,
  }))
)

const fallback = (
  <div style={{ textAlign: 'center', fontWeight: 800 }}>loading...</div>
)

// --------------------- ===
//  ROUTE PATHS
// ---------------------
export const routeConfig: RouteObject[] = [
  {
    path: routePaths.home.path,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={fallback}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: routePaths.vesting.path,
        element: (
          <Suspense fallback={fallback}>
            <Vesting />
          </Suspense>
        ),
      },
    ],
  },
]
