import type { ReactNode } from 'react'

import { DropdownBucket } from '../private/DropdownBucket'

interface Props {
  children: ReactNode[] // trigger for dropdown
  trigger: ReactNode
  className?: string
  isDown: boolean
}

export const DropdownWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, trigger, className = 'flex justify-end', isDown } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  let hasChildren = false
  children.forEach((child) => {
    if (child) hasChildren = true
  })

  return (
    <div className={className}>
      <div className="relative">
        {trigger}
        {isDown && (
          <div className="absolute top-full mt-2 left-0 right-0 z-lift">
            {hasChildren && (
              <DropdownBucket showDivider={false}>{children}</DropdownBucket>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
