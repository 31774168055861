import type { BarProps } from '../types'

export const Bar = (props: BarProps) => {
  const { x, y, width, height, color, transform } = props
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      transform={transform}
    />
  )
}
