import { useEffect } from 'react'

import type { BaseModalProps } from '@pgl-apps/shared/types'

import { Loading } from '../../messaging'
import { PromptModal } from './PromptModal'

interface Props extends BaseModalProps {
  handleConfirmation: () => void
  isLoading?: boolean
  isSuccess?: boolean
  title: string
  content: string
  confirmationLabel: string
  loadingLabel?: string
}

export const ConfirmationModal = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    handleConfirmation,
    isLoading,
    isSuccess,
    title,
    content,
    confirmationLabel,
    loadingLabel,
    onCloseModal,
  } = props

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    if (isSuccess) {
      onCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]) // only isSuccess

  return (
    <PromptModal
      label={title}
      confirmConfig={{
        onClick: handleConfirmation,
        label: confirmationLabel,
        state: isLoading || isSuccess ? 'disabled' : 'default',
      }}
      cancelConfig={{
        onClick: onCloseModal,
        label: 'Cancel',
      }}
    >
      {isLoading ? (
        <div className="flex flex-col gap-1 justify-center items-center h-full">
          <div>{loadingLabel}</div>
          <Loading type="ThreeDots" height={40} width={40} />
        </div>
      ) : (
        <div>{content}</div>
      )}
    </PromptModal>
  )
}
