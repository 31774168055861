export interface ChildComponentCheck {
  [key: string]: boolean
}

export type SelectedRow = number | string
export type SelectedRows = SelectedRow[]

export interface SelectAction {
  label: string
  callback: (selectedRows: unknown) => void
}
export type SelectedActions = SelectAction[]

export type SortOpts = 'asc' | 'desc'

export interface Sort {
  label: string
  type: SortOpts
}

export type TableId = number | string

export type TableCellWidth = 1 | 2 | 3 | 4 | 5

export interface TableCell {
  label: string
  width: TableCellWidth
  sortLabel?: string // replaces isSortable
}

export interface TableColumnDefaults {
  [key: string]: TableCell
}

export type TableColumns = TableCell[]
