import dinomd from '@pgl-apps/shared-images/decoration/dino@0.5x.png'
import dinosm from '@pgl-apps/shared-images/decoration/dino@0.25x.png'
import dinolg from '@pgl-apps/shared-images/decoration/dino@1x.png'
import dinotiny from '@pgl-apps/shared-images/decoration/dino@tiny.png'
import bronze1md from '@pgl-apps/shared-images/ranks/bronze/1@0.5x.png'
import bronze1sm from '@pgl-apps/shared-images/ranks/bronze/1@0.25x.png'
import bronze1lg from '@pgl-apps/shared-images/ranks/bronze/1@1x.png'
import bronze1tiny from '@pgl-apps/shared-images/ranks/bronze/1@tiny.png'
import diamond1tiny from '@pgl-apps/shared-images/ranks/bronze/1@tiny.png'
import gold1tiny from '@pgl-apps/shared-images/ranks/bronze/1@tiny.png'
import bronze2md from '@pgl-apps/shared-images/ranks/bronze/2@0.5x.png'
import bronze2sm from '@pgl-apps/shared-images/ranks/bronze/2@0.25x.png'
import bronze2lg from '@pgl-apps/shared-images/ranks/bronze/2@1x.png'
import bronze2tiny from '@pgl-apps/shared-images/ranks/bronze/2@tiny.png'
import diamond2tiny from '@pgl-apps/shared-images/ranks/bronze/2@tiny.png'
import gold2tiny from '@pgl-apps/shared-images/ranks/bronze/2@tiny.png'
import bronze3md from '@pgl-apps/shared-images/ranks/bronze/3@0.5x.png'
import bronze3sm from '@pgl-apps/shared-images/ranks/bronze/3@0.25x.png'
import bronze3lg from '@pgl-apps/shared-images/ranks/bronze/3@1x.png'
import bronze3tiny from '@pgl-apps/shared-images/ranks/bronze/3@tiny.png'
import diamond3tiny from '@pgl-apps/shared-images/ranks/bronze/3@tiny.png'
import gold3tiny from '@pgl-apps/shared-images/ranks/bronze/3@tiny.png'
import diamond1md from '@pgl-apps/shared-images/ranks/diamond/1@0.5x.png'
import diamond1sm from '@pgl-apps/shared-images/ranks/diamond/1@0.25x.png'
import diamond1lg from '@pgl-apps/shared-images/ranks/diamond/1@1x.png'
import diamond2md from '@pgl-apps/shared-images/ranks/diamond/2@0.5x.png'
import diamond2sm from '@pgl-apps/shared-images/ranks/diamond/2@0.25x.png'
import diamond2lg from '@pgl-apps/shared-images/ranks/diamond/2@1x.png'
import diamond3md from '@pgl-apps/shared-images/ranks/diamond/3@0.5x.png'
import diamond3sm from '@pgl-apps/shared-images/ranks/diamond/3@0.25x.png'
import diamond3lg from '@pgl-apps/shared-images/ranks/diamond/3@1x.png'
import gold1md from '@pgl-apps/shared-images/ranks/gold/1@0.5x.png'
import gold1sm from '@pgl-apps/shared-images/ranks/gold/1@0.25x.png'
import gold1lg from '@pgl-apps/shared-images/ranks/gold/1@1x.png'
import gold2md from '@pgl-apps/shared-images/ranks/gold/2@0.5x.png'
import gold2sm from '@pgl-apps/shared-images/ranks/gold/2@0.25x.png'
import gold2lg from '@pgl-apps/shared-images/ranks/gold/2@1x.png'
import gold3md from '@pgl-apps/shared-images/ranks/gold/3@0.5x.png'
import gold3sm from '@pgl-apps/shared-images/ranks/gold/3@0.25x.png'
import gold3lg from '@pgl-apps/shared-images/ranks/gold/3@1x.png'
import masters1md from '@pgl-apps/shared-images/ranks/masters/1@0.5x.png'
import masters1sm from '@pgl-apps/shared-images/ranks/masters/1@0.25x.png'
import masters1lg from '@pgl-apps/shared-images/ranks/masters/1@1x.png'
import masters1tiny from '@pgl-apps/shared-images/ranks/masters/1@tiny.png'
import masters2md from '@pgl-apps/shared-images/ranks/masters/2@0.5x.png'
import masters2sm from '@pgl-apps/shared-images/ranks/masters/2@0.25x.png'
import masters2lg from '@pgl-apps/shared-images/ranks/masters/2@1x.png'
import masters2tiny from '@pgl-apps/shared-images/ranks/masters/2@tiny.png'
import masters3md from '@pgl-apps/shared-images/ranks/masters/3@0.5x.png'
import masters3sm from '@pgl-apps/shared-images/ranks/masters/3@0.25x.png'
import masters3lg from '@pgl-apps/shared-images/ranks/masters/3@1x.png'
import masters3tiny from '@pgl-apps/shared-images/ranks/masters/3@tiny.png'
import platinum1md from '@pgl-apps/shared-images/ranks/platinum/1@0.5x.png'
import platinum1sm from '@pgl-apps/shared-images/ranks/platinum/1@0.25x.png'
import platinum1lg from '@pgl-apps/shared-images/ranks/platinum/1@1x.png'
import platinum1tiny from '@pgl-apps/shared-images/ranks/platinum/1@tiny.png'
import platinum2md from '@pgl-apps/shared-images/ranks/platinum/2@0.5x.png'
import platinum2sm from '@pgl-apps/shared-images/ranks/platinum/2@0.25x.png'
import platinum2lg from '@pgl-apps/shared-images/ranks/platinum/2@1x.png'
import platinum2tiny from '@pgl-apps/shared-images/ranks/platinum/2@tiny.png'
import platinum3md from '@pgl-apps/shared-images/ranks/platinum/3@0.5x.png'
import platinum3sm from '@pgl-apps/shared-images/ranks/platinum/3@0.25x.png'
import platinum3lg from '@pgl-apps/shared-images/ranks/platinum/3@1x.png'
import platinum3tiny from '@pgl-apps/shared-images/ranks/platinum/3@tiny.png'
import silver1md from '@pgl-apps/shared-images/ranks/silver/1@0.5x.png'
import silver1sm from '@pgl-apps/shared-images/ranks/silver/1@0.25x.png'
import silver1lg from '@pgl-apps/shared-images/ranks/silver/1@1x.png'
import silver1tiny from '@pgl-apps/shared-images/ranks/silver/1@tiny.png'
import silver2md from '@pgl-apps/shared-images/ranks/silver/2@0.5x.png'
import silver2sm from '@pgl-apps/shared-images/ranks/silver/2@0.25x.png'
import silver2lg from '@pgl-apps/shared-images/ranks/silver/2@1x.png'
import silver2tiny from '@pgl-apps/shared-images/ranks/silver/2@tiny.png'
import silver3md from '@pgl-apps/shared-images/ranks/silver/3@0.5x.png'
import silver3sm from '@pgl-apps/shared-images/ranks/silver/3@0.25x.png'
import silver3lg from '@pgl-apps/shared-images/ranks/silver/3@1x.png'
import silver3tiny from '@pgl-apps/shared-images/ranks/silver/3@tiny.png'
import type { PlayerRanks } from '@pgl-apps/shared/types'

import { ProgressiveImgLoader } from '../ProgressiveImgLoader/ProgressiveImgLoader'

const allImgs = {
  //Dino
  dinolg,
  dinomd,
  dinosm,
  dinotiny,
  // Bronze
  bronze1tiny,
  bronze1md,
  bronze1sm,
  bronze1lg,
  bronze2tiny,
  bronze2md,
  bronze2sm,
  bronze2lg,
  bronze3tiny,
  bronze3md,
  bronze3sm,
  bronze3lg,
  // Diamond
  diamond1tiny,
  diamond1md,
  diamond1sm,
  diamond1lg,
  diamond2md,
  diamond2tiny,
  diamond2sm,
  diamond2lg,
  diamond3md,
  diamond3tiny,
  diamond3sm,
  diamond3lg,
  // Gold
  gold1tiny,
  gold1md,
  gold1sm,
  gold1lg,
  gold2tiny,
  gold2md,
  gold2sm,
  gold2lg,
  gold3tiny,
  gold3md,
  gold3sm,
  gold3lg,
  // Masters
  masters1tiny,
  masters1md,
  masters1sm,
  masters1lg,
  masters2tiny,
  masters2md,
  masters2sm,
  masters2lg,
  masters3tiny,
  masters3md,
  masters3sm,
  masters3lg,
  // Platinum
  platinum1tiny,
  platinum1md,
  platinum1sm,
  platinum1lg,
  platinum2tiny,
  platinum2md,
  platinum2sm,
  platinum2lg,
  platinum3tiny,
  platinum3md,
  platinum3sm,
  platinum3lg,
  // Silver
  silver1tiny,
  silver1md,
  silver1sm,
  silver1lg,
  silver2tiny,
  silver2md,
  silver2sm,
  silver2lg,
  silver3tiny,
  silver3md,
  silver3sm,
  silver3lg,
}

interface Props {
  rank: PlayerRanks
  level: '' | 1 | 2 | 3
  size?: 'sm' | 'md' | 'lg' // 250 | 500 | 1000 px
}

export const RankImg = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { rank, level, size = 'sm' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="relative">
      <ProgressiveImgLoader
        placeholderSrc={allImgs[`${rank}${level}tiny`]}
        src={allImgs[`${rank}${level}${size}`]}
        className={`inset-0 w-full`}
        alt=""
      />
    </div>
  )
}
