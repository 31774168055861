import { Children, cloneElement } from 'react'
import type { ReactElement } from 'react'

import type { SelectedRows, TableId } from '@pgl-apps/shared/types'

import { ExpandCell } from '../../private/ExpandCell/ExpandCell'
import { SelectCell } from '../../private/SelectCell/SelectCell'
import styles from './index.module.scss'

interface Props {
  id: TableId
  expandedRow?: number | string
  selectedRows?: SelectedRows
  isHeader?: boolean
  // TODO with TS you can't check for specific components, but maybe
  // we could add a run time check. Maybe only in dev?
  children: ReactElement[] // ONLY Cell, ActionCell, &&|| ExpandBody
  // Below passed by TableWrapper - no need to manually pass
  hasAction?: boolean
  hasExpand?: boolean
  hasSelect?: boolean
  isAllSelected?: boolean
  isSomeSelected?: boolean
  isSelectDisabled?: boolean // is row disabled
  clientSort?: boolean
  onExpandClick?: () => void
  onSelectClick?: (id: number | string) => void
  colWidths?: number[]
}

export const Row = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    id,
    expandedRow = null,
    selectedRows = [],
    isHeader = false,
    children,
    hasAction = false,
    hasExpand = false,
    hasSelect = false,
    isAllSelected = false,
    isSomeSelected = false,
    isSelectDisabled = false,
    onExpandClick = (id: string | number) => null,
    onSelectClick = (id: string | number) => null,
    colWidths = [],
  } = props
  // const hasSelect = selectActions.length > 0

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isExpanded = id === expandedRow
  const isSelected = selectedRows.includes(id)

  const headerClass = isHeader ? styles.row__header : ''
  const actionClass = hasAction ? styles.row__action : ''
  const expandedClass = hasExpand ? styles.row__expand : ''
  const selectClass = hasSelect ? styles.row__select : ''

  const isExpandedClass = isExpanded ? styles.row__expanded : ''
  const isSelectedClass = isSelected ? styles.row__selected : ''

  let gridTemplateColumns = ''

  colWidths.forEach(
    (w) => (gridTemplateColumns = `${gridTemplateColumns} ${w}fr`)
  )

  return (
    <div
      className={`${styles.row} ${headerClass} ${actionClass} ${expandedClass} ${selectClass} ${isExpandedClass} ${isSelectedClass}`}
      role="row"
    >
      <div className={styles.inner} style={{ gridTemplateColumns }}>
        {hasSelect && (
          <SelectCell
            onSelectClick={() => {
              onSelectClick(id)
            }}
            isSelected={isSelected}
            isHeader={isHeader}
            isAllSelected={isAllSelected}
            isSomeSelected={isSomeSelected}
            isDisabled={isSelectDisabled}
          />
        )}
        {hasExpand && !isHeader && (
          <ExpandCell
            onExpandClick={() => onExpandClick(id)}
            isExpanded={isExpanded}
            hasAction={hasAction}
          />
        )}
        {children.length > 0 &&
          Children.map(children, (child) =>
            // Pass to Cell
            cloneElement(child, {
              // Available to all Cells, but only used in some cell types
              onExpandClick: () => onExpandClick(id),
              onSelectClick: () => onSelectClick(id),
              hasExpand,
              hasSelect,
              isExpanded,
              isSelected,
              isHeader,
              colCount: children.length,
            })
          )}
      </div>
    </div>
  )
}
