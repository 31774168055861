import {
  Children,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import type { PointerEventHandler, ReactNode } from 'react'

import { usePrevious } from '@pgl-apps/shared/hooks'

import styles from './index.module.scss'

interface Props {
  children: ReactNode
  isExpanded?: boolean
  cellTitle?: string
  isHeader?: boolean
  resizeCursor?: string
  onResize?: (changePx: number) => void
  colCount?: number
  className?: string
}

export const Cell = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    isExpanded,
    cellTitle = '',
    isHeader = false,
    resizeCursor = '',
    onResize = () => null,
    colCount = null,
    className = 'text-left',
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDown, setIsDown] = useState(false)

  // --------------------- ===
  //  REFS
  // ---------------------
  const title = useRef('')
  const pointerWasX = useRef(0)

  // --------------------- ===
  //  HELPERS
  // ---------------------
  // TODO should be stress tested
  const findTitle = useCallback(() => {
    // Reset
    title.current = ''
    // Cycle through children to find strings
    const findPrimitive = (c: ReactNode) => {
      Children.forEach(c, (child) => {
        if (!isValidElement(child)) {
          if (typeof child === 'string' || typeof child === 'number') {
            title.current = `${title.current}${child.toString()} `
          }
          return
        }

        if (child.props && child.props.children) {
          findPrimitive(child.props.children)
        }
      })
    }
    findPrimitive(children)
  }, [children])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handlePointerDown: PointerEventHandler<HTMLDivElement> = (evt) => {
    pointerWasX.current = evt.clientX
    setIsDown(true)
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  const wasDown = usePrevious(isDown)

  useEffect(() => {
    findTitle()
  }, [findTitle])

  useEffect(() => {
    const handleColResize = (evt: PointerEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      const dif = evt.clientX - pointerWasX.current
      onResize(dif)
      pointerWasX.current = evt.clientX
    }
    const handleUp = () => {
      setIsDown(false)
    }

    if (isDown) {
      document.addEventListener('pointermove', handleColResize)
      document.addEventListener('pointerup', handleUp)
    }
    return () => {
      document.removeEventListener('pointermove', handleColResize)
      document.removeEventListener('pointerup', handleUp)
    }
    // just isDown
  }, [isDown]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDown) {
      document.body.style.cursor = resizeCursor
    }

    if (!isDown && wasDown) {
      document.body.removeAttribute('style')
    }
  }, [isDown, wasDown, resizeCursor])

  // --------------------- ===
  //  RENDER
  // ---------------------
  const expandedClass = isExpanded ? styles.cell__expanded : ''
  const headerClass = isHeader ? styles.cell__header : ''

  return (
    <div
      className={`flex items-center px-2 py-1 overflow-hidden flex-grow flex-shrink-0 basis-0 min-w-[8rem] bpTable:min-w-[6rem] relative ${styles.cell} ${expandedClass} ${headerClass}`}
      title={cellTitle ? cellTitle : title.current}
      role={isHeader ? 'columnheader' : 'cell'}
    >
      <div
        className={`!overflow-hidden text-ellipsis !whitespace-nowrap w-full text-sm ${className}`}
      >
        {children}
      </div>
      {isHeader && colCount !== 1 && (
        <div
          className="absolute top-2 right-0 bottom-2 text-theme-muted hover:text-theme-punched"
          style={{ cursor: resizeCursor }}
          onPointerDown={handlePointerDown}
        >
          <div className="w-2 h-full mr-2 border-dashed border-r border-current" />
        </div>
      )}
    </div>
  )
}
