import type { ReactNode } from 'react'

import { ReactComponent as CheckSvg } from '@pgl-apps/shared-images/svgr/icons/check.svg'
import { ReactComponent as DashSvg } from '@pgl-apps/shared-images/svgr/icons/dash.svg'

interface Props {
  onClick: () => void
  state: 'checked' | 'unchecked' | 'somechecked'
  isSingleChoice?: boolean
  isDisabled?: boolean
  bgColorIfSelected?: string
  children?: string | ReactNode
}

export const Checkbox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onClick,
    state,
    isSingleChoice = false,
    isDisabled = false,
    children,
    bgColorIfSelected = '',
  } = props

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const getIcon = () => {
    switch (state) {
      case 'checked':
        return <CheckSvg />
      case 'somechecked':
        return <DashSvg />
      default:
        return null
    }
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  const radiusClass = isSingleChoice ? 'rounded-full' : 'rounded-sm'

  return (
    <button
      className={`text-theme-lightsaber group flex items-center text-left ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
    >
      <span
        className={`grow-0 shrink-0 ${children ? 'w-4 h-4' : 'w-8 h-8 p-2'}`}
      >
        <span
          className={`${
            state === 'checked' && bgColorIfSelected
          } w-full h-full border border-theme-muted group-hover:border-theme-highlighted p-0.5 flex items-center justify-center ${radiusClass} ${
            isDisabled ? 'bg-gray-300' : ''
          }`}
        >
          {getIcon()}
          <span className="sr-only">
            {state === 'unchecked' ? 'select item' : 'deselect item'}
          </span>
        </span>
      </span>
      {children && <span className="text-theme-base ml-2">{children}</span>}
    </button>
  )
}
