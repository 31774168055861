import type { ReactNode } from 'react'

import { ReactComponent as Copy } from '@pgl-apps/shared-images/svgr/icons/copy.svg'

interface Props {
  copyStr: string
  children?: ReactNode
  callback?: () => void
}

export const CopyBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    copyStr,
    children = null,
    callback = () => null, // useful for success message
  } = props

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleClick = () => {
    navigator.clipboard.writeText(copyStr)
    callback()
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      onClick={handleClick}
      className="flex gap-2 items-center hover:brightness-150"
    >
      {children && <span>{children}</span>}
      <Copy className="w-3" />
    </button>
  )
}
