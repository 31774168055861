import kapCoinSplit from '@pgl-apps/shared-images/logos/kap-coin-split.png'
import { Heading, Label, Spinner, Tag } from '@pgl-apps/shared/components'

export const Allowance = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------

  return (
    <div className="text-center my-12">
      <Heading type="page" tag="h2">
        Checking allowance
      </Heading>
      <div className="flex justify-center my-12">
        <img
          src={kapCoinSplit}
          alt={'Checking allowance'}
          className="w-auto h-48"
        />
      </div>
    </div>
  )
}
