import React from 'react'

interface Props {
  onFocus: () => void
}

export const ModalTrap = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onFocus } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className="w-0 h-0"
      onFocus={onFocus}
      tabIndex={0} // eslint-disable-line
    />
  )
}
