import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

import type { SpinnerStatuses } from '@pgl-apps/shared/types'

import styles from './index.module.scss'
import { Circle } from './private/Circle'

interface Props {
  status?: SpinnerStatuses
}

const animDur = 3

export const Spinner = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { status = 'pending' } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const spinnerRef = useRef<HTMLDivElement>(null)
  const tweens = useRef<GSAPAnimation[]>([])

  // --------------------- ===
  //  ANIMATION
  // ---------------------
  useEffect(() => {
    const children = Array.from(spinnerRef.current.children)

    // Kill existing tweens
    tweens.current.forEach((tween) => {
      tween.kill()
    })
    tweens.current = []

    // Setup new tween
    // complete || failed vars
    let duration = animDur
    let x = [0, 0]
    let y = [0, 0]
    let z = [0, 0]
    let repeat = 0
    let ease = 'sine.out'

    if (status === 'pending') {
      duration = animDur
      x = [180, 360]
      y = [360, 180]
      z = [180, 180]
      repeat = -1
      ease = 'sine.inOut'
    }

    children.forEach((disc, i) => {
      const rotateX = x[i]
      const rotateY = y[i]
      const rotateZ = z[i]
      const tween = gsap.to(disc, {
        duration,
        rotateX,
        rotateY,
        rotateZ,
        repeat,
        ease,
      })
      tweens.current.push(tween)
    })
  })

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className={`${styles.wrapper} ${styles[`wrapper__${status}`]}`}
      ref={spinnerRef}
    >
      <Circle />
      <Circle />
    </div>
  )
}
