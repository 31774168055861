import { createAction, createReducer } from '@reduxjs/toolkit'

import type { modalTypes } from '@pgl-apps/kap-stake/constants'
import { isActionType } from '@pgl-apps/shared/helpers'

type Type = typeof modalTypes[keyof typeof modalTypes]
type InitialState = {
  type: Type | null
  props: Record<string, any>
  isVisible: boolean
}

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'modal'
const initialState: InitialState = {
  type: null,
  props: {},
  isVisible: false,
}

// --------------------- ===
//  CONSTANTS
// ---------------------
const SHOW_MODAL = `${sliceName}/SHOW_MODAL`
const HIDE_MODAL = `${sliceName}/HIDE_MODAL`
const CLEAR_MODAL_TYPE = `${sliceName}/CLEAR_MODAL_TYPE`
const SET_MODAL_PROPS = `${sliceName}/SET_MODAL_PROPS`

// --------------------- ===
//  HELPERS
// ---------------------
const localShowModal = (type: Type, props = {}) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    props,
  },
})

// --------------------- ===
//  ACTIONS
// ---------------------
// SYNC
export const hideModal = createAction(HIDE_MODAL)
export const clearModalType = createAction(CLEAR_MODAL_TYPE)
export const setModalProps = createAction<Record<string, any>>(SET_MODAL_PROPS)

// ASYNC
export const showModal =
  (type: Type, props = {}) =>
  (dispatch, getState) => {
    const { isVisible } = getState().modal

    // If there's already a modal visible, let's hide it first
    if (isVisible) {
      dispatch(hideModal())
      setTimeout(() => {
        dispatch(localShowModal(type, props))
      }, 300)
    } else {
      dispatch(localShowModal(type, props))
    }
  }

// --------------------- ===
//  REDUCER
// ---------------------
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(hideModal, (state) => {
      state.isVisible = false
    })
    .addCase(clearModalType, (state) => {
      state.type = null
    })
    .addCase(setModalProps, (state, action) => {
      const { payload } = action
      // state.props = payload.props
      state.props = payload
    })
    .addMatcher(
      (action) => isActionType(action, SHOW_MODAL),
      (state, action) => {
        const { payload } = action
        state.type = payload.type
        state.props = payload.props
        state.isVisible = true
      }
    )
})

export default reducer
