import { Link } from 'react-router-dom'

export function MenuItem({ link, title }) {
  // --------------------- ===
  //  RETURN
  // ---------------------
  return (
    <div className="hover:text-theme-primaryCtaBorder py-1" role="none">
      <Link
        to={link}
        className="group block px-2 py-2 text-sm"
        role="menuitem"
        tabIndex={-1}
        id="menu-item-0"
      >
        <span className="invisible group-hover:visible mr-3">|</span>
        {title}
      </Link>
    </div>
  )
}
