import { curveMonotoneX, line } from 'd3'

import type {
  AxisBottomProps,
  AxisLeftProps,
  LineChartValue,
  LineProps,
} from '../types'

export const Line = (props: LineProps) => {
  const { values, scaleX, scaleY, color } = props

  const getLine = (
    xScale: AxisBottomProps['scale'],
    yScale: AxisLeftProps['scale'],
    data: LineChartValue[]
  ) => {
    return (
      /* TODO: D3 x and y functions are not happy accepting given param types.
        Event though they seem correct.
        Investigate later.
    */
      line()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .x((d) => xScale(d.label))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .y((d) => yScale(d.value))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .curve(curveMonotoneX)(data)
    )
  }

  return (
    <path
      d={getLine(scaleX, scaleY, values)}
      fill="none"
      stroke={color}
      strokeWidth="3"
    />
  )
}
