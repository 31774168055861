import { ReactComponent as DropdownSvg } from '@pgl-apps/shared-images/svgr/icons/dropdown.svg'

import styles from './index.module.scss'

interface Props {
  onExpandClick?: () => void
  isExpanded?: boolean
  hasAction?: boolean
}

export const ExpandCell = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onExpandClick = () => null,
    isExpanded = false,
    hasAction = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const downClass = isExpanded ? styles.wrapper__down : ''
  const positionClass = hasAction ? styles.wrapper__action : ''
  return (
    <div className={`${styles.wrapper} ${downClass} ${positionClass}`}>
      <button
        className="w-full h-full px-2 text-theme-muted hover:text-theme-highlighted"
        onClick={onExpandClick}
        type="button"
      >
        <span>
          <DropdownSvg />
        </span>
        <span className="sr-only">Toggle dropdown</span>
      </button>
    </div>
  )
}
