import { ReactComponent as Arrow } from '@pgl-apps/shared-images/svgr/icons/arrow-min-down.svg'

interface Props {
  label: string
  onClick: () => void
  isDown: boolean
}

export const DropdownTrigger = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, onClick, isDown } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  // Assumes right alignment but could be a prop if needed
  return (
    <button
      className="text-theme-muted text-sm flex items-center justify-end"
      type="button"
      onClick={onClick}
    >
      <span>{label}</span>
      <span className={`basis-3 ml-2 ${isDown ? '-scale-100' : ''}`}>
        <Arrow />
      </span>
    </button>
  )
}
