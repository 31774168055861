import { gsap } from 'gsap'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'

import styles from './index.module.scss'

interface Props {
  children: ReactNode
  isExpanded?: boolean
}

const duration = 0.24

export const ExpandBody = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, isExpanded = false } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const wrapperRef = useRef(null)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    if (isExpanded) {
      gsap.set(wrapperRef.current, { height: 'auto', display: 'block' })
      gsap.from(wrapperRef.current, {
        duration,
        height: 0,
      })
    } else {
      gsap.to(wrapperRef.current, {
        duration,
        height: 0,
        onComplete: () => gsap.set(wrapperRef.current, { display: 'none' }),
      })
    }
  }, [isExpanded])

  // --------------------- ===
  //  RENDER
  // ---------------------
  // const expandClass = hasExpand ? styles.wrapper__expand : ''
  // const selectClass = hasSelect ? styles.wrapper__select : ''
  return (
    <div className={`${styles.wrapper}`} ref={wrapperRef}>
      <div className="w-full h-px bg-theme-containerBg mb-4" />
      {children}
    </div>
  )
}
