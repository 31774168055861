import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import { day } from '@pgl-apps/kap-stake/helpers'
import { stakingOpts } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { showModal, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import type { StakingAgreement } from '@pgl-apps/kap-stake/types'
import { config } from '@pgl-apps/shared/api'
import {
  ActionCell,
  ActionItem,
  Badge,
  Cell,
  CtaBtn,
  ExpandBody,
  Heading,
  Row,
  TableWrapper,
} from '@pgl-apps/shared/components'
import { defaultColumns, stakingKeys } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'
import type { SelectedRows } from '@pgl-apps/shared/types'

import styles from '../index.module.scss'
import expandBodyStyles from './expandBody.module.scss'

const columnData: typeof defaultColumns.general[] = [
  {
    ...defaultColumns.general,
    label: 'Pool',
  },
  {
    ...defaultColumns.general,
    label: 'Value (USD)',
  },
  {
    ...defaultColumns.general,
    label: 'Rewards',
  },
  {
    ...defaultColumns.general,
    label: 'APR (%)',
  },
  {
    ...defaultColumns.general,
    label: 'Days Remaining',
  },
]

interface AgreementRowProps {
  id: number
  poolType: string
  poolLabel: string
  // staked amount
  amount: string
  value: number
  // pending rewards
  rewards: number
  rewardsValue: number
  maxBoostRewards: number
  maxBoostRewardsValue: number
  // staked date
  date: string
  daysRemaining: number
  apr: number
}

// convert "StakingAgreement" into table row props
const convertAgreement = (
  agreement: StakingAgreement,
  poolType: string,
  assetPrice: number, // kap or kap/eth price
  kapPrice: number
): AgreementRowProps => {
  const { id, amount, rewards, maxBoostRewards, lockStart, lockEnd, apr } =
    agreement
  const date = new Date(lockStart * 1000)
  const unlocks = Math.ceil((lockEnd * 1000 - Date.now()) / (day * 1000))
  const value = Number(amount) * assetPrice
  const rewardsValue = rewards * kapPrice

  return {
    id,
    poolType,
    poolLabel: stakingOpts[poolType].label,
    amount,
    value,
    rewards,
    rewardsValue,
    maxBoostRewards,
    maxBoostRewardsValue: maxBoostRewards * kapPrice,
    date: date.toLocaleDateString(),
    daysRemaining: Math.max(unlocks, 0),
    apr,
  }
}

interface Props {
  staking: Record<string, any>
}

export const StakingAgreements = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { staking } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )
  const kapEthPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kapEthLP].price
  )

  // // [TODO] Remove kap positions in production
  // const kapRows = useMemo(
  //   () =>
  //     staking.kap.stakingAgreements.map((a) =>
  //       convertAgreement(
  //         a,
  //         stakingKeys.kap,
  //         kapPrice,
  //         kapPrice
  //       )
  //     ),
  //   [staking.kap.stakingAgreements]
  // )

  const kapEthRows = useMemo(
    () =>
      staking.kapEth.stakingAgreements.map((a) =>
        convertAgreement(a, stakingKeys.kapEth, kapEthPrice, kapPrice)
      ),
    [staking.kapEth.stakingAgreements]
  )

  // const allAgreementRows = useMemo(
  //   () => kapRows.concat(kapEthRows),
  //   [kapRows, kapEthRows]
  // )
  const allAgreementRows = kapEthRows

  // --------------------- ===
  //  METHOD
  // ---------------------
  const onClickBoostSingle = useCallback(
    (poolType, agreementId) => {
      dispatch(
        showModal(modalTypes.boostSingle, {
          hookKey: poolType,
          agreementId,
        })
      )
    },
    [dispatch, showModal]
  )

  const onClickClaim = useCallback(
    (poolType, agreementId) => {
      dispatch(
        showModal(modalTypes.claim, {
          hookKey: poolType,
          agreementId,
        })
      )
    },
    [dispatch, showModal]
  )

  // boostMultiple
  const onAgreementsSelectedHandler = useCallback(
    (selectedRows: SelectedRows) => {
      const selectedAgreements = {
        [stakingKeys.kap]: [],
        [stakingKeys.kapEth]: [],
      }
      for (const row of selectedRows) {
        selectedAgreements[allAgreementRows[row].poolType].push(
          allAgreementRows[row].id
        )
      }
      dispatch(
        showModal(modalTypes.boostMultiple, {
          selectedAgreements, // array of agreement id
        })
      )
    },
    [dispatch, showModal, allAgreementRows]
  )

  const onClickUnstake = useCallback(
    (poolType, agreementId, restake = false) => {
      dispatch(
        showModal(modalTypes.unstake, {
          hookKey: poolType,
          agreementId,
          restake,
        })
      )
    },
    [dispatch, showModal]
  )

  return (
    <TableWrapper
      columnData={columnData}
      title="My Stakes"
      selectActions={[
        {
          label: 'Boost Selected',
          callback: onAgreementsSelectedHandler,
        },
      ]}
      isLoading={staking.kapEth.loading}
      hideBoxContainer
    >
      {allAgreementRows.map((row, i) => {
        const canBoost = row.daysRemaining > 0
        const canClaim = row.rewardsValue.toFixed(1) > 0
        return (
          <Row id={i} key={i} isSelectDisabled={!canBoost}>
            <Cell>
              <div className={styles.poolWrapper}>
                <div className={styles.icon}>
                  <img
                    src={stakingOpts[row.poolType].icon}
                    alt={row.poolLabel}
                  />
                </div>
                <span>{row.poolLabel}</span>
              </div>
            </Cell>
            <Cell>${addNumPunctuation(row.value, 2)}</Cell>
            <Cell>${addNumPunctuation(row.rewardsValue, 2)}</Cell>
            <Cell>{addNumPunctuation(row.apr, 2)}%</Cell>
            <Cell>
              <div className="flex items-center justify-between">
                <Badge
                  type={canBoost ? 'info' : 'inactive'}
                  label={row.daysRemaining.toString()}
                />
              </div>
            </Cell>
            <ActionCell>
              {canBoost ? (
                <>
                  <ActionItem
                    label="Boost"
                    onClick={() => onClickBoostSingle(row.poolType, row.id)}
                  />
                  {canClaim && (
                    <ActionItem
                      label="Claim Rewards"
                      onClick={() => onClickClaim(row.poolType, row.id)}
                    />
                  )}
                </>
              ) : (
                <>
                  <ActionItem
                    label="Restake"
                    onClick={() => onClickUnstake(row.poolType, row.id, true)}
                  />
                  <ActionItem
                    label="Unstake & Claim"
                    onClick={() => onClickUnstake(row.poolType, row.id)}
                  />
                </>
              )}
            </ActionCell>
            <ExpandBody>
              <div className="border border-theme-highlighted p-4 pt-8 mb-4 bg-theme-containerBg">
                <div className="flex flex-col md:flex-row items-center">
                  <div className="md:basis-1/2 md:divider-r px-8 flex flex-col gap-4">
                    <div className="flex">
                      <Heading type="paragraph" tag="h4" className="mb-0 mr-2">
                        Date Staked
                      </Heading>
                      <span>{row.date}</span>
                    </div>
                    <div className="flex">
                      <Heading type="paragraph" tag="h4" className="mb-0 mr-2">
                        Days Remaining
                      </Heading>
                      <span>{row.daysRemaining}</span>
                    </div>
                    <div className="flex">
                      <Heading type="paragraph" tag="h4" className="mb-0 mr-2">
                        Available Rewards
                      </Heading>
                      <span>${addNumPunctuation(row.rewardsValue, 2)}</span>
                    </div>
                  </div>
                  {/* <div
                    className={`hidden md:block ${expandBodyStyles.splitter}`}
                  /> */}
                  <div className="md:basis-1/2 px-8">
                    {canBoost ? (
                      <>
                        <Heading type="paragraph" tag="h4" className="mb-2">
                          Est. Max Boost Value
                        </Heading>
                        <div className="flex items-end flex-wrap">
                          <span className="text-3xl text-theme-highlighted mb-2 mr-2">
                            {addNumPunctuation(row.maxBoostRewards, 2)} KAP
                          </span>
                          <span className="text-xs mb-3">
                            (+ ${addNumPunctuation(row.maxBoostRewardsValue, 2)}
                            )
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <Heading type="paragraph" tag="h4" className="mb-2">
                          Est. Max Restake Value
                        </Heading>
                        <div className="flex items-end flex-wrap">
                          <span
                            className={`text-3xl ${expandBodyStyles.boostValue} mb-2 mr-2`}
                          >
                            +
                            {addNumPunctuation(
                              row.amount *
                                staking[row.poolType].aprDivider.toNumber() *
                                (staking[row.poolType].maxApr / 100),
                              1
                            )}{' '}
                            KAP
                          </span>
                          <span className="text-xs mb-3">
                            ($
                            {addNumPunctuation(
                              row.value *
                                (staking[row.poolType].maxApr / 100 + 1),
                              1
                            )}
                            )
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="flex justify-end mt-4 gap-3">
                  {canBoost ? (
                    <>
                      <CtaBtn
                        state={canClaim ? 'default' : 'disabled'}
                        type="tertiary"
                        onClick={() => onClickClaim(row.poolType, row.id)}
                      >
                        Claim Rewards
                      </CtaBtn>
                      <CtaBtn
                        type="primary"
                        onClick={() => onClickBoostSingle(row.poolType, row.id)}
                      >
                        Boost
                      </CtaBtn>
                    </>
                  ) : (
                    <>
                      <CtaBtn
                        type="tertiary"
                        onClick={() => onClickUnstake(row.poolType, row.id)}
                      >
                        Unstake & Claim
                      </CtaBtn>
                      <CtaBtn
                        type="primary"
                        onClick={() =>
                          onClickUnstake(row.poolType, row.id, true)
                        }
                      >
                        Restake
                      </CtaBtn>
                    </>
                  )}
                </div>
              </div>
            </ExpandBody>
          </Row>
        )
      })}
    </TableWrapper>
  )
}
