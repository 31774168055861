import { createReducer } from '@reduxjs/toolkit'
import { BigNumber, Contract, ethers, utils } from 'ethers'

import { config } from '@pgl-apps/shared/api'
import { abiToken, abiUniV2Pair } from '@pgl-apps/shared/api'
import {
  getActionStatusObj,
  getCoingeckoPrice,
  getUniswapDayPrice,
  isActionType,
  setActionStatus,
} from '@pgl-apps/shared/helpers'

/*
interface TokenBaseParams {
  provider: ethers.providers.InfuraProvider

  name?: string
  symbol?: string

  decimals: number
  totalSupplyBN: BigNumber
  balances: Record<string, BigNumber>

  price

  isLP: boolean
  // LP Token specific
  token0: string
  token1: string
  reserve0BN: BigNumber
  reserve1BN: BigNumber
}
*/

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'tokens'

// TODO does it matter if we create a new provider on each instance?
const infuraProvider = new ethers.providers.InfuraProvider(config.network)

// --------------------- ===
//  CONSTANTS
// ---------------------
const FETCH_BASE_PARAMS = `${sliceName}/FETCH_BASE_PARAMS`
const FETCH_BALANCES = `${sliceName}/FETCH_BALANCES`

const constants = {
  FETCH_BASE_PARAMS,
  FETCH_BALANCES,
}

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState = {
  [config.addresses.tokens.kap]: {
    provider: new Contract(
      config.addresses.tokens.kap,
      abiToken.abi,
      infuraProvider
    ),
    decimals: 0,
    totalSupplyBN: BigNumber.from(1),
    balances: {},
    price: 0,
    isLP: false,
  },
  [config.addresses.tokens.kapEthLP]: {
    provider: new Contract(
      config.addresses.tokens.kapEthLP,
      abiUniV2Pair.abi,
      infuraProvider
    ),
    decimals: 0,
    totalSupplyBN: BigNumber.from(1),
    balances: {},
    price: 0,
    isLP: true,
    token0: '',
    token1: '',
    reserve0BN: BigNumber.from(0),
    reserve1BN: BigNumber.from(0),
  },
} // Record<string, TokenBaseParams>

// convert token address into coingecko api ID
const getCoingeckoID = (tokenAddress) => {
  return config.coingeckoID[tokenAddress]
}

// --------------------- ===
//  ACTIONS
// ---------------------
const fetchBaseParams =
  (token: string, isLP = false) =>
  (dispatch) => {
    const provider = new Contract(
      token,
      isLP ? abiUniV2Pair.abi : abiToken.abi,
      infuraProvider
    )

    return dispatch({
      type: FETCH_BASE_PARAMS,
      payload: provider.decimals().then(async (decimals: BigNumber) => {
        const totalSupplyBN = await provider.totalSupply()

        let token0, token1, reserve0BN, reserve1BN, price
        if (isLP) {
          token0 = await provider.token0()
          token1 = await provider.token1()
          const _reserves = await provider.getReserves()
          reserve0BN = _reserves[0]
          reserve1BN = _reserves[1]

          const _token0ID = getCoingeckoID(token0)
          const _token1ID = getCoingeckoID(token1)
          let prices
          try {
            prices = await getCoingeckoPrice([_token0ID, _token1ID])
          } catch (err) {
            console.error(err)
            const token0Prices = await getUniswapDayPrice(token0)
            const token1Prices = await getUniswapDayPrice(token1)
            prices = {
              [_token0ID]: { usd: token0Prices },
              [_token1ID]: { usd: token1Prices },
            }
          }

          const token0Price = prices[_token0ID].usd
          const token1Price = prices[_token1ID].usd

          price =
            (parseFloat(utils.formatEther(_reserves[0])) * token0Price +
              parseFloat(utils.formatEther(_reserves[1])) * token1Price) /
            parseFloat(utils.formatEther(totalSupplyBN))
        } else {
          const tokenID = getCoingeckoID(token)
          const prices = await getCoingeckoPrice([tokenID])
          price = prices[tokenID].usd
        }

        return {
          [token]: {
            provider,
            decimals,
            totalSupplyBN,
            price,

            isLP,
            token0,
            token1,
            reserve0BN,
            reserve1BN,
          },
        }
      }),
    })
  }

const fetchBalances =
  (token: string, accounts: string[]) => (dispatch, getState) => {
    const { provider } = getState()[sliceName][token]

    return dispatch({
      type: FETCH_BALANCES,
      payload: Promise.all(
        accounts.map((account) => provider.balanceOf(account))
      ).then((balances: BigNumber[]) => {
        const result: Record<string, BigNumber> = {}
        for (let i = 0; i < accounts.length; i++) {
          result[accounts[i]] = balances[i]
        }

        return {
          [token]: result,
        }
      }),
    })
  }

const actions = {
  fetchBaseParams,
  fetchBalances,
}

// --------------------- ===
//  REDUCER
// ---------------------
const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(
      (action) => isActionType(action, FETCH_BASE_PARAMS),
      (state, action) => {
        setActionStatus(state, action)
        if (getActionStatusObj(action).isFulfilled) {
          const token = Object.keys(action.payload)[0]
          // Copy selected field only
          Object.keys(action.payload[token]).forEach((attribute) => {
            state[token][attribute] = action.payload[token][attribute]
          })
        }
      }
    )
    .addMatcher(
      (action) => isActionType(action, FETCH_BALANCES),
      (state, action) => {
        setActionStatus(state, action)
        if (getActionStatusObj(action).isFulfilled) {
          const token = Object.keys(action.payload)[0]
          // Copy selected account only
          Object.keys(action.payload[token]).forEach((account) => {
            state[token].balances[account] = action.payload[token][account]
          })
        }
      }
    )
})

export const tokens = {
  reducer,
  actions,
  constants,
}

export default reducer
