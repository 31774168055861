import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { SVGR } from '@pgl-apps/shared/types'

import { TabBtn } from '../../../buttons'

interface Props {
  tabs: {
    title: string
    Comp: ReactNode
    Icon: SVGR // Should use svgs from the nav folder
  }[]
  initTabIndex?: number
}

export const TabController = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { tabs, initTabIndex = 0 } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [currentTabIndex, setCurrentTabIndex] = useState(initTabIndex)
  const [searchParams, setSearchParams] = useSearchParams()

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const handleTabSelection = (tabName: string) => {
    // setCurrentTabIndex(index)
    const params = searchParams
    params.set('tab', tabName)
    // replace the last query param on the history stack
    setSearchParams(params, { replace: true })
  }

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const selectedTab = searchParams.get('tab')

    const tabIndex = tabs.findIndex((tab) => tab.title === selectedTab)
    if (tabIndex > -1) {
      setCurrentTabIndex(tabIndex)
    } else {
      setCurrentTabIndex(0)
    }
  }, [searchParams, tabs])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <>
      <div className="flex flex-wrap mt-8 mb-4 border-b border-theme-containerBg">
        {tabs.map((tab, i) => (
          <TabBtn
            key={i}
            isActive={currentTabIndex === i}
            onClick={() => handleTabSelection(tab.title)}
            label={tab.title}
            Icon={tab.Icon}
          />
        ))}
      </div>
      <div>{tabs[currentTabIndex].Comp}</div>
    </>
  )
}
