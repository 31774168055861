import type { ReactNode } from 'react'

interface BulletPointProps {
  children: ReactNode
}

export const BulletPoint = (props: BulletPointProps) => {
  const { children } = props

  return (
    <div className="flex gap-2 text-sm">
      <span className="text-theme-highlighted">+</span>
      <span>{children}</span>
    </div>
  )
}
