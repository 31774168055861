import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'

import { ReactComponent as ArrowDown } from '@pgl-apps/shared-images/svgr/icons/dropdown.svg'
import { ReactComponent as FilterIcon } from '@pgl-apps/shared-images/svgr/icons/filter.svg'
import type {
  FilterConfig,
  Filter as FilterProps,
} from '@pgl-apps/shared/types'

import { CtaBtn } from '../../../../buttons'
import { Checkbox } from '../../../../form'
import { DropdownWrapper } from '../../../../utility'
import styles from '../index.module.scss'
import { FilterTag } from './private/FilterTag/FilterTag'

interface Props {
  filterConfig?: FilterConfig
}

const duration = 0.32

const checkSomeSelected = (filter: FilterProps) =>
  filter.options.some((opt) => opt.isSelected)

export const Filter = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { filterConfig } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isExpanded, setIsExpanded] = useState(false) // Add Filters toggle
  const [currentKey, setCurrentKey] = useState('') // currently expanded filter

  // --------------------- ===
  //  REFS
  // ---------------------
  const filterGroup = useRef(null)
  const filterContainer = useRef(null)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const checkClickOutside = (evt: PointerEvent) => {
      // close dropdown on click outside
      if (!filterContainer.current.contains(evt.target)) {
        setCurrentKey('')
      }
    }
    if (isExpanded) {
      gsap.set(filterGroup.current, { display: 'flex' })
      gsap.to(filterGroup.current, {
        duration,
        xPercent: 0,
        onComplete: () => {
          gsap.set(filterContainer.current, { overflow: 'visible' })
        },
      })
      window.addEventListener('pointerdown', checkClickOutside)
    } else {
      gsap.set(filterContainer.current, { overflow: 'hidden' })
      gsap.to(filterGroup.current, {
        duration,
        xPercent: -100,
        onComplete: () => {
          gsap.set(filterGroup.current, { display: 'none' })
        },
      })
    }
    return () => window.removeEventListener('pointerdown', checkClickOutside)
  }, [isExpanded])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleClearAllFilters = () => filterConfig.onClick(null, null)
  const handleClearFilterGroup = (key) => filterConfig.onClick(key, null)
  const handleToggleVisibility = (key: string) =>
    setCurrentKey((prev) => (prev === key ? '' : key))

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isAnySelected = Object.keys(filterConfig.filters).some((key) =>
    checkSomeSelected(filterConfig.filters[key])
  )

  const arrowClass = isExpanded ? '-scale-y-100' : ''

  const selected = isAnySelected ? styles.filterItem__active : styles.filterItem

  return (
    <div className="flex gap-4 items-center">
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        type="button"
        className="flex items-center gap-1 hover:underline text-sm relative py-1"
      >
        <span className={`${selected} w-3 flex align-middle justify-center `}>
          <FilterIcon />
        </span>
        <span>Add Filters</span>
        <span className="w-3 text-theme-muted">
          <ArrowDown
            className={`-rotate-90 transition-transform ${arrowClass}`}
          />
        </span>
      </button>

      <div className="overflow-hidden relative z-nav" ref={filterContainer}>
        <div
          className="flex-wrap gap-2 items-center w-fit hidden"
          ref={filterGroup}
        >
          {Object.keys(filterConfig.filters).map((key) => (
            <DropdownWrapper
              key={key}
              trigger={
                <FilterTag
                  key={key}
                  isOpen={key === currentKey}
                  label={filterConfig.filters[key].label}
                  isActive={checkSomeSelected(filterConfig.filters[key])}
                  onClick={() => handleToggleVisibility(key)}
                  onXClick={() => {
                    if (
                      checkSomeSelected(filterConfig.filters[key]) &&
                      key !== currentKey
                    ) {
                      handleClearFilterGroup(key)
                    } else {
                      handleToggleVisibility(key)
                    }
                  }}
                />
              }
              isDown={key === currentKey}
            >
              {filterConfig.filters[key].options.map((opt) => (
                <Checkbox
                  onClick={() => filterConfig.onClick(key, opt)}
                  state={opt.isSelected ? 'checked' : 'unchecked'}
                  isSingleChoice={filterConfig.filters[key].isSingleChoice}
                  key={opt.label}
                >
                  {opt.label}
                </Checkbox>
              ))}
            </DropdownWrapper>
          ))}
          {isAnySelected && (
            <CtaBtn type="tertiary" onClick={handleClearAllFilters}>
              Clear Filters
            </CtaBtn>
          )}
        </div>
      </div>
    </div>
  )
}
