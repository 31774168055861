import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const ModalContentWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <div className="flex flex-col">{children}</div>
}
