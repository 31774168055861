import { Heading } from '@pgl-apps/shared/components'

import { CtaPrimary } from '../../../../buttons'
import { CancelBtn } from '../../Claim/private/CancelBtn'

interface Props {
  onCloseModal: (arg?: unknown) => void
}

export const AcquireToken = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onCloseModal } = props

  // --------------------- ===
  //  RENDER
  // ---------------------

  return (
    <div>
      <div className="my-12">
        <Heading type="page" tag="h2">
          Acquire KAP/ETH LP Tokens to Stake
        </Heading>
        <div className="my-24 text-theme-muted">
          In order to stake, acquire KAP/ETH LP tokens
          <br /> or switch to a wallet that has some.
          <br />
          For more information, see&nbsp;
          <a
            href="https://medium.com/@kapitaldao/how-to-stake-kapital-dao-kap-tokens-57679d73e6ff"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            How to Stake
          </a>
          .
        </div>
      </div>

      <div className="flex justify-end items-center gap-4 mt-16">
        <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
        <div className="w-full max-w-[14rem]">
          <a
            href="https://app.uniswap.org/#/add/v2/0x9625ce7753ace1fa1865a47aae2c5c2ce4418569/ETH"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <CtaPrimary label="Get KAP/ETH LP" onClick={() => {}} />
          </a>
        </div>
      </div>
    </div>
  )
}
