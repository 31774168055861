export interface FetchBody {
  [key: string]: string
}

export interface FetchOptions {
  credentials?: RequestCredentials
  headers?: Headers
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  mode?: RequestMode
}
