import type { ReactNode } from 'react'

import { validateField } from '@pgl-apps/shared/helpers'
import type { InputConfig, InputConfigCheckbox } from '@pgl-apps/shared/types'

import { Spinner } from '../../decorative'
import { Error } from '../../typography'

interface Props {
  label?: string
  inputConfig: InputConfig | InputConfigCheckbox
  children: ReactNode
  hideLabel?: boolean
  required?: boolean
  canShowError?: boolean // kinda hacky. TODO improve
}

export const InputWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    label,
    inputConfig,
    children,
    hideLabel = false,
    canShowError = false,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const errorMsg = canShowError ? validateField(inputConfig) : ''

  return (
    <label>
      <span
        className={hideLabel ? 'sr-only' : 'mb-1 text-sm inline-block w-full'}
      >
        {`${inputConfig.label || label}${inputConfig.isRequired ? '*' : ''}`}
      </span>
      <span className="align-items-end inline-block w-full relative">
        {children}
        {(inputConfig.state === 'loading' ||
          inputConfig.state === 'loadingPassive') && (
          <span className="absolute bg-theme-fieldBg right-px top-px bottom-px w-10 p-3 flex items-center rounded-lg">
            <Spinner status="pending" />
          </span>
        )}
        <div className="absolute top-full mt-px">
          {errorMsg && <Error message={errorMsg} />}
        </div>
      </span>
    </label>
  )
}
