import type { ComponentType, ReactNode } from 'react'
import { Children, isValidElement } from 'react'

/*
 TODO: This type should be imported from @pgl-apps/shared/types.
 There's some issue where the hocs package does not see types from @pgl-apps/shared/types during builds.
*/
interface ChildComponentCheck {
  [key: string]: boolean
}

// Checks if any component exists in a parent's children
export const withChildComponent = (
  Component: ComponentType,
  expectedChildren: ComponentType[]
) => {
  return (props: any) => {
    const childComponents: ChildComponentCheck = {}
    // Add keys to obj
    expectedChildren.forEach((expectedChild) => {
      childComponents[expectedChild.name] = false
    })

    // Check each children level func
    const checkChildren = (children: ReactNode) => {
      Children.forEach(children, (child) => {
        // if (!child) return
        if (!isValidElement(child)) return

        if (
          typeof child.type !== 'string' &&
          Object.keys(childComponents).includes(child.type.name) &&
          childComponents[child.type.name] === false
        ) {
          childComponents[child.type.name] = true
        }

        // Run recursively
        if (child.props && child.props.children) {
          checkChildren(child.props.children)
        }
      })
    }

    // Run recursive func
    checkChildren(props.children)

    return <Component {...props} childComponents={childComponents} />
  }
}
