import { useState } from 'react'

import type { WalletConnectModes } from '@pgl-apps/kap-stake/hooks'
import { useWallet } from '@pgl-apps/kap-stake/hooks'
import metamaskSrc from '@pgl-apps/shared-images/logos/metamask.svg'
import walletConnectSrc from '@pgl-apps/shared-images/logos/wallet-connect.svg'
import { Error, Heading, SpinnerOverlay } from '@pgl-apps/shared/components'

import { ModalContentWrapper } from '../../ModalContentWrapper'
import { WalletBtn } from './private/WalletBtn'

interface Props {
  onCloseModal: () => void
}

export const Wallet = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onCloseModal } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { connect } = useWallet()

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleClick = (mode: WalletConnectModes) => {
    setIsLoading(true)
    setErrMsg('')
    connect(mode)
      .then(() => {
        onCloseModal()
      })
      .catch((err: string | ErrorEvent) => {
        console.log('err :>> ', err)
        if (typeof err === 'string') {
          setErrMsg(err)
        } else if (err && err.message) {
          setErrMsg(err.message)
        } else {
          setErrMsg('An unknown error has occurred.')
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <ModalContentWrapper>
      <Heading type="article" tag="h2" hasDivider className="text-left">
        <>
          <span className="w-full">Connect Your Wallet</span>
          <br />
          <span className="text-xs text-theme-muted font-normal">
            Connect your Ethereum wallet. Be sure it is unlocked and ready to
            use.
          </span>
        </>
      </Heading>

      <div className="text-center">
        <div className="relative flex gap-4 mt-14 mb-12">
          <WalletBtn
            onClick={() => {
              if (!isLoading) {
                handleClick('MetaMask')
              }
            }}
            isLoading={isLoading}
            label="MetaMask"
            imgSrc={metamaskSrc}
          />
          <WalletBtn
            onClick={() => {
              if (!isLoading) {
                handleClick('WalletConnect')
              }
            }}
            isLoading={isLoading}
            label="Wallet Connect"
            imgSrc={walletConnectSrc}
          />
          {isLoading && <SpinnerOverlay />}
        </div>
        {errMsg && (
          <div className="mt-2 sm:px-8">
            <Error message={errMsg} />
          </div>
        )}
      </div>
    </ModalContentWrapper>
  )
}
