import { Fragment, useState } from 'react'

import type { InputConfig } from '@pgl-apps/shared/types'

import { DropdownOption, DropdownWrapper } from '../../utility'
import { Suggestion } from '../../utility/dropdown/private/Suggestion'
import { InputWithSuffix } from '../InputWithSuffix/InputWithSuffix'

export interface SuggestionGroup {
  groupName: string
  suggestions: string[]
}

interface Props {
  inputConfig: InputConfig
  className?: string
  onChange: (arg0: string) => void
  onClick?: (arg0: string) => void
  suggestionGroupArr: SuggestionGroup[]
  placeholder?: string
  suffix: React.ReactNode
  showSuffix?: boolean
  filterOptions?: boolean
}

export const InputWithGroupedSuggestions = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    className = 'w-full',
    onChange,
    onClick = (arg0: string) => onChange(arg0),
    suggestionGroupArr,
    placeholder = 'Search',
    suffix,
    showSuffix = true,
    filterOptions = true,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isOpen, setOpen] = useState<boolean>(false)

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div>
      <DropdownWrapper
        className={className}
        trigger={
          <InputWithSuffix
            inputConfig={inputConfig}
            onChange={onChange}
            onFocus={() => setOpen(true)}
            onBlur={(e) => {
              if (
                !e.currentTarget.contains(e.relatedTarget) &&
                // Don't close the dropdown option list if it was clicked on an option.
                // The dropdown option will close the list after setting the selected value.
                !e.relatedTarget?.classList.contains('dropdown-option')
              ) {
                // Not triggered when swapping focus between children
                setOpen(false)
              }
            }}
            placeholder={placeholder}
            hideLabel
            required // hack to keep border 'active' color
            suffix={suffix}
            showSuffix={showSuffix}
          />
        }
        isDown={isOpen}
      >
        {suggestionGroupArr.map((suggestionGroup) => (
          <Fragment key={suggestionGroup.groupName}>
            <div className="text-sm divider-b">{suggestionGroup.groupName}</div>
            {suggestionGroup.suggestions.map((suggestion, i) =>
              !filterOptions ||
              suggestion
                .toLowerCase()
                .includes(inputConfig.value.toLowerCase()) ? (
                <div key={suggestion} className="pl-4">
                  <DropdownOption
                    onClick={() => {
                      onClick(suggestion)
                      setOpen(false)
                    }}
                  >
                    <Suggestion
                      searchValue={inputConfig.value}
                      suggestion={suggestion}
                    />
                  </DropdownOption>
                </div>
              ) : null
            )}
          </Fragment>
        ))}
      </DropdownWrapper>
    </div>
  )
}
