import type { Signer } from 'ethers'
import {
  BigNumber,
  Contract,
  constants as ethersConstants,
  utils,
} from 'ethers'
import { useCallback, useEffect, useState } from 'react'

import { calculateAPR } from '@pgl-apps/kap-stake/helpers'
import { rewards, tokens, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { abiToken, config } from '@pgl-apps/shared/api'
import { actionStatuses, isActionStatus } from '@pgl-apps/shared/helpers'
import { NotificationTypes } from '@pgl-apps/shared/types'

import { useNotifications } from '../useNotifications'
import { useTransactions } from '../useTransactions'

export const useStakingTemplate = (
  slice: any,
  actions: any,
  constants: any,
  walletId: string,
  signer: Signer
) => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const {
    provider,
    asset,
    rewardsRule,
    lockPeriodLimits,
    totalWeight,
    maxApr,
    aprDivider,
    agreements,
    pendingRewards,
    totalStaked,
  } = slice
  const {
    initPendingTxn,
    updatePendingTxn,
    completePendingTxn,
    errorPendingTxn,
  } = useTransactions()

  const loading =
    isActionStatus(
      slice,
      constants.FETCH_CONTRACT_DATA,
      actionStatuses.PENDING
    ) ||
    isActionStatus(
      slice,
      constants.FETCH_STAKING_AGREEMENTS,
      actionStatuses.PENDING
    )

  // --------------------- ===
  //  STATE
  // ---------------------
  const [currentApr, setCurrentAprState] = useState('0')

  const [stakeInputAmount, setStakeInputAmount] = useState('')
  const [currentLockPeriod, setCurrentLockPeriod] = useState(0) // in seconds

  const [userAllowanceBN, setUserAllowanceBNState] = useState<null | BigNumber>(
    null
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { addNotification } = useNotifications()

  // --------------------- ===
  //  METHODS
  // ---------------------
  const setCurrentApr = useCallback(
    async (
      amount: BigNumber,
      lockPeriod: number // seconds
    ) => {
      // estimate future APR if stakes newly
      const apr = await calculateAPR(
        amount,
        lockPeriod,
        totalWeight,
        rewardsRule,
        aprDivider,
        true
      )
      setCurrentAprState(apr)
    },
    [totalWeight, rewardsRule, aprDivider]
  )

  // --------------------- ===
  //  CONTRACT METHODS
  // ---------------------
  /*
    assuming:
      - all token inputs and outputs are BigNumbers in wei
      - all times are in weeks an converted to seconds here
      - wallet connection is already checked in the component
      - handle waiting for transactions in the useEffect below
  */

  // gets current token allowance for the contracts staking token
  const setUserAllowance = useCallback(
    async (wallet: string) => {
      try {
        const contract = new Contract(asset, abiToken.abi, signer)
        const amountBN = await contract.allowance(wallet, provider.address)
        setUserAllowanceBNState(amountBN)
      } catch (e) {
        // invalid network selected
        setUserAllowanceBNState(null)
      }
    },
    [provider]
  )

  // submits approval tx for contract to spend users tokens
  const approveUserAllowance = useCallback(
    async (wallet: string): Promise<boolean> => {
      const newTxnID = initPendingTxn('Waiting for Confirmation')
      try {
        const contract = new Contract(asset, abiToken.abi, signer)
        const txn = await contract.approve(
          provider.address,
          ethersConstants.MaxInt256
        )
        updatePendingTxn(
          newTxnID,
          txn.hash,
          'Approval is pending. This could take a few minutes.'
        )
        await txn.wait()
        completePendingTxn(newTxnID, txn.hash, 'Approval Successful')
      } catch (err: any) {
        errorPendingTxn(newTxnID, 'Approve failed.')
        return false
      }

      // load allowance again
      setUserAllowance(wallet)
      return true
    },
    [provider]
  )

  // stakes users for amount in wei and lockPeriod in weeks
  const stake = useCallback(async () => {
    const newTxnID = initPendingTxn('Waiting for Confirmation')
    try {
      // connect the users web3 provider to the contract
      const userContract = provider.connect(signer)
      // sign and send transaction
      const txn = await userContract.stake(
        utils.parseEther(stakeInputAmount),
        BigNumber.from(currentLockPeriod)
      )
      addNotification({
        message: `Transaction submitted: ${txn.hash}`,
        type: NotificationTypes.info,
      })
      updatePendingTxn(
        newTxnID,
        txn.hash,
        'Stake is pending. This could take a few minutes.'
      )
      await txn.wait()
      completePendingTxn(newTxnID, txn.hash, 'Stake Successful')
    } catch (err: any) {
      errorPendingTxn(newTxnID, 'Stake failed.')
    }
    // reload staking agreements
    dispatch(actions.fetchStakingAgreements(walletId))
    // reload token balances
    dispatch(tokens.actions.fetchBalances(asset, [walletId, provider.address]))
  }, [currentLockPeriod, provider, stakeInputAmount, walletId])

  const claimReward = useCallback(
    async (claim: {
      agreementId: number
      txnId: number
      extendPeriod: number
    }) => {
      const { agreementId, txnId, extendPeriod } = claim
      try {
        // connect the users web3 provider to the contract
        const userContract = provider.connect(signer)
        // estimate gas limit
        const gasLimit = await userContract.estimateGas.claimRewards(
          agreementId,
          extendPeriod
        )
        // sign and send tx
        const txn = await userContract.claimRewards(agreementId, extendPeriod, {
          gasLimit: gasLimit.mul(120).div(100),
        })
        addNotification({
          message: `Transaction submitted: ${txn.hash}`,
          type: NotificationTypes.info,
        })
        updatePendingTxn(
          txnId,
          txn.hash,
          `${
            extendPeriod > 0 ? 'Boost' : 'Claim'
          } is pending. This could take a few minutes.`
        )
        await txn.wait()
        completePendingTxn(
          txnId,
          txn.hash,
          `${extendPeriod > 0 ? 'Boost' : 'Claim'} Successful`
        )
      } catch (err: any) {
        errorPendingTxn(
          txnId,
          `${extendPeriod > 0 ? 'Boost' : 'Claim'} failed.`
        )
      }
    },
    [provider]
  )

  // claims pending rewards and creates a new lock agreement in the rewards locker contract
  // [NOTICE] if extendPeriod > 0, then it restakes while boosting rewards
  const claimRewards = useCallback(
    async (agreementIds: number[], extendPeriods: number[]) => {
      const claims = []
      agreementIds.forEach((id, i) => {
        claims.push({
          agreementId: id,
          txnId: initPendingTxn('Waiting for Confirmation'),
          extendPeriod: extendPeriods[i],
        })
      })

      for (const claim of claims) {
        await claimReward(claim)
      }

      // reload staking agreements
      dispatch(actions.fetchStakingAgreements(walletId))
      // reload rewards agreements
      dispatch(rewards.actions.fetchRewardsLockAgreements(walletId))
      // reload token balances
      dispatch(
        tokens.actions.fetchBalances(config.addresses.tokens.kap, [walletId])
      )
    },
    [provider, walletId]
  )

  // unstakes a users staking agreement so they can reclaim staked funds
  const unstake = useCallback(
    async (agreementId: number): Promise<boolean> => {
      const newTxnID = initPendingTxn('Waiting for Confirmation')
      try {
        const userContract = provider.connect(signer)
        // sign and send tx
        const txn = await userContract.unstake(agreementId)
        addNotification({
          message: `Transaction submitted: ${txn.hash}`,
          type: NotificationTypes.info,
        })
        updatePendingTxn(
          newTxnID,
          txn.hash,
          'Unstake is pending. This could take a few minutes.'
        )
        await txn.wait()
        completePendingTxn(newTxnID, txn.hash, 'Unstake Successful')
      } catch (err: any) {
        errorPendingTxn(newTxnID, 'Unstake failed.')
        return false
      }
      // reload staking agreements
      dispatch(actions.fetchStakingAgreements(walletId))
      // reload token balances
      dispatch(
        tokens.actions.fetchBalances(asset, [walletId, provider.address])
      )
      return true
    },
    [provider, walletId]
  )

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    const amtBN = stakeInputAmount || '0'
    setCurrentApr(utils.parseEther(amtBN), currentLockPeriod)
  }, [currentLockPeriod, setCurrentApr, stakeInputAmount])

  useEffect(() => {
    // Set allowance if walletId changes and there's not a pending tx
    if (walletId) {
      setUserAllowance(walletId)
    } else {
      setUserAllowanceBNState(null)
    }
  }, [setUserAllowance, walletId])

  return {
    loading,
    setCurrentApr,
    setStakeInputAmount,
    setCurrentLockPeriod,
    approveUserAllowance,
    stake,
    stakingAgreements: agreements,
    staker: {
      totalAmount: totalStaked,
    },
    currentApr,
    pendingRewards: utils.formatEther(pendingRewards),
    lockPeriodLimits,
    stakeInputAmount,
    currentLockPeriod,
    userAllowanceBN,
    maxApr,
    aprDivider,
    claimRewards,
    unstake,
  }
}

export default useStakingTemplate
