const suffixes = ['K', 'M', 'B', 'T']

export const addNumPunctuation = (
  num: number | string | undefined,
  decimalPlaces?: number | undefined
) => {
  if (num === undefined) {
    return num
  }
  const numStr = num.toString()
  let formatResult
  if (Number(num) > 1000) {
    formatResult = parseInt(numStr)
  } else if (decimalPlaces === undefined) {
    formatResult = parseFloat(numStr)
  } else {
    formatResult = parseFloat(numStr).toFixed(decimalPlaces)
  }
  return formatResult.toLocaleString()
}

export const shortNumber = (num: string | number, decimalPlaces = 0) => {
  let numStr = num.toString()
  // Remove minus
  const isNeg = numStr[0] === '-'
  numStr = isNeg ? numStr.substring(1) : numStr
  // Check for float
  const [int] = numStr.split('.')
  const { length } = int
  if (length <= 3) {
    // Under 1000, return full number
    return num
  }
  // Find index of character to split
  const split = length % 3 === 0 ? 3 : length % 3
  // Max out at Trillion (becomes 1234T)
  const extra = Math.max(length - suffixes.length * 3, 0) // for really big number
  const total = split + extra
  let final = int.substring(0, total)
  // Add decimal
  if (decimalPlaces) {
    final = `${final}.${int.substring(total, total + decimalPlaces)}`
  }
  // Get suffix (could be improved)
  const suffix =
    suffixes[Math.min(Math.floor(length / 3) - 1, suffixes.length - 1)]
  return `${isNeg ? '-' : ''}${final}${suffix}`
}

export const formatCash = (n) => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}
