import type { ReactNode } from 'react'

import { FormWrapper } from '../../../form'
import { PrimaryBox } from '../PrimaryBox/PrimaryBox'

interface Props {
  children: ReactNode
  label: string
  onSubmit: () => void
  isSubmitting?: boolean
  errorMsgs?: string[]
  subLabel?: string
  btnLabel?: string
  className?: string
}

export const FormBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    children,
    label,
    onSubmit,
    isSubmitting = false,
    errorMsgs = [],
    subLabel = '',
    btnLabel = 'Submit',
    className = 'w-full',
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <PrimaryBox label={label} subLabel={subLabel}>
      <FormWrapper
        onSubmit={onSubmit}
        btnLabel={btnLabel}
        isSubmitting={isSubmitting}
        className={className}
        errorMsgs={errorMsgs}
      >
        <div className="divider-b pb-4">{children}</div>
      </FormWrapper>
    </PrimaryBox>
  )
}
