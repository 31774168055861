import styles from '../index.module.scss'

export const Circle = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <svg viewBox="0 0 110 110" className={styles.disc}>
      <circle r="50" cx="55" cy="55" strokeWidth="10" />
      <circle r="50" cx="55" cy="55" strokeWidth="10" />
    </svg>
  )
}
