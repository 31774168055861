export type TokenOpts =
  | 'ETH'
  | 'SLP'
  | 'AXS'
  | 'VIS'
  | 'RBW'
  | 'UNIM'
  | 'MATIC'
  | 'RON'
  | 'RON_WETH'
  | 'FNC'
  | 'sFNC'
