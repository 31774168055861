import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import { stakingOpts, useStaking, useToken } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { showModal, useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { Heading, Label } from '@pgl-apps/shared/components'
import { stakingKeys } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../buttons'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { CancelBtn } from '../Claim/private/CancelBtn'

interface Props {
  hookKey?: keyof typeof stakingOpts
  agreementId?: number
  restake?: boolean
  onCloseModal: () => void
}
export const Unstake = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { hookKey, agreementId, onCloseModal, restake } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const staking = useStaking(walletId, signer)

  const currentStakingHook = staking[hookKey]
  const { stakingAgreements, unstake } = currentStakingHook
  const selectedAgreement = useMemo(
    () => stakingAgreements.find((a) => a.id == agreementId),
    [stakingAgreements, agreementId]
  )
  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = useCallback(async () => {
    onCloseModal()
    // backup staking amount for restake
    const restakeAmount = selectedAgreement.amount
    const isUnstakeSuccess = await unstake(agreementId)
    if (!restake || !isUnstakeSuccess) return

    dispatch(
      showModal(modalTypes.stake, {
        hookKey,
        defaultAmount: restakeAmount,
      })
    )
  }, [selectedAgreement, restake])

  return (
    <ModalContentWrapper>
      <div>
        <div className="flex flex-col items-center pt-16">
          <Heading type="page" tag="h2">
            You are {restake ? 'restaking' : 'unstaking'}&nbsp;
            <span>
              {addNumPunctuation(selectedAgreement.amount, 3)}&nbsp;
              {stakingOpts[hookKey].label}
            </span>
            &nbsp;tokens.
          </Heading>
          <p className="mb-16">
            You are additionally claiming your rewards of approximately &nbsp;
            <span>
              {addNumPunctuation(selectedAgreement.rewards, 1)}&nbsp;KAP.
            </span>
          </p>

          <div className="text-center">
            <Label>Claimed rewards will be locked for a 1 year period.</Label>
          </div>
        </div>

        <div className="flex justify-end items-center gap-4 mt-16">
          <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
          <div className="w-48">
            <CtaPrimary label="Unstake Now" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ModalContentWrapper>
  )
}
