/* eslint-disable @typescript-eslint/ban-ts-comment */
import { arc, pie, scaleOrdinal, select, selectAll } from 'd3'
import { useCallback, useEffect, useRef } from 'react'

import { colors } from '../colors'
import type { DonutChartValue } from '../types'

interface DonutProps {
  values: DonutChartValue[]
  title: string
  minSize: number
}

const margin = 0

export const Donut = (props: DonutProps) => {
  const { values, title, minSize } = props

  const graphId = `donut-graph-${title.replace(' ', '-')}`
  const segmentClass = `${graphId}-segment`

  // --------------------- ===
  //  STATE
  // ---------------------

  // --------------------- ===
  //  REFS
  // ---------------------
  const outerRef = useRef(null)

  // --------------------- ===
  //  FUNCS
  // ---------------------
  const renderGraph = useCallback(() => {
    // remove the old graph and tooltip
    select(`#${graphId}`).select('svg').remove()
    select(`#${graphId}`).select('div').remove()
    const outerWidth = outerRef.current.clientWidth
    const outerHeight = outerRef.current.clientHeight

    // since chart is a circle, take the smaller of the height and width and set dimensions to that
    const dimensions = outerWidth < outerHeight ? outerWidth : outerHeight
    const radius = dimensions / 2 - margin

    const svg = select(`#${graphId}`)
      .append('svg')
      .attr('width', dimensions)
      .attr('height', dimensions)
      .attr('overflow', 'visible')
      .append('g')
      .attr(
        'transform',
        // Center the graph horizontally by adding half of the free space to radius.
        `translate(${(outerWidth - dimensions) / 2 + radius}, ${radius})`
      )

    const tooltip = select(`#${graphId}`)
      .append('div')
      .style('visibility', 'hidden')
      .style('position', 'fixed')
      .style('color', '#f0e5d2')
      .style('background-color', '#262626')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    const mouseOver = (e, d) => {
      tooltip
        .style('visibility', 'visible')
        .text(`${d.data.label}: ${d.data.value}`)

      selectAll(`.${segmentClass}`).style('opacity', 0.25)

      select(e.target)
        // @ts-expect-error
        .style('stroke', color(d.data.label))
        .style('stroke-width', 2)
        .style('opacity', 1)
    }

    const mouseMove = (e, d) => {
      tooltip
        .style('top', e.pageY - 50 - window.scrollY + 'px')
        .style('left', e.pageX - 50 + 'px')
    }

    const mouseOut = (e) => {
      tooltip.style('visibility', 'hidden')
      selectAll(`.${segmentClass}`).style('opacity', 0.7)
      select(e.target).style('stroke', 'none')
    }

    const domainValues = []
    const colorValues = []

    // set color scale
    values.forEach((item, i) => {
      domainValues.push(item.value)
      colorValues.push(colors[i % colors.length])
    })

    const color = scaleOrdinal().domain(domainValues).range(colorValues)

    // @ts-expect-error
    const donutGenerator = pie().value((d) => d.value)
    // @ts-expect-error
    const donutData = donutGenerator(values)

    const plotArc = arc()
      .innerRadius(radius - 20)
      .outerRadius(radius)

    svg
      .selectAll()
      .data(donutData)
      .enter()
      .append('path')
      // @ts-expect-error
      .attr('d', plotArc)
      .attr('class', segmentClass)
      // @ts-expect-error
      .attr('fill', (d) => color(d.data.label))
      .style('opacity', 0.7)
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMove)
      .on('mouseout', mouseOut)
  }, [values])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    renderGraph()
    window.addEventListener('resize', renderGraph)
    return () => window.removeEventListener('resize', renderGraph)
  }, [renderGraph])

  return (
    <div
      id={graphId}
      className="w-full h-full"
      style={{ minHeight: minSize, minWidth: minSize }}
      ref={outerRef}
    />
  )
}
