import { useState } from 'react'

import type { DropdownProps } from '@pgl-apps/shared/types'

import { DropdownOption } from '../DropdownOption/DropdownOption'
import { DropdownTrigger } from '../DropdownTrigger/DropdownTrigger'
import { DropdownWrapper } from '../DropdownWrapper/DropdownWrapper'

export const DropdownCommon = (props: DropdownProps) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, options, onClick } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDown, setIsDown] = useState(false)

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <DropdownWrapper
      trigger={
        <DropdownTrigger
          label={label}
          onClick={() => setIsDown((prev) => !prev)}
          isDown={isDown}
        />
      }
      isDown={isDown}
    >
      {options.map((option) => (
        <DropdownOption
          onClick={() => {
            onClick(option)
            setIsDown(false)
          }}
          key={option}
        >
          {option}
        </DropdownOption>
      ))}
    </DropdownWrapper>
  )
}
