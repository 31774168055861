import detectEthereumProvider from '@metamask/detect-provider'
import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js'
import { BigNumber, providers } from 'ethers'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import {
  connectWallet,
  disconnectWallet,
  setIDs,
  useAppDispatch,
} from '@pgl-apps/kap-stake/redux'

export type WalletConnectModes = 'MetaMask' | 'WalletConnect'

export const useWallet = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const { ids, provider } = useSelector((store: IRootState) => store.wallet)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [walletConnectProvider] = useState(
    new WalletConnectProvider({
      infuraId: 'd09731fb14be432fbfc2718156110189',
    })
  )

  // --------------------- ===
  //  METHODS
  // ---------------------
  const _initListeners = useCallback(
    (rawProvider: providers.JsonRpcProvider) => {
      rawProvider.on('chainChanged', () => {
        console.log('Wallet: chain changed.')
        window.location.reload()
      })

      rawProvider.on('accountsChanged', (accounts: string[]) => {
        console.log('Wallet: accounts changed.')
        if (!accounts) {
          dispatch(disconnectWallet())
        } else {
          dispatch(setIDs(accounts))
        }
      })

      rawProvider.on('connect', (accounts: string[]) => {
        console.log('Connected accounts: ', accounts)
      })

      rawProvider.on('disconnect', () => {
        dispatch(disconnectWallet())
      })

      console.log('Added wallet listeners ...')
    },
    [provider]
  )

  const connect = useCallback(
    async (mode: WalletConnectModes) => {
      if (ids && ids.length) {
        // already connected
        throw 'You are already connected.'
      }

      let rawProvider
      let _provider: providers.Web3Provider
      let _ids: string[] = []

      if (mode === 'MetaMask') {
        rawProvider = await detectEthereumProvider()
        if (!rawProvider) {
          // metamask not installed!
          throw 'We are unable to connect to MetaMask. Check to make sure it is installed and unlocked, and try again.'
        }
        _ids = await (rawProvider as any)?.request({
          method: 'eth_requestAccounts',
        })
        _provider = new providers.Web3Provider(rawProvider)
      } else if (mode === 'WalletConnect') {
        if (!walletConnectProvider) {
          // not initialized
          throw 'We are having trouble connecting to Wallet Connect. Please try again.'
        }
        rawProvider = walletConnectProvider
        _ids = await walletConnectProvider.enable()
        _provider = new providers.Web3Provider(walletConnectProvider)
      }

      if (!_provider || !_ids) {
        // failed to initialize
        throw 'Wallet Connect failed to initialize.'
      }
      const _signer = _provider.getSigner()
      const { chainId } = await _provider.getNetwork()
      const _walletNetwork = BigNumber.from(chainId).toNumber()
      console.log('Connected network: ', _walletNetwork)

      _initListeners(rawProvider)

      dispatch(
        connectWallet({
          provider: _provider,
          ids: _ids,
          signer: _signer,
          walletNetwork: _walletNetwork,
        })
      )
    },
    [dispatch, ids, walletConnectProvider]
  )

  return {
    connect,
  }
}
