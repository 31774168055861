import { BigNumber, utils } from 'ethers'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'

export const useToken = (walletId: string) => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const tokens = useSelector((store: IRootState) => store.tokens)
  const {
    price: kapPrice,
    totalSupplyBN,
    balances: kapBalances,
  } = tokens[config.addresses.tokens.kap]
  const {
    totalSupplyBN: totalSupplyLpBN,
    token0,
    reserve0BN,
    reserve1BN,
    price: kapEthPrice,
    balances: kapEthBalances,
  } = tokens[config.addresses.tokens.kapEthLP]

  const totalStakedLpAmount =
    kapEthBalances[config.addresses.staking.kapEth] ?? BigNumber.from(0)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const marketCap = useMemo(
    () =>
      utils.formatEther(
        kapPrice
          ? totalSupplyBN
              .mul(BigNumber.from(Math.round(kapPrice * 1000000000)))
              .div(1000000000)
          : BigNumber.from(0)
      ),
    [kapPrice, totalSupplyBN]
  )

  // {stakedLP} * {kapReservesInLP} / {totalLP}
  const totalCirculatingLocked = useMemo(
    () =>
      totalStakedLpAmount
        .mul(token0 == config.addresses.tokens.kap ? reserve0BN : reserve1BN)
        .div(totalSupplyLpBN),
    [totalStakedLpAmount, totalSupplyLpBN]
  )

  // {stakedLP} * lpPrice
  const tvl = useMemo(
    () =>
      utils.formatEther(
        kapEthPrice
          ? totalStakedLpAmount
              .mul(BigNumber.from(Math.round(kapEthPrice * 1000000)))
              .div(1000000)
          : BigNumber.from(0)
      ),
    [kapEthPrice, totalStakedLpAmount]
  )

  // [TODO] Update by time, now 50,000,000 KAP
  const TEMP_CIRCULATING_SUPPLY = '50000000'
  const totalCirculating = useMemo(
    () => utils.parseEther(TEMP_CIRCULATING_SUPPLY),
    []
  )

  // Percent Circulating Staked
  const totalPercentLocked = useMemo(() => {
    return (
      (parseFloat(utils.formatEther(totalCirculatingLocked)) /
        parseFloat(TEMP_CIRCULATING_SUPPLY)) *
      100
    )
  }, [totalCirculatingLocked])

  return {
    kap: {
      price: kapPrice,
      balances: kapBalances,
      walletBalanceBN: kapBalances[walletId],
    },
    kapEth: {
      price: kapEthPrice,
      balances: kapEthBalances,
      walletBalanceBN: kapEthBalances[walletId],
    },
    marketCap,
    tvl,
    totalPercentLocked,
  }
}

export default useToken
