import type { InputConfig } from '@pgl-apps/shared/types'

export const defaultInputConfig: InputConfig = {
  value: '',
  label: '',
  isSuccess: false, // Depricate
  isHighlighted: false, // Depricate - Useful for showing changed fields
  isError: false, // Depricate
  isDisabled: false, // Depricate
  isRequired: false,
  state: 'normal',
  validation: () => '', // validation for this field. Required is automatically checked
}
