import type { Signer } from 'ethers'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { tokens, useAppDispatch, vestings } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { actionStatuses, isActionStatus } from '@pgl-apps/shared/helpers'
import { NotificationTypes } from '@pgl-apps/shared/types'

import { useNotifications } from './useNotifications'
import { useTransactions } from './useTransactions'

export const useVesting = (walletId: string, signer: Signer) => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const {
    initPendingTxn,
    updatePendingTxn,
    completePendingTxn,
    errorPendingTxn,
  } = useTransactions()

  const slice = useSelector((store: IRootState) => store.vestings)
  const { provider, agreements } = slice
  const loading = isActionStatus(
    slice,
    vestings.constants.FETCH_VESTING_AGREEMENTS,
    actionStatuses.PENDING
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { addNotification } = useNotifications()

  // --------------------- ===
  //  METHODS
  // ---------------------
  const collect = useCallback(
    async (agreementId: number) => {
      const newTxnID = initPendingTxn('Waiting for Confirmation')
      try {
        // connect the users web3 provider to the contract
        const userContract = provider.connect(signer)
        // sign and send transaction
        const txn = await userContract.collect(agreementId)
        addNotification({
          message: `Transaction submitted: ${txn.hash}`,
          type: NotificationTypes.info,
        })
        updatePendingTxn(
          newTxnID,
          txn.hash,
          'Collect is pending. This could take a few minutes.'
        )
        await txn.wait()
        completePendingTxn(newTxnID, txn.hash, 'Transaction Success.')
      } catch (err: any) {
        errorPendingTxn(newTxnID, 'Collect failed.')
      }

      // reload vesting agreements
      dispatch(vestings.actions.fetchVestingAgreements(walletId))
      // reload token balances
      dispatch(
        tokens.actions.fetchBalances(config.addresses.tokens.kap, [walletId])
      )
    },
    [provider, walletId]
  )

  const appointDelegate = useCallback(
    async (delegatee: string) => {
      const newTxnID = initPendingTxn(
        'Waiting on your approval to appoint delegate.'
      )
      try {
        // connect the users web3 provider to the contract
        const userContract = provider.connect(signer)
        // sign and send transaction
        const txn = await userContract.appointDelegate(delegatee)
        addNotification({
          message: `Transaction submitted: ${txn.hash}`,
          type: NotificationTypes.info,
        })
        updatePendingTxn(
          newTxnID,
          txn.hash,
          'Delegate is pending. This could take a few minutes.'
        )
        await txn.wait()
        completePendingTxn(newTxnID, txn.hash, 'Transaction Success.')
      } catch (err: any) {
        errorPendingTxn(newTxnID, 'Delegate failed.')
      }
    },
    [provider, walletId]
  )

  const undelegate = useCallback(async () => {
    const newTxnID = initPendingTxn('Waiting on your approval to undelegate.')
    try {
      // connect the users web3 provider to the contract
      const userContract = provider.connect(signer)
      // sign and send transaction
      const txn = await userContract.undelegate()
      addNotification({
        message: `Transaction submitted: ${txn.hash}`,
        type: NotificationTypes.info,
      })
      updatePendingTxn(
        newTxnID,
        txn.hash,
        'Undelegate is pending. This could take a few minutes.'
      )
      await txn.wait()
      completePendingTxn(newTxnID, txn.hash, 'Transaction Success.')
    } catch (err: any) {
      errorPendingTxn(newTxnID, 'Undelegate failed.')
    }
  }, [provider, walletId])

  return {
    loading,
    vestingAgreements: agreements,
    collect,
    appointDelegate,
    undelegate,
  }
}

export default useVesting
