import tailwindConfig from 'tailwind.base'
import type { Config } from 'tailwindcss'
import resolveConfig from 'tailwindcss/resolveConfig'

/* 
  This is a temporary solution to augument `fullTailwindConfig` object type 
  with 'bpNav' and 'bpTable' values defined in `tailwind.base.js`.
  Ideally, this type should be in the same file as the declaration.
*/
type AugumentedConfig = {
  theme: { screens: { bpNav: string; bpTable: string; bpSearch: string } }
}

export const fullTailwindConfig = resolveConfig(tailwindConfig) as Config &
  AugumentedConfig
