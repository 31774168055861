import { filter, words } from 'lodash'

export const alphabetize = (list: string[]) => {
  return list.sort((a, b) =>
    a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
  )
}

export const initialsFromUsername = (username: string) => {
  let sanitizedUsername = username

  // removing discord discriminator:
  if (username.match(/#\d{4}$/gm)) {
    sanitizedUsername = username.substring(0, username.length - 5)
  }

  const initials = words(sanitizedUsername).reduce((prev, current) => {
    prev += current.charAt(0).toUpperCase()
    return prev
  }, '')

  return initials
}

export const searchInArray = (
  search: string,
  arr: string[],
  isCaseInsensitive = true
) => {
  const list = isCaseInsensitive ? arr.map((s) => s.toLocaleLowerCase()) : arr
  return filter(list, (s) => s.indexOf(search) !== -1)
}
