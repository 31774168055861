import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import { usePrevious } from './react'

type PagedValues = Record<number, { length: number }>

interface ParamsWithPage {
  [key: string]: any
  limit: number
  skip: number
}

interface HookParams {
  pageSize?: number
  initialPage?: number
  isPaused?: boolean
}

// --------------------- ===
//  HELPERS
// ---------------------
const countPagedValues = (pagedValues: PagedValues) => {
  let currentCount = 0
  Object.keys(pagedValues).forEach((key) => {
    currentCount += pagedValues[key].length
  })
  return currentCount
}
const checkIfPageHasVals = (data: any, page: number, pageSize: number) =>
  data?.count > page * pageSize
// Check if the data has a next page
const checkHasNextPage = (data: any, pagedValues: PagedValues) => {
  if (!data) return true
  const currentCount = countPagedValues(pagedValues)
  return data?.count > currentCount
}

// Finds the first page, low to high, that doesn't have any values
const findNextPage = (pg: number, pagedValues: PagedValues) => {
  if (!pagedValues[pg]) return pg
  else return findNextPage(pg + 1, pagedValues)
}

const getCurrentPage = (paramsWithPage: ParamsWithPage, pageSize: number) =>
  Math.ceil(paramsWithPage.skip / paramsWithPage.limit)

// TODO data.status is depricated, but seems to be more reliable than isFetching and isSuccess, which are sometimes both true

const defaultHookParams: HookParams = {
  pageSize: 25,
  initialPage: 0, // initial page and will reset to this page
  isPaused: false, // using "paused" instead of "skip" to not confuse it with our api's skip param
}

// --------------------- ===
//  HOOK: USE CUMULATIVE QUERY
// ---------------------
export const useCumulativeQuery = (
  useQuery: any,
  // useQuery: UseQuery<any>, // TS wasn't happy, but this should be correctly typed

  params: ParamsWithPage['params'], // should be a memorized obj
  hookParams?: HookParams
  // TODO change below to single hookParams obj
  // pageSize = 25,
  // initialPage = 0,
  // isPaused = false
) => {
  // --------------------- ===
  //  REFS
  // ---------------------
  const isFetching = useRef(true)
  const fetchOnComplete = useRef(false)
  const count = useRef<number>(null)
  const initialHookParams = useRef<HookParams>({
    ...defaultHookParams,
    ...(hookParams || {}),
  })

  // --------------------- ===
  //  STATE
  // ---------------------
  const [requestedPage, setRequestedPage] = useState(
    initialHookParams.current.initialPage
  )
  const [pagedValues, setPagedValues] = useState<PagedValues>({})
  const [paramsWithPage, setParamsWithPage] = useState<ParamsWithPage>({
    ...params,
    limit: initialHookParams.current.pageSize, // Let effect make all api calls
    skip: 0,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceReload, setForceReload] = useState(false)

  // --------------------- ===
  //  QUERIES
  // ---------------------
  const result = useQuery(paramsWithPage, {
    skip: initialHookParams.current.isPaused,
  })

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  const prevParams = usePrevious(params, params)
  // const prevIsPaused = usePrevious(isPaused, isPaused)
  // RESET if params change
  useEffect(() => {
    if (!_.isEqual(params, prevParams)) {
      setParamsWithPage({
        ...params,
        limit: initialHookParams.current.pageSize,
        skip: 0,
      })
      setRequestedPage(initialHookParams.current.initialPage)
      setPagedValues({})
      isFetching.current = true
      fetchOnComplete.current = false
    }
  }, [params, prevParams])

  // BUILD PAGED VALUES
  useEffect(() => {
    // Everytime the request succeeds, add it to our pagedValues
    if (result.status === 'fulfilled') {
      if (result?.data?.count) {
        count.current = result.data.count
      }
      setPagedValues((prev) => {
        const newValues = {
          ...prev,
        }
        newValues[
          getCurrentPage(paramsWithPage, initialHookParams.current.pageSize)
        ] = result.data?.values
        return newValues
      })
    }
  }, [result])

  // REQUEST PAGES
  useEffect(() => {
    const resolveWaterfall = () => {
      // Finish waterfall
      isFetching.current = false
      setForceReload((prev) => !prev) // need a state change for isFetching to be updated in consumer
      // If another page has been requested, request it
      if (fetchOnComplete.current) {
        fetchOnComplete.current = false // reset
        requestNextPage()
      }
    }
    // If there's not a current request, request each page until we get to the requestedPage
    if (result?.status !== 'pending') {
      // If current page is below the requested page
      if (
        requestedPage >
        getCurrentPage(paramsWithPage, initialHookParams.current.pageSize)
      ) {
        // Start waterfall
        isFetching.current = true // while we try the current request, don't allow new ones
        const fetchPage = findNextPage(0, pagedValues) // Find which page to fetch
        if (
          !checkIfPageHasVals(
            result?.data,
            fetchPage,
            initialHookParams.current.pageSize
          )
        ) {
          resolveWaterfall()
          return
        }
        // Adjust params to call the query
        setParamsWithPage((prev) => ({
          ...prev,
          limit: initialHookParams.current.pageSize,
          skip: initialHookParams.current.pageSize * fetchPage,
        }))
      } else {
        resolveWaterfall()
      }
    }
  }, [requestedPage, pagedValues])

  // --------------------- ===
  //  METHODS
  // ---------------------
  const requestNextPage = useCallback(() => {
    if (isFetching.current) {
      fetchOnComplete.current = true
    } else if (checkHasNextPage(result?.data, pagedValues)) {
      setRequestedPage((prev) => prev + 1)
    }
    // Else, nothing to fetch
  }, [result, pagedValues])

  const requestPage = useCallback((page: number) => {
    // Calls to api will loop from 0 to the last call with values
    // Aka, if you request page 1000, it might just get pages 0-3 -- wherever there are no more values
    setRequestedPage(page)
  }, [])

  // --------------------- ===
  //  RETURN
  // ---------------------
  return {
    pagedValues,
    hasNextPage: checkHasNextPage(result?.data, pagedValues),
    requestNextPage,
    requestPage,
    isFetching: isFetching.current, // cumulative isFetching
    count: count.current,
    result, // might not ever need this
  }
}
