import type { ChangeEvent } from 'react'
import { useState } from 'react'

import type { InputConfig } from '@pgl-apps/shared/types'

import { InputWrapper } from '../private/InputWrapper'

interface Props {
  inputConfig: InputConfig
  label?: string
  options: string[]
  onChange: (arg0: string) => void
  hasSubmitErrors?: boolean // kinda hacky. TODO improve
  placeholder?: string
  required?: boolean
  hideLabel?: boolean
}

// TODO depricate "is" properties (isLoading, isDisabled, ...) replace with "state"
export const Select = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    label,
    options,
    onChange,
    hasSubmitErrors = false,
    placeholder = '',
    required = false,
    hideLabel = false,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [didBlur, setDidBlur] = useState(false)

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    onChange(evt.target.value)
  }

  const handleBlur = () => {
    setDidBlur(true)
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  const isDisabled =
    inputConfig.isDisabled || // depricate
    inputConfig.state === 'disabled' ||
    inputConfig.state === 'loading'

  const highlightClass =
    inputConfig.isHighlighted || inputConfig.state === 'highlighted'
      ? '!border-theme-highlighted'
      : ''

  return (
    <InputWrapper
      label={label}
      hideLabel={hideLabel}
      inputConfig={inputConfig}
      required={required}
      canShowError={hasSubmitErrors || didBlur}
    >
      <select
        className={`w-full pr-10 ${
          inputConfig.value ? '' : 'invalid'
        } ${highlightClass}`}
        onChange={(evt: ChangeEvent<HTMLSelectElement>) =>
          isDisabled || inputConfig.state === 'loading'
            ? () => null
            : handleChange(evt)
        }
        onBlur={handleBlur}
        value={inputConfig.value}
        disabled={isDisabled}
        name={inputConfig.label || label}
        required={required}
      >
        {placeholder && (
          <option value="" disabled>{`${placeholder}...`}</option>
        )}
        {options.map((o) => (
          <option key={`option-${o}`} value={o}>
            {o}
          </option>
        ))}
      </select>
    </InputWrapper>
  )
}
