import type { WizardStep } from '../WizardWrapper'
import { Circle } from './Circle'

interface ProgressBarProps {
  steps: WizardStep[]
  currentStep?: number
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { steps, currentStep } = props

  return (
    <div className="relative hidden md:flex w-[50%] justify-between items-center mb-8">
      <div className="h-px bg-theme-muted absolute top-1/2 left-0 right-0" />
      {steps.map((step, index) => (
        <div className="relative" key={index}>
          <Circle isPending={index + 1 > currentStep} />
        </div>
      ))}
    </div>
  )
}
