export const convertToEthereum = (address: string) => {
  if (address.slice(0, 6) === 'ronin:') return address.replace('ronin:', '0x')
  return address
}

export const convertToRonin = (address: string) => {
  if (address.slice(0, 2) === '0x') return address.replace('0x', 'ronin:')
  return address
}

export const convertForGame = (game, address) => {
  // if (game === 'Axie Infinity') {
  //   return convertToRonin(address)
  // }

  return address
}

export const shortenAddress = (address: string) => {
  if (address) {
    if (address.includes('ronin')) {
      return `${address.slice(0, 9)}...${address.slice(-3, address.length)}`
    } else if (address.slice(0, 2) === '0x') {
      return `${address.slice(0, 6)}...${address.slice(-4, address.length)}`
    }
  }

  return ''
}
