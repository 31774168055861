import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useRewardsLocker } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { Heading } from '@pgl-apps/shared/components'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../buttons'
import { ModalContentWrapper } from '../../ModalContentWrapper'
import { CancelBtn } from '../Claim/private/CancelBtn'

interface Props {
  agreementId?: number
  onCloseModal: () => void
}
export const CollectLockedRewards = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { agreementId, onCloseModal } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const signer = useSelector((store: IRootState) => store.wallet.signer)
  const kapPrice = useSelector(
    (store: IRootState) => store.tokens[config.addresses.tokens.kap].price
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { lockAgreements, collectRewards } = useRewardsLocker(walletId, signer)

  const lockedAmount = useMemo(() => {
    const agreement = lockAgreements.find((a) => a.id == agreementId)
    return Date.now() > agreement.availableTimestamp * 1000
      ? agreement.amount
      : 0
  }, [lockAgreements, agreementId])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleSubmit = () => {
    collectRewards(agreementId)
    onCloseModal()
  }

  return (
    <ModalContentWrapper>
      <div>
        <div className="flex flex-col items-center pt-16">
          <Heading type="page" tag="h2">
            Are you sure you want to collect locked rewards?
          </Heading>
          <p className="mb-16">
            You are collecting &nbsp;
            <span>{addNumPunctuation(lockedAmount, 1)}</span>
            &nbsp;
            <span>KAP</span>
            <span>(Est. ${addNumPunctuation(kapPrice * lockedAmount, 1)})</span>
          </p>
        </div>

        <div className="flex justify-end items-center gap-4 mt-16">
          <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
          <div className="w-48">
            <CtaPrimary label="Collect Now" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ModalContentWrapper>
  )
}
