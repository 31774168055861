import { useState } from 'react'

import type { InputConfig } from '@pgl-apps/shared/types'

import { DropdownOption, DropdownWrapper } from '../../utility'
import { Suggestion } from '../../utility/dropdown/private/Suggestion'
import { InputWithSuffix } from '../InputWithSuffix/InputWithSuffix'

interface Props {
  inputConfig: InputConfig
  className?: string
  onChange: (arg0: string) => void
  hasSubmitErrors?: boolean
  suggestionArr: string[]
  hideLabel?: boolean
  placeholder?: string
  suffix: React.ReactNode
  showSuffix?: boolean
}

export const InputWithSuggestions = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    inputConfig,
    className = 'w-full',
    onChange,
    hasSubmitErrors = false,
    suggestionArr,
    hideLabel = true,
    placeholder = 'Search',
    suffix,
    showSuffix = true,
  } = props

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isOpen, setOpen] = useState<boolean>(false)

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div>
      <DropdownWrapper
        className={className}
        trigger={
          <InputWithSuffix
            inputConfig={inputConfig}
            onChange={onChange}
            onFocus={() => setOpen(true)}
            onBlur={(e) => {
              if (
                !e.currentTarget.contains(e.relatedTarget) &&
                // Don't close the dtopdown option list if it was clicked on an option.
                // The dropdown option will close the list after setting the selected value.
                !e.relatedTarget?.classList.contains('dropdown-option')
              ) {
                // Not triggered when swapping focus between children
                setOpen(false)
              }
            }}
            placeholder={placeholder}
            hideLabel={hideLabel}
            required // hack to keep border 'active' color
            hasSubmitErrors={hasSubmitErrors}
            suffix={suffix}
            showSuffix={showSuffix}
          />
        }
        isDown={isOpen}
      >
        {suggestionArr.map((suggestion) =>
          suggestion.toLowerCase().includes(inputConfig.value.toLowerCase()) ? (
            <DropdownOption
              key={suggestion}
              onClick={() => {
                onChange(suggestion)
                setOpen(false)
              }}
            >
              <Suggestion
                searchValue={inputConfig.value}
                suggestion={suggestion}
              />
            </DropdownOption>
          ) : null
        )}
      </DropdownWrapper>
    </div>
  )
}
