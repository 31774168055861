export const notificationTypes = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
}

// TODO improve to make customer facing
export const commonErrorMsgs = {
  400: 'Bad Request.',
  401: 'Unauthorized.',
  403: 'Forbidden.',
  404: 'Not Found.',
  422: 'Unable to process your request.',
  500: 'Internal Server Error.',
  502: 'Bad Gateway.',
  503: 'Service Unavailable.',
  504: 'Gateway Timeout.',
}
