// import { notificationTypes } from '@pgl-apps/shared/constants' // TODO was circular dependency. Should update to use enum TODO

// Used in useStakingTemplate
export interface TxHash {
  id: number | string
  txHash: string
  status: 'pending' | 'complete' | 'failed'
  message: string
}

// --------------------- ===
//  NOTIFICATION FEATURE SLICE
// ---------------------
export enum NotificationTypes {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

export interface NotificationArg {
  message: string
  type: NotificationTypes
}
export interface ClearNotificationArg extends NotificationArg {
  id: string
}

// --------------------- ===
//  SPINNER
// ---------------------
export type SpinnerStatuses = 'pending' | 'complete' | 'failed'
