import { Heading } from '@pgl-apps/shared/components'

import { CtaPrimary } from '../../../../buttons'
import { CommonHeader } from '../CommonHeader'

interface Props {
  label: string
  onClick: () => void
  currentKey: string
  setCurrentKey: (arg: string) => void
}

export const ApproveStep = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, onClick, currentKey, setCurrentKey } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div>
      <CommonHeader currentKey={currentKey} setCurrentKey={setCurrentKey} />
      <div className="text-align-center flex flex-col gap-8 mt-12">
        <Heading type="article">{`Approve ${label} to be staked`}</Heading>
        <p className="mb-12">{`Before you stake, you must approve ${label} on your wallet.`}</p>
        <CtaPrimary onClick={onClick} label={`Approve ${label}`} />
      </div>
    </div>
  )
}
