import type { SVGR } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

interface Props {
  isActive: boolean
  onClick: () => void
  Icon: SVGR
  label: string
}

// TODO this could get merged with LeftNavigation's NavItem
// when that gets cleaned up
export const TabBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { isActive, onClick, Icon, label } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const activeClass = isActive ? styles.btn__active : ''
  return (
    <button
      className={`${activeClass} ${styles.btn}`}
      onClick={onClick}
      type="button"
    >
      <span className="w-4 mr-2 h-full flex align-middle">{<Icon />}</span>
      <span>{label}</span>
    </button>
  )
}
