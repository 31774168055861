import type { FunctionComponent, SVGProps } from 'react'

// type SVGR = FunctionComponent<SVGProps<SVGSVGElement>>
export type SVGR = FunctionComponent<SVGProps<SVGSVGElement>>

// declare module "*.svg" {
//   import React = require("react");
//   const src: React.FC<React.SVGProps<SVGSVGElement>>;
//   export default src;
// }
