import { utils } from 'ethers'
import { useSelector } from 'react-redux'

import { stakingOpts, useToken } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { Label, Tag } from '@pgl-apps/shared/components'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

interface Props {
  currentKey: string
  setCurrentKey: (arg: string) => void
}

export const CommonHeader = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { currentKey, setCurrentKey } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const { walletBalanceBN } = useToken(walletId)[currentKey]

  return (
    <div className="divider-b flex flex-wrap w-full items-center justify-between">
      <div className="flex">
        <button
          key={stakingOpts.kapEth.key}
          className="mr-2 hover:underline"
          onClick={() => setCurrentKey(stakingOpts.kapEth.key)}
          type="button"
        >
          <Tag
            type={currentKey === stakingOpts.kapEth.key ? 'default' : 'none'}
          >
            {stakingOpts.kapEth.label}
          </Tag>
        </button>
      </div>
      <div className="flex flex-col text-left">
        <Label>Balance</Label>
        <div>
          <span className="text-xl">
            {addNumPunctuation(utils.formatEther(walletBalanceBN), 2)}
          </span>
          <Label className="ms-2 text-uppercase">
            {stakingOpts[currentKey].label}
          </Label>
        </div>
      </div>
    </div>
  )
}
