import type { Status as StatusProps } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

interface Props {
  type: StatusProps
  label: string
}

const badgeClasses = {
  success: styles.success,
  error: styles.error,
  warning: styles.warning,
  info: styles.info,
  inactive: styles.inactive,
}

export const Badge = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { type, label } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <span className={`capitalize ${badgeClasses[type]} ${styles.badgeWrapper}`}>
      {label}
    </span>
  )
}
