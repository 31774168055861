import type { ReactNode } from 'react'

import type { SVGR } from '@pgl-apps/shared/types'

import type { HeadingColors } from '../../../typography'
import { Heading } from '../../../typography'
import { ContentBox } from '../ContentBox'

interface Props {
  children: ReactNode
  color?: HeadingColors
  Icon?: SVGR
  label: string
}

export const SecondaryBox = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, color = 'muted', label, Icon = null } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  // TODO
  return (
    <ContentBox>
      <div>
        <Heading tag="h4" type="paragraph" color={color}>
          <span className="flex">
            {Icon && <Icon className="w-4 mr-2 fill-current" />}
            {label}
          </span>
        </Heading>
        {children}
      </div>
    </ContentBox>
  )
}
