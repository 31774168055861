/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { commonErrorMsgs } from '@pgl-apps/shared/constants'

export const buildErrorMessage = (
  error: FetchBaseQueryError | SerializedError,
  customErrorMsg: string
): string => {
  if (customErrorMsg) return customErrorMsg
  if (error) {
    // TODO what's the TS way to check if a entity exists?
    // @ts-expect-error
    if (error.data?.detail) return error.data.detail
    // @ts-expect-error
    if (error.status && commonErrorMsgs[error.status])
      // @ts-expect-error
      return commonErrorMsgs[error.status]
    return 'An unknown error has occured.'
  }
  return ''
}
