import { utils } from 'ethers'
import type { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { apyDisclaimer } from '@pgl-apps/kap-stake/constants'
import type { useStakingTemplate } from '@pgl-apps/kap-stake/hooks'
import { useToken } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { Input, Label } from '@pgl-apps/shared/components'
import { defaultInputConfig } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { CtaPrimary } from '../../../../buttons'
import { DurationSlider } from '../../../../lockups'
import { AprBox } from '../../../../messaging'
import { CommonHeader } from '../CommonHeader'

interface Props {
  currentStakingHook: ReturnType<typeof useStakingTemplate>
  label: string
  currentKey: string
  setCurrentKey: (arg: string) => void
  onCloseModal: () => void
  defaultAmount?: string
}

const validate = (val: string, balance: BigNumber) => {
  if (!val) return 'Amount cannot be blank.'
  const valBN = utils.parseEther(val)
  if (!(parseFloat(val) > 0)) return 'Amount must be greater than 0.'
  if (valBN.gt(balance)) return 'Amount is greater than your balance.'
  return ''
}

const secondsPerWeek = 604800

export const StakeStep = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    currentStakingHook,
    defaultAmount,
    currentKey,
    setCurrentKey,
    onCloseModal,
  } = props

  // --------------------- ===
  //  STORE
  // ---------------------
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const { price, walletBalanceBN } = useToken(walletId)[currentKey]

  // --------------------- ===
  //  STATE
  // ---------------------
  const [hasInputErrors, setHasInputErrors] = useState(false)

  // --------------------- ===
  //  HOOKS
  // ---------------------
  useEffect(() => {
    if (defaultAmount) {
      currentStakingHook.setStakeInputAmount(defaultAmount)
    }
  }, [])

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleInputChange = (val: string) => {
    currentStakingHook.setStakeInputAmount(val)
  }

  const handleSubmit = () => {
    const err = validate(currentStakingHook.stakeInputAmount, walletBalanceBN)
    setHasInputErrors(err.length > 0)
    if (!err) {
      currentStakingHook.stake()
      onCloseModal()
    }
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  const { stakeInputAmount, lockPeriodLimits } = currentStakingHook
  const amountInputConfig = {
    ...defaultInputConfig,
    value: stakeInputAmount,
    validation: (val) => validate(val, walletBalanceBN),
  }
  const totalPriceBN = useMemo(
    () =>
      utils
        .parseEther(stakeInputAmount || '0')
        .mul(Math.ceil(Number(price) * 1000000) || 0)
        .div(1000000),
    [stakeInputAmount, price]
  )

  return (
    <div>
      <CommonHeader currentKey={currentKey} setCurrentKey={setCurrentKey} />
      <div className="divider-b text-left py-4 grid grid-cols-1 sm:grid-cols-2">
        <div className="flex items-center content-center mx-auto">
          <div className="flex flex-col content-center">
            <div className="flex items-end">
              <Input
                inputConfig={amountInputConfig}
                label="Staking Amount"
                type="number"
                onChange={(val) => handleInputChange(val)}
                placeholder="0"
                hasSubmitErrors={hasInputErrors}
              />
            </div>
            <div className="mt-3">
              <div>
                <button
                  onClick={() =>
                    handleInputChange(utils.formatEther(walletBalanceBN))
                  }
                  type="button"
                  className="text-sm text-theme-muted underline"
                >
                  Add Max
                </button>
              </div>
              <p className="text-start">
                {/* <Label>{`$${stakeInputAmount ? addNumPunctuation(parseInt(stakeInputAmount, 10) * price) : 0}`}</Label> */}
                <Label>
                  {`
                    $${addNumPunctuation(utils.formatEther(totalPriceBN), 2)}
                  `}
                </Label>
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center content-center mx-auto">
          {
            // Wait for lockPeriodLimits to be created
            lockPeriodLimits.min.toNumber() <
              lockPeriodLimits.max.toNumber() && (
              <DurationSlider
                min={lockPeriodLimits.min.div(secondsPerWeek).toNumber()} // seconds in a week
                max={lockPeriodLimits.max.div(secondsPerWeek).toNumber()} // seconds in a week
                onChange={(weeks: number) =>
                  currentStakingHook.setCurrentLockPeriod(
                    weeks * secondsPerWeek
                  )
                }
                label="Week"
                value={Math.round(
                  currentStakingHook.currentLockPeriod / secondsPerWeek
                )}
                title="Lock Duration"
              />
            )
          }
        </div>
      </div>
      <div className="flex flex-wrap sm:flex-nowrap justify-between items-center mt-4 gap-4">
        <div className="flex items-center w-full sm:w-1/4">
          <div className="grow basis-full">
            <AprBox
              label="Est Apr"
              unit="%"
              value={currentStakingHook.currentApr}
            />
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <CtaPrimary onClick={handleSubmit} label="Stake" />
        </div>
      </div>
      <p className="text-xs text-theme-muted text-start mt-6">
        <span className="align-super">*</span>&nbsp;
        {apyDisclaimer}&nbsp;
        <a
          href="https://docs.kapital.gg/disclaimer"
          target="_blank"
          rel="noreferrer"
          className="text-xs underline"
        >
          See More
        </a>
      </p>
    </div>
  )
}
