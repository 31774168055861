import { TailSpin, ThreeDots } from 'react-loader-spinner'

// TODO Loader was maybe from an older version of the package?

export const Loading = ({
  className = 'text-theme-highlighted',
  type = '',
  height = 100,
  width = 100,
}) => {
  if (type === 'ThreeDots') {
    return <ThreeDots wrapperClass={className} height={height} width={width} />
  }

  return (
    <TailSpin
      wrapperClass={className}
      color={'#d4a859'}
      height={height}
      width={width}
    />
  )
}
