import { combineReducers, configureStore } from '@reduxjs/toolkit'
import LogRocket from 'logrocket'
import { useDispatch } from 'react-redux'
import { createPromise } from 'redux-promise-middleware'

import { promiseTypeDelimiter } from '@pgl-apps/shared/helpers'

// import { kap } from './features/contracts/kap'
import { kapEth } from './features/contracts/kapEth'
import rewards from './features/contracts/rewards'
import vestings from './features/contracts/vestings'
import ethers from './features/ethers'
import modal from './features/modal'
import { notifications } from './features/notifications'
import tokens from './features/tokens'
import transactions from './features/transactions'
import wallet from './features/wallet'

const reducer = combineReducers({
  ethers,
  modal,
  notifications: notifications.reducer,
  transactions,
  wallet,
  tokens,
  // kap: kap.reducer,
  kapEth: kapEth.reducer,
  rewards,
  vestings,
})

export const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      createPromise({
        promiseTypeDelimiter: promiseTypeDelimiter,
      }),
      // keep last
      LogRocket.reduxMiddleware()
    ),
})

export type IRootState = ReturnType<typeof reducer>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
