import type { ReactNode } from 'react'

import styles from './index.module.scss'

interface Props {
  children: ReactNode
  className?: string
}

export const Label = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { children, className = '' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <span className={`${styles.label} ${className}`}>{children}</span>
}
