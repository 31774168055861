import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createReducer } from '@reduxjs/toolkit'
import type { Signer, providers } from 'ethers'

// --------------------- ===
//  SETUP
// ---------------------
const sliceName = 'wallet'

const CONNECT_WALLET = `${sliceName}/CONNECT_WALLET`
const DISCONNECT_WALLET = `${sliceName}/DISCONNECT_WALLET`
const SET_IDS = `${sliceName}/SET_IDS`
const SET_WALLET_NETWORK = `${sliceName}/SET_WALLET_NETWORK`

// --------------------- ===
//  INITIAL STATE
// ---------------------
const initialState: {
  provider?: providers.Web3Provider
  signer?: Signer
  ids: string[]
  walletNetwork?: number
} = {
  ids: [],
}

// --------------------- ===
//  ACTIONS
// ---------------------
interface ConnectPayload {
  provider: providers.Web3Provider
  ids: string[]
  signer: Signer
  walletNetwork: number
}

export const connectWallet = createAction<ConnectPayload>(CONNECT_WALLET)
export const disconnectWallet = createAction(DISCONNECT_WALLET)
export const setIDs = createAction<string[]>(SET_IDS)
export const setWalletNetwork = createAction<number>(SET_WALLET_NETWORK)

// --------------------- ===
//  REDUCER
// ---------------------
// IMPORTANT: All addCases(s) must come before addMatcher(s)
// https://redux-toolkit.js.org/api/createreducer#builderaddmatcher
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(connectWallet, (state, action: PayloadAction<ConnectPayload>) => {
      state.provider = action.payload.provider
      state.ids = action.payload.ids
      state.signer = action.payload.signer
      state.walletNetwork = action.payload.walletNetwork
    })
    .addCase(disconnectWallet, (state) => {
      state.provider = null
      state.ids = []
      state.signer = null
      state.walletNetwork = null
    })
    .addCase(setIDs, (state, action: PayloadAction<string[]>) => {
      state.ids = [...action.payload]
    })
    .addCase(setWalletNetwork, (state, action: PayloadAction<number>) => {
      state.walletNetwork = action.payload
    })
})

export default reducer
