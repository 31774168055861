import React from 'react'

interface Props {
  label: string
  onClick: () => void
}

export const ActionItem = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, onClick } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      className="text-theme-base hover:underline text-left"
      onClick={onClick}
      type="button"
    >
      {label}
    </button>
  )
}
