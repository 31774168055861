import styles from '../GraphWrapper.module.scss'

interface Props {
  color: string
  label: string
  isActive: boolean
  onClick: () => void
}

export const FilterTag = (props: Props) => {
  const { color, label, isActive, onClick } = props
  return (
    <button
      className={styles.filterTag}
      onClick={onClick}
      type="button"
      style={{ color: color }}
    >
      <div
        className={`${styles.colorChip} ${
          isActive ? styles.colorChip__active : ''
        }`}
      />
      <div className={styles.labelText}>{label}</div>
    </button>
  )
}
