// import kapCoinSplit from '@pgl-apps/shared-images/logos/kap-coin-split.png'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

// import coin3 from './coin-overlay-3.png'
import coin3 from '@pgl-apps/shared-images/logos/kap-games-icon.svg'

import styles from './coin.module.scss'

interface Props {
  startAnimation: boolean
  isError: boolean
}

const duration = 1 // animation total
const delay = 0.1

export const Coin = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { startAnimation, isError } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const innerRef = useRef<HTMLDivElement>(null)
  const slotRef = useRef<HTMLDivElement>(null)
  const coverRef = useRef<HTMLDivElement>(null)

  // --------------------- ===
  //  ANIMATION
  // ---------------------
  useEffect(() => {
    if (startAnimation) {
      // open slot
      gsap.set(slotRef.current, {
        overflow: 'visible',
      })
      gsap.to(slotRef.current, {
        duration: duration / 4,
        width: '100%',
        left: 0,
      })
      gsap.to(coverRef.current, {
        duration: duration / 4,
        width: '110%',
        marginLeft: '-5%',
      })

      // up coin
      gsap.to(innerRef.current, {
        duration: duration / 2,
        translateY: '-125%',
        ease: 'expo.out',
      })
      gsap.to(innerRef.current, {
        duration: duration / 2,
        rotateY: 10,
        ease: 'none',
      })

      // down coin
      gsap.to(innerRef.current, {
        duration: duration / 3,
        translateY: '300%',
        ease: 'expo.in',
        delay: duration / 2 + delay,
        // onComplete: () => gsap.set(innerRef.current, { display: 'none' }),
      })
      gsap.to(innerRef.current, {
        duration: duration / 3,
        rotateY: -10,
        ease: 'none',
        delay: duration / 2 + delay,
      })

      // Close
      gsap.to(slotRef.current, {
        duration: duration / 2,
        left: '50%',
        width: 0,
        delay: duration + delay,
      })
    } else {
      // Reset
      gsap.set(innerRef.current, {
        translateY: 0,
      })
      gsap.set(coverRef.current, {
        width: 0,
        marginLeft: '0%',
      })
      gsap.set(slotRef.current, {
        overflow: 'hidden',
        left: '50%',
        width: 0,
      })
    }
  }, [startAnimation])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className={`w-full relative ${styles.wrapper}`}>
      <div className={styles.inner} ref={innerRef}>
        <div
          className={`${styles.frontInner} ${
            isError ? styles.frontInner__err : ''
          }`}
        />
        <div
          className={`${styles.backInner} ${
            isError ? styles.backInner__err : ''
          }`}
        />
        <div className={styles.front}>
          <img className="absolute top-0 left-0" src={coin3} alt="" />
        </div>
        <div className={styles.back}>
          <img className="relative" src={coin3} alt="" />
        </div>
      </div>
      <div
        className={`-z-lift absolute w-0 h-4 overflow-hidden left-1/2 ${styles.slotWrapper}`}
        ref={slotRef}
      >
        <div
          className={`w-full h-full bg-gradient-to-r fr from-theme-highlighted via-yellow-500 to-theme-highlighted absolute z-lift -top-3 shado ${styles.slot}`}
        />
      </div>
      <div className="absolute z-lift w-0 h-6 overflow-hidden" ref={coverRef}>
        <div className="w-full h-full border-b-theme-highlighted bg-theme-containerBg absolute top-0" />
      </div>
    </div>
  )
}
