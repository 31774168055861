import { isAddress } from 'ethers/lib/utils'

import type { InputConfig, InputConfigCheckbox } from '@pgl-apps/shared/types'

import { convertToEthereum } from './addresses'

export const validateEmail = (email: string) => {
  const isValid = !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

  return isValid ? '' : 'Please enter a valid email address.'
}

export const validateWalletAddress = (address: string, acceptRonin = false) => {
  let addr = address
  if (acceptRonin) {
    addr = convertToEthereum(address)
  }
  if (!isAddress(addr)) {
    return 'Please enter a valid address.'
  }
  return ''
}

export const validateForm = (inputs: InputConfig[]) => {
  const errorMsgs: string[] = []

  inputs.forEach((input) => {
    // Check field validation
    const errorMsg = validateField(input)
    if (errorMsg) errorMsgs.push(errorMsg)
  })
  return errorMsgs
}

export const validateField = (input: InputConfig | InputConfigCheckbox) => {
  let errorMsg = ''
  if (input.validation && input.validation(input.value)) {
    errorMsg = input.validation(input.value)
  }
  // value can be boolean in InputConfigCheckbox, so need to check for empty string
  if (input.isRequired && input.value === '') {
    errorMsg = `${input.label} is a required field`
  }
  return errorMsg
}
