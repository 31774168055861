import { config } from '@pgl-apps/shared/api'
import { shortenAddress } from '@pgl-apps/shared/helpers'

import { TextLink } from '../TextLink/TextLink'

interface Props {
  txHash: string
  blockExplorer?: string // not sure if this is the best approach
}

export const TxLink = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { txHash, blockExplorer = config.blockExplorer } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <TextLink type="external" path={`${blockExplorer}${txHash}`}>
      {shortenAddress(txHash)}
    </TextLink>
  )
}
