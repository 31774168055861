import { Checkbox } from '../../../../form'
import styles from './index.module.scss'

interface Props {
  onSelectClick?: () => void
  isSelected?: boolean
  isHeader?: boolean
  isAllSelected?: boolean
  isSomeSelected?: boolean
  isDisabled?: boolean
}

export const SelectCell = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onSelectClick = () => null,
    isSelected = false,
    isHeader = false,
    isAllSelected = false,
    isSomeSelected = false,
    isDisabled = false,
  } = props

  // --------------------- ===
  //  HELPERS
  // ---------------------
  const getState = () => {
    if (isHeader) {
      if (isAllSelected) {
        return 'checked'
      }
      if (isSomeSelected) {
        return 'somechecked'
      }
    }
    if (isSelected) {
      return 'checked'
    }
    return 'unchecked'
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className={`${styles.wrapper} absolute left-0 top-0 flex items-center`}
    >
      <Checkbox
        onClick={onSelectClick}
        state={getState()}
        isDisabled={isDisabled}
      />
    </div>
  )
}
