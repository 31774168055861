import { colors } from '../../colors'
import type { DonutChartValue } from '../../types'

interface Props {
  values: DonutChartValue[]
  sum: number
}

export const ValuesKey = (props: Props) => {
  const { values, sum } = props
  return (
    <>
      {values.map((item, i) => {
        const color = colors[i % colors.length]
        return (
          <div key={i} className="flex items-center mt-[15px] text-sm">
            <div
              className="min-h-[12px] min-w-[12px] rounded-sm mr-[10px]"
              style={{ backgroundColor: color }}
            />
            <span className="text-theme-muted mr-[5px]">
              {/* capitalize first letter */}
              {item.label.charAt(0).toUpperCase() + item.label.slice(1)}:
            </span>
            <span>{`${item.value} ${
              item.value !== 0
                ? `(${Math.round((item.value / sum) * 100)}%)`
                : ''
            }`}</span>
          </div>
        )
      })}
    </>
  )
}
