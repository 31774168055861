import { scaleBand, scaleLinear } from 'd3'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AxisBottom } from '../private/AxisBottom'
import { AxisLeft } from '../private/AxisLeft'
import { Line } from '../private/Line'
import type { ChartProps, LineChartValue, PlotState } from '../types'

const defaultMargins = { top: 20, right: 0, bottom: 20, left: 30 }
const xAxisPadding = 5

export const LineGraph = (props: ChartProps) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    values,
    showX = true,
    showY = true,
    margin = defaultMargins,
    graphKeyData,
  } = props
  // --------------------- ===
  //  STATE
  // ---------------------
  const [plot, setPlot] = useState<null | PlotState>(null)

  // --------------------- ===
  //  REFS
  // ---------------------
  const outerRef = useRef(null)

  // --------------------- ===
  //  FUNCS
  // ---------------------
  // Construct a simple array of coordinates [{ label, value }] for given line chart.
  const getLinevalues = (key: string): LineChartValue[] => {
    return values
      .filter((x) =>
        x.values
          .map((y) => y.key)
          .flat()
          .includes(key)
      )
      .map((x) => {
        return {
          label: x.label,
          value: x.values.filter((y) => y.key === key)[0]
            ? x.values.filter((y) => y.key === key)[0].value
            : 0,
        }
      })
  }

  const renderGraph = useCallback(() => {
    const outerWidth = outerRef.current.clientWidth
    const outerHeight = outerRef.current.clientHeight
    const width = outerWidth - margin.left - margin.right
    const height = outerHeight - margin.top - margin.bottom - xAxisPadding

    // filter out values that don't have any active keys
    const activeValues = values.filter((item) => {
      let valueIsActive = false
      for (let i = 0; i < item.values.length; i++) {
        const itemValue = item.values[i]
        if (graphKeyData[itemValue.key]?.isActive) {
          valueIsActive = true
          break
        }
      }

      return valueIsActive
    })

    const labels = activeValues.map((item) => {
      return item.label
    })
    const scaleX = scaleBand().domain(labels).range([0, width]).padding(1)
    const yValues = activeValues
      .map(({ values }) => {
        return values.map(
          ({ value, key }) => graphKeyData[key].isActive && value
        )
      })
      .flat()

    const scaleY = scaleLinear()
      .domain([Math.min(...yValues), Math.max(...yValues)])
      .range([height, 0])
      .nice()

    setPlot({ width, height, scaleX, scaleY: scaleY, subscaleX: null })
  }, [
    margin.left,
    margin.right,
    margin.top,
    margin.bottom,
    values,
    graphKeyData,
  ])

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useEffect(() => {
    renderGraph()
    window.addEventListener('resize', renderGraph)
    return () => window.removeEventListener('resize', renderGraph)
  }, [renderGraph, graphKeyData])

  return (
    <div className="w-full h-full" ref={outerRef}>
      {plot ? (
        <svg
          id="line-chart"
          width={plot.width + margin.left + margin.right}
          height={plot.height + margin.top + margin.bottom}
        >
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            {showX && (
              <AxisBottom
                scale={plot.scaleX}
                transform={`translate(0,${plot.height})`}
                width={plot.width}
              />
            )}
            {showY && (
              <AxisLeft
                scale={plot.scaleY}
                transform={`translate(${xAxisPadding},0)`}
              />
            )}
            {Object.keys(graphKeyData).map((key) => {
              return graphKeyData[key].isActive ? (
                <Line
                  key={`line-chat-${key}`}
                  values={getLinevalues(key)}
                  scaleX={plot.scaleX}
                  scaleY={plot.scaleY}
                  color={graphKeyData[key].color}
                />
              ) : null
            })}
          </g>
        </svg>
      ) : null}
    </div>
  )
}
